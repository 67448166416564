.main-content, body, html {
    min-height: 100%}
.alert, .well {
    border-radius: 0;
}
.nav-list>li .submenu>li>a:hover, .nav-list>li>a, a:active, a:focus {
    text-decoration: none;
}
html {
    position: relative;
}
.p-button.p-fileupload-choose input[type=file], .p-fileupload-choose.p-fileupload-choose-selected input[type=file]
{
    display: none !important;
}
.p-dialog-footer .p-button{
    color: white !important;
    margin: 0 0.5rem 0 0;
    width: auto;
}
.p-datatable .p-datatable-thead > tr > th
{
    text-align: left !important;
    padding: 0.571rem 0.857rem !important;
}
.p-menu ul {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
}
.p-calendar-w-btn .p-datepicker-trigger
{
    color: white !important;
}
.lb-outerContainer {
    position: relative;
    zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: #fff;
}
.lightbox {
    position: absolute !important;
    left: 0 !important;
    width: 100% !important;
    z-index: 10000 !important;
    text-align: center;
    line-height: 0;
    font-weight: 400;
    box-sizing: content-box;
    outline: none;
}
.lightbox .lb-image {
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 3px;
    display: block;
}
.animation {
    animation-fill-mode: both;
}

.fadeIn {
    animation-name: fadeIn;
}
.fadeInOverlay {
    animation-name: fadeInOverlay;
}

.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: .8;
}
.lb-data .lb-close
{
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAMAAAC6CgRnAAAAPFBMVEX///8AAAD9/f2CgoKAgIAAAAAAAAAAAABLS0sAAAAAAACqqqqqqqq6urpKSkpISEgAAAC7u7u5ubn////zbsMcAAAAE3RSTlMASv6rqwAWS5YMC7/AyZWVFcrJCYaKfAAAAHhJREFUeF590kkOgCAQRFEaFVGc+/53FYmbz6JqBbyQMFSYuoQuV+iTflnstI7ssLXRvMWRaEMs84e2uVckuZe6knL0hiSPObXhj6ChzoEkIolIIpKIO4joICAIeDd7QGIfCCjOKe9HEk8mnxpIAup/F31RPZP9fAG3IAyBSJe0igAAAABJRU5ErkJggg==) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: .7;
    transition: opacity .2s;
}
.lb-data .lb-details {
    max-width: 80%;
    float: left;
    text-align: left;
    line-height: 1.1em;
}
.lb-data .lb-controlContainer {
    float: right;
}
.lb-data .lb-closeContainer {
    float: right;
}
.lb-dataContainer {
    margin: 0 auto;
    padding-top: 10px;
    zoom: 1;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: block !important;
    cursor: pointer;
}
body {
    background-color: #E4E6E9;
    padding-bottom: 0;
    /*font-family: 'Open Sans';*/
    /* font-family: 'Segoe UI'; */
    font-family: 'Helvetica Neue';
    font-size: 13px !important;
    color: #393939;
    line-height: 1.5;
}
/*For Map*/
@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
.basic-container {  
    padding: 30px;
   
  }
  
  .version-info {
    font-size: 8pt;
    float: right;
  }
h1, h2, h3, h4, h5, h6 {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.main-container:before {
    display: block;
    content: "";
    position: absolute;
    z-index: -2;
    width: 100%;
    max-width: inherit;
    bottom: 0;
    top: 0;
    background-color: #FFF;
}
.main-container.container, .rtl .main-container.container {
    padding-left: 0;
    padding-right: 0;
}
.main-container.container:before {
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    width: inherit;
}
.main-content {
    margin-left: 0;
    padding: 0;
}
.main-content:after, .main-content:before {
    content: " ";
    display: table;
}
.main-content:after {
    clear: both;
}
.sidebar+.main-content {
    margin-left: 230px;
}



.page-content {
    background-color: #ffff;
    position: relative;
    margin: 0;
    /* padding: 5px 15px 0px 10px;*/
    padding: 7px 15px 0px 10px;
}
.page-header {
    margin: 0 0 12px;
    border-bottom: 1px dotted #79b0ce;
    padding-bottom: 16px;
    padding-top: 7px;
}
.page-header h1 {
    padding: 0;
    margin: 0 8px;
    font-size: 24px;
    font-weight: lighter;
    color: #2679B5;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}
.page-header h1 small {
    margin: 0 6px;
    font-size: 14px;
    font-weight: 400;
    color: #8089A0;
}
h1.block, h2.block, h3.block, h4.block, h5.block, h6.block {
    margin-bottom: 16px;
}
.ajax-loading-overlay {
    position: absolute;
    z-index: 1999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .5);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80FFFFFF',  endColorstr='#80FFFFFF',  GradientType=0 );
}
.ajax-loading-overlay>.ajax-loading-icon {
    position: relative;
    left: 8px;
    top: 8px;
}
.ajax-loading-overlay.content-loaded {
    background-color: rgba(255, 255, 255, .4);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66FFFFFF',  endColorstr='#66FFFFFF',  GradientType=0 );
}
.ajax-loading-overlay.almost-loaded {
    background-color: rgba(255, 255, 255, .3);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4CFFFFFF',  endColorstr='#4CFFFFFF',  GradientType=0 );
}
.ajax-loading-overlay.ajax-overlay-body {
    position: fixed;
    z-index: 2999;
}
.ajax-loading-overlay.ajax-overlay-body>.ajax-loading-icon {
    left: 8px;
    top: 8px;
}
.ace-icon {
    text-align: center;
}
h1 {
    font-size: 32px;
}
h1.smaller {
    font-size: 31px;
}
h1.bigger {
    font-size: 33px;
}
h2 {
    font-size: 26px;
}
h2.smaller {
    font-size: 25px;
}
h2.bigger {
    font-size: 27px;
}
h3 {
    font-size: 22px;
}
h3.smaller {
    font-size: 21px;
}
h3.bigger {
    font-size: 23px;
}
h4 {
    font-size: 18px;
}
h4.smaller {
    font-size: 17px;
}
h4.bigger {
    font-size: 19px;
}
h5 {
    font-size: 13px !important;
    margin-top: 4px !important;
    margin-bottom: 5px !important;
}
h5.smaller {
    font-size: 14px;
}
h5.bigger {
    font-size: 16px;
}
h6 {
    font-size: 14px;
}
h6.smaller {
    font-size: 12px;
}
h6.bigger {
    font-size: 14px;
}
ol, ul {
    padding: 0;
    margin: 0 0 10px 25px;
}
ol.margin-5, ul.margin-5 {
    margin-left: 5px;
}
ol.margin-10, ul.margin-10 {
    margin-left: 10px;
}
ol.margin-15, ul.margin-15 {
    margin-left: 15px;
}
ol.margin-20, ul.margin-20 {
    margin-left: 20px;
}
li>ol, li>ul {
    margin-left: 18px;
}
.list-inline, .list-unstyled {
    margin-left: 0;
}
.list-inline>li>.ace-icon:first-child, .list-unstyled>li>.ace-icon:first-child {
    width: 18px;
    text-align: center;
}
.spaced>li {
    margin-top: 9px;
    margin-bottom: 9px;
}
.spaced2>li {
    margin-top: 15px;
    margin-bottom: 15px;
}
li.divider {
    margin-top: 3px;
    margin-bottom: 3px;
    height: 0;
    font-size: 0;
}
.spaced>li.divider {
    margin-top: 5px;
    margin-bottom: 5px;
}
.spaced2>li.divider {
    margin-top: 8px;
    margin-bottom: 8px;
}
li.divider:before {
    display: inline-block;
}
.blockquote-reverse, blockquote, blockquote.pull-right {
    border-color: #E5EAF1;
}
pre.prettyprint {
    white-space: pre-wrap;
}
.well h1, .well h2, .well h3, .well h4, .well h5, .well h6 {
    margin-top: 0;
}
.well h1, .well h2, .well h3 {
    line-height: 36px;
}
.alert {
    font-size: 14px;
}
.list-group-item, .no-radius, .pager>li>a, .pagination>li>a {
    border-radius: 0!important;
}
.alert .close {
    font-size: 16px;
}
.alert-block p+p {
    margin-top: 10px;
}
.pager>li>span, .pagination>li>span {
    border-width: 1px;
    border-radius: 0!important;
}
.pager>li>a, .pagination>li>a {
    border-width: 1px;
    border-color: #d4dfe3;
    color: #2283C5;
    background-color: #FAFAFA;
    margin: 0 -1px 0 0;
    position: relative;
    z-index: auto;
}
.pager>li>a:hover, .pagination>li>a:hover {
    color: #1c6ca2;
    background-color: #EAEFF2;
    border-color: #c1d1d7;
    z-index: 1;
}
.pager>li>a:focus, .pagination>li>a:focus {
    background-color: #eaeef2;
}
.pager>li.disabled>a, .pager>li.disabled>a:hover, .pagination>li.disabled>a, .pagination>li.disabled>a:hover {
    background-color: #F9F9F9;
    border-color: #D9D9D9;
    z-index: auto;
}
.pagination>li.active>a, .pagination>li.active>a:focus, .pagination>li.active>a:hover {
    background-color: #6FAED9;
    border-color: #6FAED9;
    color: #FFF;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
    z-index: 2;
}
.list-group {
    margin-left: auto;
}
.lighter {
    font-weight: lighter;
}
.bolder {
    font-weight: bolder;
}
.inline {
    display: inline-block!important;
}
.block {
    display: block!important;
}
.align-center, .center {
    text-align: center!important;
}
.align-left {
    text-align: left!important;
}
.align-right {
    text-align: right!important;
}
.align-justify {
    text-align: justify;
}
.middle {
    vertical-align: middle;
}
.align-middle {
    vertical-align: middle!important;
}
.align-top {
    vertical-align: top!important;
}
.align-bottom {
    vertical-align: bottom!important;
}
.pos-rel, .position-relative {
    position: relative;
}
.pos-abs, .position-absolute {
    position: absolute;
}
.no-float {
    float: none!important;
}
.line-height-normal {
    line-height: normal!important;
}
.line-height-0 {
    line-height: 0!important;
}
.line-height-1 {
    line-height: 1!important;
}
.line-height-125 {
    line-height: 1.25!important;
}
.line-height-150 {
    line-height: 1.5!important;
}
.dark {
    color: #333!important;
}
.white {
    color: #FFF!important;
}
.red {
    color: #DD5A43!important;
}
.red2 {
    color: #E08374!important;
}
.light-red {
    color: #F77!important;
}
.blue {
    color: #478FCA!important;
}
.light-blue {
    color: #93CBF9!important;
}
.green {
    color: #69AA46!important;
}
.light-green {
    color: #B0D877!important;
}
.orange {
    color: #FF892A!important;
}
.orange2 {
    color: #FEB902!important;
}
.light-orange {
    color: #FCAC6F!important;
}
.purple {
    color: #A069C3!important;
}
.pink {
    color: #C6699F!important;
}
.pink2 {
    color: #D6487E!important;
}
.brown {
    color: brown!important;
}
.grey {
    color: #777!important;
}
.light-grey {
    color: #BBB!important;
}
.bigger-110 {
    font-size: 110%!important;
    color: #fff;
}
.bigger-120 {
    font-size: 120%!important;
    color: #fff;
}
.bigger-130 {
    font-size: 130%!important;
}
.bigger-140 {
    font-size: 140%!important;
}
.bigger-150 {
    font-size: 150%!important;
}
.bigger-160 {
    font-size: 160%!important;
}
.bigger-170 {
    font-size: 170%!important;
}
.bigger-180 {
    font-size: 180%!important;
}
.bigger-190 {
    font-size: 190%!important;
}
.bigger-200 {
    font-size: 200%!important;
}
.bigger-210 {
    font-size: 210%!important;
}
.bigger-220 {
    font-size: 220%!important;
}
.bigger-230 {
    font-size: 230%!important;
}
.bigger-240 {
    font-size: 240%!important;
}
.bigger-250 {
    font-size: 250%!important;
}
.bigger-260 {
    font-size: 260%!important;
}
.bigger-270 {
    font-size: 270%!important;
}
.bigger-280 {
    font-size: 280%!important;
}
.bigger-290 {
    font-size: 290%!important;
}
.bigger-300 {
    font-size: 300%!important;
}
.bigger-115 {
    font-size: 115%!important;
}
.bigger-125 {
    font-size: 125%!important;
}
.bigger-175 {
    font-size: 175%!important;
}
.bigger-225 {
    font-size: 225%!important;
}
.bigger-275 {
    font-size: 275%!important;
}
.smaller-90 {
    font-size: 90%!important;
}
.smaller-80 {
    font-size: 80%!important;
}
.smaller-70 {
    font-size: 70%!important;
}
.smaller-60 {
    font-size: 60%!important;
}
.smaller-50 {
    font-size: 50%!important;
}
.smaller-40 {
    font-size: 40%!important;
}
.smaller-30 {
    font-size: 30%!important;
}
.smaller-20 {
    font-size: 20%!important;
}
.smaller-75 {
    font-size: 75%!important;
}
.width-20 {
    width: 20%!important;
}
.width-30 {
    width: 30%!important;
}
.width-35 {
    width: 35%!important;
}
.width-40 {
    width: 40%!important;
}
.width-45 {
    width: 45%!important;
}
.width-50 {
    width: 50%!important;
}
.width-55 {
    width: 55%!important;
}
.width-60 {
    width: 60%!important;
}
.width-65 {
    width: 65%!important;
}
.width-70 {
    width: 70%!important;
}
.width-80 {
    width: 80%!important;
}
.width-85 {
    width: 85%!important;
}
.width-90 {
    width: 90%!important;
}
.width-95 {
    width: 95%!important;
}
.width-100 {
    width: 100%!important;
}
.width-75 {
    width: 75%!important;
}
.width-25 {
    width: 25%!important;
}
.width-auto {
    width: auto!important;
}
.height-auto {
    height: auto!important;
}
.no-padding {
    padding: 0!important;
}
.no-padding-bottom {
    padding-bottom: 0!important;
}
.no-padding-top {
    padding-top: 0!important;
}
.no-padding-left {
    padding-left: 0!important;
}
.no-padding-right {
    padding-right: 0!important;
}
.no-margin {
    margin: 0!important;
}
.no-margin-bottom {
    margin-bottom: 0!important;
}
.no-margin-top {
    margin-top: 0!important;
}
.no-margin-left {
    margin-left: 0!important;
}
.no-margin-right {
    margin-right: 0!important;
}
.no-border {
    border-width: 0;
}
.no-border-bottom {
    border-bottom-width: 0;
}
.no-border-top {
    border-top-width: 0;
}
.no-border-left {
    border-left-width: 0;
}
.no-border-right {
    border-right-width: 0;
}
.overflow-hidden {
    overflow: hidden!important;
}
.overflow-scroll {
    overflow-x: hidden!important;
    overflow-y: scroll!important;
}
.no-text-shadow {
    text-shadow: none!important;
}
.hr {
    display: block;
    height: 0;
    overflow: hidden;
    font-size: 0;
    border-width: 1px 0 0;
    border-top: 1px solid #E3E3E3;
    margin: 12px 0;
    border-top-color: rgba(0, 0, 0, .11);
}
.hr-double {
    height: 3px;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    border-top-color: rgba(0, 0, 0, .11);
    border-bottom-color: rgba(0, 0, 0, .11);
}
.hr-dotted, .hr.dotted {
    border-style: dotted;
}
.hr-32, .hr32 {
    margin: 32px 0;
}
.hr-30, .hr30 {
    margin: 30px 0;
}
.hr-28, .hr28 {
    margin: 28px 0;
}
.hr-26, .hr26 {
    margin: 26px 0;
}
.hr-24, .hr24 {
    margin: 24px 0;
}
.hr-22, .hr22 {
    margin: 22px 0;
}
.hr-20, .hr20 {
    margin: 20px 0;
}
.hr-18, .hr18 {
    margin: 18px 0;
}
.hr-16, .hr16 {
    margin: 16px 0;
}
.hr-14, .hr14 {
    margin: 14px 0;
}
.hr-12, .hr12 {
    margin: 12px 0;
}
.hr-10, .hr10 {
    margin: 10px 0;
}
.hr-8, .hr8 {
    margin: 8px 0;
}
.hr-6, .hr6 {
    margin: 6px 0;
}
.hr-4, .hr4 {
    margin: 4px 0;
}
.hr-2, .hr2 {
    margin: 2px 0;
}
.space {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
}
[class*=vspace-] {
    display: none;
}
.space-32, [class*=vspace-32] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 32px 0 31px;
}
.space-30, [class*=vspace-30] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 30px 0 29px;
}
.space-28, [class*=vspace-28] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 28px 0 27px;
}
.space-26, [class*=vspace-26] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 26px 0 25px;
}
.space-24, [class*=vspace-24] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 24px 0 23px;
}
.space-22, [class*=vspace-22] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 22px 0 21px;
}
.space-20, [class*=vspace-20] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 20px 0 19px;
}
.space-18, [class*=vspace-18] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 18px 0 17px;
}
.space-16, [class*=vspace-16] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 16px 0 15px;
}
.space-14, [class*=vspace-14] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 14px 0 13px;
}
.space-12, [class*=vspace-12] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0 11px;
}
.space-10, [class*=vspace-10] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 10px 0 9px;
}
.space-8, [class*=vspace-8] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 8px 0 7px;
}
.space-6, [class*=vspace-6] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 6px 0 5px;
}
.space-4, [class*=vspace-4] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 4px 0 3px;
}
.space-2, [class*=vspace-2] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 2px 0 1px;
}
.space-0, [class*=vspace-0] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 0;
}
@media only screen and (max-width:479px) {
    [class*=vspace-][class*="-xs"] {
    display: block;
}
}@media only screen and (max-width:767px) {
    [class*=vspace-][class*="-sm"] {
    display: block;
}
}@media only screen and (max-width:991px) {
    [class*=vspace-][class*="-md"] {
    display: block;
}
}@media only screen and (max-width:1199px) {
    [class*=vspace-][class*="-lg"] {
    display: block;
}
}.header {
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 18px;
    padding-bottom: 4px;
    border-bottom: 1px solid #CCC;
}
.header.less-margin {
    margin-top: 10px;
    margin-bottom: 10px;
}
.header.blue {
    border-bottom-color: #d5e3ef;
}
.header.green {
    border-bottom-color: #cce2c1;
}
.header.purple {
    border-bottom-color: #e0d1ea;
}
.header.orange {
    border-bottom-color: #fdd0ac;
}
.header.orange2 {
    border-bottom-color: #fbd36b;
}
.header.red {
    border-bottom-color: #f3cdc6;
}
.header.grey {
    border-bottom-color: #c3c3c3;
}
.header.pink {
    border-bottom-color: #f2e0eb;
}
.header.pink2 {
    border-bottom-color: #e69eb9;
}
.header.light-blue {
    border-bottom-color: #dceefc;
}
.header.light-red {
    border-bottom-color: #fec5c5;
}
.header.light-green {
    border-bottom-color: #d2e7b5;
}
.header.brown {
    border-bottom-color: #df9393;
}
.header>.ace-icon {
    margin-right: 2px;
}
.action-buttons a {
    margin: 0 3px;
    display: inline-block;
    opacity: .85;
    -webkit-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s;
}
.action-buttons a:hover {
    text-decoration: none;
    opacity: 1;
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
.navbar {
    margin: 0% 0% 0px 51px !important;
    /* margin: 0% 0% 2px 51px !important; */
    border: none !important;
    padding-left: 0;
    padding-right: 0;
    border-width: 0;
    border-radius: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* min-height: 45px; */
    height: 52px;
    background-color: #165788 !important;
    background-image:url('assets/Images/header.png');
    /* background-size: 100%; */
    background-repeat: no-repeat;
    /* border-bottom: 1px solid lightgray !important; */
}
.sec-menu{
    border-top: 1px solid #FFF;
}
.navbar .navbar-link, .navbar .navbar-text {
    color: #FFF;
}
.navbar .navbar-brand {
    color: #FFF;
    font-size: 24px;
    text-shadow: none;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
}
.navbar .navbar-brand:focus, .navbar .navbar-brand:hover {
    color: #FFF;
}
@media only screen and (min-width:992px) {
    .navbar-container {
    padding-left: 10px;
    /*padding-right: 10px;*/
}
}.navbar-container.container {
    padding-left: 0;
    padding-right: 0;
}
.nav>li>a {
    padding: 4px 15px !important;
}
.ace-nav {
    height: 100%;
    margin: 0!important;
}
.ace-nav>li {
    line-height: 45px;
    height: 50px;
    /* border-left: 1px solid #E1E1E1; */
    padding: 0;
    position: relative;
    float: left;
}
.ace-nav>li:first-child {
    border-left-width: 0;
}
.ace-nav>li>a {
    /* background-color: #2E6589; */
    color: #FFF;
    display: block;
    line-height: inherit;
    text-align: center;
    height: 100%;
    width: auto;
    min-width: 50px;
    padding: 6px 15px;
    position: relative;
}
.ace-nav>li>a>.ace-icon {
    display: inline-block;
    font-size: 16px;
    color: #FFF;
    text-align: center;
    width: 20px;
}
.ace-nav>li>a>.badge {
    position: relative;
    top: -4px;
    left: 2px;
    padding-right: 5px;
    padding-left: 5px;
}
.ace-nav>li.open>a, .ace-nav>li>a:focus, .ace-nav>li>a:hover {
    background-color: #2c5976;
    color: #FFF;
}
.ace-nav>li.blue>a {
    background-color: #2E6589;
}
.ace-nav>li.blue>a:focus, .ace-nav>li.blue>a:hover, .ace-nav>li.open.blue>a {
    background-color: #2c5976;
}
.ace-nav>li.grey>a {
    background-color: #555;
}
.ace-nav>li.grey>a:focus, .ace-nav>li.grey>a:hover, .ace-nav>li.open.grey>a {
    background-color: #4b4b4b;
}
.ace-nav>li.purple>a {
    /*background-color: #892E65;*/
    /*background-color: #1d4d75;*/
    /* background-color: rgba(7, 93, 141, 1); */
    /* background-color: #054a70; */
}
.ace-nav>li.open.purple>a, .ace-nav>li.purple>a:focus, .ace-nav>li.purple>a:hover {
    background-color: #359fd9;
}
.ace-nav>li.green>a {
    /*background-color: #2E8965;*/
    background-color: #1d4d75;
}
.ace-nav>li.green>a:focus, .ace-nav>li.green>a:hover, .ace-nav>li.open.green>a {
    background-color: #359fd9;
}
.ace-nav>li.light-blue>a {
    /* background-color: #054a70; */
}
.ace-nav>li.light-blue>a:focus, .ace-nav>li.light-blue>a:hover, .ace-nav>li.open.light-blue>a {
    background-color: #359fd9;
}
.ace-nav>li.light-blue2>a {
    background-color: #42A8E1;
}
.ace-nav>li.light-blue2>a:focus, .ace-nav>li.light-blue2>a:hover, .ace-nav>li.open.light-blue2>a {
    background-color: #359fd9;
}
.ace-nav>li.red>a {
    background-color: #B74635;
}
.ace-nav>li.open.red>a, .ace-nav>li.red>a:focus, .ace-nav>li.red>a:hover {
    background-color: #a34335;
}
.ace-nav>li.light-green>a {
    background-color: #9ABC32;
}
.ace-nav>li.light-green>a:focus, .ace-nav>li.light-green>a:hover, .ace-nav>li.open.light-green>a {
    background-color: #8ba832;
}
.ace-nav>li.light-purple>a {
    background-color: #CB6FD7;
}
.ace-nav>li.light-purple>a:focus, .ace-nav>li.light-purple>a:hover, .ace-nav>li.open.light-purple>a {
    background-color: #c263ce;
}
.ace-nav>li.light-orange>a {
    background-color: #F79263;
}
.ace-nav>li.light-orange>a:focus, .ace-nav>li.light-orange>a:hover, .ace-nav>li.open.light-orange>a {
    background-color: #f28653;
}
.ace-nav>li.light-pink>a {
    background-color: #F4DAE5;
}
.ace-nav>li.light-pink>a:focus, .ace-nav>li.light-pink>a:hover, .ace-nav>li.open.light-pink>a {
    background-color: #eeccda;
}
.ace-nav>li.dark>a {
    background-color: #404040;
}
.ace-nav>li.dark>a:focus, .ace-nav>li.dark>a:hover, .ace-nav>li.open.dark>a {
    background-color: #363636;
}
.ace-nav>li.white-opaque>a {
    background-color: rgba(255, 255, 255, .8);
}
.ace-nav>li.open.white-opaque>a, .ace-nav>li.white-opaque>a:focus, .ace-nav>li.white-opaque>a:hover {
    background-color: rgba(245, 245, 245, .8);
}
.ace-nav>li.dark-opaque>a, .ace-nav>li.dark-opaque>a:focus, .ace-nav>li.dark-opaque>a:hover, .ace-nav>li.open.dark-opaque>a {
    background-color: rgba(0, 0, 0, .2);
}
.ace-nav>li.transparent>a {
    background-color: transparent;
}
.ace-nav>li.open.transparent>a, .ace-nav>li.transparent>a:focus, .ace-nav>li.transparent>a:hover {
    background-color: rgba(0, 0, 0, .1);
}
.ace-nav>li.light-10>a {
    background-color: rgba(255, 255, 255, .1);
}
.ace-nav>li.light-10>a:focus, .ace-nav>li.light-10>a:hover, .ace-nav>li.open.light-10>a {
    background-color: rgba(255, 255, 255, .2);
}
.ace-nav>li.dark-10>a {
    background-color: rgba(0, 0, 0, .1);
}
.ace-nav>li.dark-10>a:focus, .ace-nav>li.dark-10>a:hover, .ace-nav>li.open.dark-10>a {
    background-color: rgba(0, 0, 0, .2);
}
.ace-nav>li.margin-4 {
    margin-left: 4px;
}
.ace-nav>li.margin-3 {
    margin-left: 3px;
}
.ace-nav>li.margin-2 {
    margin-left: 2px;
}
.ace-nav>li.margin-1 {
    margin-left: 1px;
}
.ace-nav>li.no-border {
    border-width: 0!important;
}
.ace-nav>li .dropdown-menu {
    z-index: 1031;
}
.ace-nav .nav-user-photo {
    margin: -4px 8px 0 0;
    border-radius: 100%;
    border: 2px solid #FFF;
    max-width: 40px;
}
.ace-nav li.dropdown-footer a .ace-icon {
    display: inline-block;
    width: 1.25em;
    text-align: center;
}
@media only screen and (max-height:540px) {
    .ace-nav>li.dropdown-modal>.dropdown-menu {
    position: fixed;
    margin-top: auto;
    z-index: 1052;
}
.ace-nav>li.dropdown-modal>.dropdown-menu:after, .ace-nav>li.dropdown-modal>.dropdown-menu:before {
    display: none;
}
.ace-nav>li.dropdown-modal>.user-menu>li>a {
    padding-top: 6px;
    padding-bottom: 6px;
}
.ace-nav>li.dropdown-modal.open:before {
    background-color: #000;
    opacity: .5;
    border-width: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    display: block;
    height: 2000px;
    width: auto;
    z-index: 1051;
}
}@media only screen and (max-width:767px) {
    
.ace-nav>li:nth-last-child(4)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(4)>.dropdown-menu.dropdown-menu-right:before {
    right: 120px;
}
.ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right {
    right: -80px;
}
.ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right:before {
    right: 100px;
}
.user-menu.dropdown-close.dropdown-menu-right {
    right: 0!important;
}
}@media only screen and (min-width:480px) and (max-width:767px) {

.navbar.navbar-collapse .ace-nav>li:nth-last-child(2):nth-child(1)>.dropdown-menu.dropdown-menu-right:after, .navbar.navbar-collapse .ace-nav>li:nth-last-child(2):nth-child(1)>.dropdown-menu.dropdown-menu-right:before, .navbar.navbar-collapse .ace-nav>li:nth-last-child(2):nth-child(2)>.dropdown-menu.dropdown-menu-right:after, .navbar.navbar-collapse .ace-nav>li:nth-last-child(2):nth-child(2)>.dropdown-menu.dropdown-menu-right:before {
    right: 80px;
    left: auto;
}
}@media only screen and (max-width:480px) {

.ace-nav>li:nth-last-child(4)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(4)>.dropdown-menu.dropdown-menu-right:before {
    right: 140px;
}
.ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right {
    right: -90px;
}
.ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right:before {
    right: 110px;
}
.ace-nav>li:nth-last-child(2)>.dropdown-menu.dropdown-menu-right {
    right: -50px;
}
.ace-nav>li:nth-last-child(2)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(2)>.dropdown-menu.dropdown-menu-right:before {
    right: 70px;
}
}@media only screen and (max-width:479px) {
.ace-nav>li:nth-last-child(4)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(4)>.dropdown-menu.dropdown-menu-right:before {
    right: auto;
    left: 30px;
}
.ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right {
    left: -50px;
    right: auto;
}
.ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(3)>.dropdown-menu.dropdown-menu-right:before {
    left: 75px;
    right: auto;
}
.ace-nav>li:nth-last-child(2)>.dropdown-menu.dropdown-menu-right {
    left: -70px;
    right: auto;
}
.ace-nav>li:nth-last-child(2)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(2)>.dropdown-menu.dropdown-menu-right:before {
    left: 90px;
    right: auto;
}
.navbar:not(.navbar-collapse) .navbar-header {
    float: none!important;
    display: block;
}
.navbar:not(.navbar-collapse) .ace-nav {
    display: block;
    float: none!important;
    text-align: center;
    background-color: rgba(0, 0, 0, .15);
    border: 1px solid rgba(0, 0, 0, .1);
    border-width: 1px 0 0;
    letter-spacing: -3px;
}
.navbar:not(.navbar-collapse) .ace-nav>li {
    display: inline-block;
    float: none!important;
    text-align: left;
    letter-spacing: normal;
}
.navbar:not(.navbar-collapse) .ace-nav>li:first-child {
    border-left: 1px solid rgba(255, 255, 255, .4);
}
.navbar:not(.navbar-collapse) .ace-nav>li:last-child {
    border-right: 1px solid rgba(255, 255, 255, .4);
}
}@media only screen and (max-width:319px) {
.ace-nav>li:nth-last-child(2)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-last-child(2)>.dropdown-menu.dropdown-menu-right:before {
    left: 130px;
    right: auto;
}
.ace-nav>li:nth-child(2):nth-last-child(2)>.dropdown-menu.dropdown-menu-right {
    left: -85px;
    right: auto;
}
.ace-nav>li:nth-child(2):nth-last-child(2)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-child(2):nth-last-child(2)>.dropdown-menu.dropdown-menu-right:before {
    left: 105px;
    right: auto;
}
.ace-nav>li:nth-child(1):nth-last-child(3)>.dropdown-menu.dropdown-menu-right {
    left: -35px;
    right: auto;
}
.ace-nav>li:nth-child(1):nth-last-child(3)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-child(1):nth-last-child(3)>.dropdown-menu.dropdown-menu-right:before {
    left: 55px;
    right: auto;
}
.ace-nav>li:nth-child(1):nth-last-child(2)>.dropdown-menu.dropdown-menu-right {
    left: -60px;
    right: auto;
}
.ace-nav>li:nth-child(1):nth-last-child(2)>.dropdown-menu.dropdown-menu-right:after, .ace-nav>li:nth-child(1):nth-last-child(2)>.dropdown-menu.dropdown-menu-right:before {
    left: 75px;
    right: auto;
}
.ace-nav>li>a {
    padding: 0 5px!important;
}
}.user-menu>li>a {
    padding: 4px 12px;
}
.user-menu>li>a>.ace-icon {
    margin-right: 6px;
    font-size: 120%}
.user-info {
    max-width: 180px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    vertical-align: top;
    line-height: 15px;
    position: relative;
    top: 6px;
}
.user-info small {
    display: block;
}
@media (min-width:480px) and (max-width:540px), (max-width:360px) {
.navbar:not(.navbar-collapse) .navbar-buttons .nav-user-photo {
    margin-right: 0;
}
.navbar:not(.navbar-collapse) .navbar-buttons .user-info {
    position: absolute;
    margin-top: 40px;
    margin-left: 1px;
    right: 2px;
    z-index: 1032;
    color: #777;
    font-size: 14px;
    width: 156px;
    max-width: 156px;
    padding-left: 8px;
    padding-right: 8px;
    height: 32px;
    line-height: 26px!important;
    display: none;
    border-bottom: 1px solid #E5E5E5;
    text-align: center;
    cursor: text;
}
.navbar:not(.navbar-collapse) .navbar-buttons .user-info>small {
    display: inline;
    opacity: 1;
}
li.open .user-info {
    display: inline-block!important;
}
}@media (max-width:360px) {
    .navbar.navbar-collapse .navbar-buttons .user-menu {
    padding-top: 42px!important;
}
.navbar.navbar-collapse .navbar-buttons .nav-user-photo {
    margin-right: 0;
}
.navbar.navbar-collapse .navbar-buttons .user-info {
    position: absolute;
    margin-top: 40px;
    margin-left: 1px;
    right: 2px;
    z-index: 1032;
    color: #777;
    font-size: 14px;
    width: 156px;
    max-width: 156px;
    padding-left: 8px;
    padding-right: 8px;
    height: 32px;
    line-height: 26px!important;
    display: none;
    border-bottom: 1px solid #E5E5E5;
    text-align: center;
    cursor: text;
}
.navbar.navbar-collapse .navbar-buttons .user-info>small {
    display: inline;
    opacity: 1;
}
li.open .user-info {
    display: inline-block!important;
    right: 2px;
}
}@media (min-width:992px) {
    .user-min .user-menu {
    padding-top: 42px!important;
}
.user-min .nav-user-photo {
    margin-right: 0;
}
.user-min .user-info {
    position: absolute;
    margin-top: 40px;
    margin-left: 1px;
    z-index: 1032;
    color: #777;
    font-size: 14px;
    width: 156px;
    max-width: 156px;
    padding-left: 8px;
    padding-right: 8px;
    height: 32px;
    line-height: 26px!important;
    display: none;
    border-bottom: 1px solid #E5E5E5;
    text-align: center;
    cursor: text;
    right: -3px;
}
.user-min .user-info>small {
    display: inline;
    opacity: 1;
}
.user-min.open .user-info {
    display: inline-block!important;
}
}.btn, .dropdown-colorpicker a {
    cursor: pointer;
}
@media (max-height:540px) and (min-width:480px) and (max-width:540px), (max-height:540px) and (max-width:360px) {
}@media (max-height:540px) and (max-width:360px) {
    .navbar.navbar-collapse .ace-nav>li.dropdown-modal .user-info {
    position: fixed;
    right: auto;
    margin-top: auto;
    max-width: none;
}
}@media (max-height:540px) and (min-width:992px) {
    .ace-nav>li.dropdown-modal.user-min .user-info {
    position: fixed;
    right: auto;
    margin-top: auto;
    max-width: none;
}
}@media only screen and (max-width:991px) {
    .navbar.navbar-collapse {
    max-height: none;
}
.navbar .navbar-collapse, .navbar .navbar-header {
    /* margin-left: 0!important; */
    margin-right: 0!important;
}
.navbar.navbar-collapse .navbar-header {
    float: none!important;
    min-height: 45px;
}
.navbar.navbar-collapse .navbar-buttons {
    min-height: 0;
    padding-left: 0;
    padding-right: 0;
    border: 1px solid rgba(255, 255, 255, .4);
    border-width: 1px 0 0;
    width: 100%
}
.navbar.navbar-collapse .navbar-buttons.in {
    overflow: visible!important;
}
.navbar.navbar-collapse .navbar-buttons>.ace-nav {
    display: block;
    float: none!important;
    text-align: center;
    background-color: transparent!important;
    border-width: 0;
    letter-spacing: -3px;
}
.navbar.navbar-collapse .navbar-buttons>.ace-nav>li {
    display: inline-block;
    float: none!important;
    text-align: left;
    letter-spacing: normal;
}
.navbar.navbar-collapse .navbar-buttons>.ace-nav>li:first-child {
    border-left: 1px solid rgba(255, 255, 255, .4);
}
.navbar.navbar-collapse .navbar-buttons>.ace-nav>li:last-child {
    border-right: 1px solid rgba(255, 255, 255, .4);
}
}.container-fluid>.navbar-buttons, .container>.navbar-buttons {
    padding-right: 0!important;
}
@media only screen and (max-height:400px) {
    .navbar-fixed-top .navbar-collapse {
    max-height: 280px;
}
}@media only screen and (max-height:360px) {
    .navbar-fixed-top .navbar-collapse {
    max-height: 240px;
}
}@media only screen and (max-height:320px) {
    .navbar-fixed-top .navbar-collapse {
    max-height: 200px;
}
}@media only screen and (max-height:280px) {
    .navbar-fixed-top .navbar-collapse {
    max-height: 160px;
}
}@media only screen and (max-height:240px) {
    .navbar-fixed-top .navbar-collapse {
    max-height: 120px;
}
}.navbar .navbar-nav>li {
    border: 1px solid rgba(0, 0, 0, .2);
    border-width: 0 1px 0 0;
}
.navbar .navbar-nav>li:first-child {
    border-width: 0 1px;
}
.navbar .navbar-nav>li>a {
    padding-top: 12px;
    padding-bottom: 11px;
    height: 45px;
    background-color: transparent;
    font-size: 14px;
    color: #FFF;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .25);
}
.navbar .navbar-nav>li.open>a, .navbar .navbar-nav>li>a:focus, .navbar .navbar-nav>li>a:hover {
    background-color: rgba(0, 0, 0, .1)!important;
    color: #FFF!important;
}
.navbar .navbar-nav>li>.dropdown-menu>li>a {
    line-height: 2;
}
.p-listbox-list-wrapper {
    max-height: unset !important;
}

.navbar-nav .dropdown-menu>li>a>.ace-icon {
    display: inline-block;
    width: 20px;
}
.navbar-nav>li>.dropdown-menu {
    z-index: 1033;
}
@media only screen and (max-width:991px) {
    .navbar .navbar-nav .dropdown-menu>li>a, .navbar .navbar-nav .dropdown-menu>li>a>.ace-icon {
    color: #FFF!important;
}
.navbar.navbar-collapse .navbar-menu {
    float: none!important;
    clear: both;
}
.navbar.navbar-collapse .navbar-menu .dropdown-menu:after, .navbar.navbar-collapse .navbar-menu .dropdown-menu:before {
    display: none!important;
}
.navbar .navbar-nav {
    margin: 0 -6px;
}
.navbar .navbar-nav>li, .navbar .navbar-nav>li:first-child {
    border-width: 1px 0 0;
}
.navbar .navbar-nav .dropdown-menu {
    float: none;
    position: relative;
    background-color: transparent;
    box-shadow: none;
    border-width: 0;
}
.navbar .navbar-nav .dropdown-menu>li>a:hover {
    background-color: rgba(0, 0, 0, .07)!important;
}
.navbar .navbar-nav .dropdown-hover>.dropdown-menu {
    left: 32px!important;
    right: auto!important;
    top: auto!important;
    width: 80%!important;
    width: calc(100% - 32px);
}
}.navbar-form.form-search {
    margin-top: 9px;
    margin-bottom: 0;
}
.navbar-form.form-search .form-group {
    margin-bottom: 0;
}
.navbar-form.form-search input[type=text] {
    width: 100px;
}
.navbar-form input[type=text] {
    font-size: 14px;
    line-height: 1.4;
    padding-top: 4px;
    padding-bottom: 4px;
    border-width: 0;
}
@media only screen and (max-width:991px) {
    .navbar-form .form-group {
    display: inline-block;
    width: 50%;
    margin-bottom: 0;
    vertical-align: middle;
}
.navbar-form.form-search input[type=text] {
    width: 100%}
.navbar-nav+.navbar-form {
    margin-top: 0;
}
.navbar .navbar-collapse, .navbar .navbar-form {
    border-color: rgba(255, 255, 255, .4);
}
.navbar .navbar-nav>li:first-child {
    border-top-width: 0;
}
}@media only screen and (max-width:480px) {
    .navbar-form .form-group {
    width: 80%}
}.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse {
    padding-left: 12px;
    padding-right: 12px;
}
@media only screen and (min-width:768px) and (max-width:991px) {
    .navbar.navbar-collapse {
    background-color: transparent;
}
.navbar.navbar-collapse:before {
    background: #438EB9;
    content: "";
    display: block;
    position: absolute;
    min-height: inherit;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    border-radius: inherit;
}
.navbar.navbar-collapse .navbar-container {
    background-color: #438EB9;
}
}.navbar .navbar-toggle {
    border: 1px solid transparent;
    width: 40px;
    height: 35px;
    line-height: 0;
    padding: 0 0 1px;
    text-align: center;
}
.navbar .navbar-toggle.pull-right {
    margin: 5px 6px 4px 0;
}
.navbar .navbar-toggle.pull-left {
    margin: 5px 0 4px 6px;
}
.navbar .navbar-toggle:active {
    top: 1px;
}
.navbar .navbar-toggle.navbar-toggle-img {
    width: auto;
    height: auto;
    overflow: hidden;
    padding: 0;
    border-width: 0;
}
.navbar .navbar-toggle.navbar-toggle-img>img {
    border-radius: inherit;
    max-height: 35px;
    border-width: 0;
}
.navbar .navbar-toggle .icon-bar {
    background-color: #FFF;
    border-radius: 0;
    width: 24px;
    margin-left: 7px;
}
.sidebar {
    width: 230px;
    float: left;
    position: static;
    padding-left: 0;
    padding-right: 0;
    background-color: #165788;
}

.sidebarNavChild{
    padding-left: 23px !important;
}
.sidebarNav{
    background-color: #f2f2f2 !important;
}
.sidebar:before {
    display: block;
    width: inherit;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: inherit;
    border-style: inherit;
    border-color: inherit;
    border-width: inherit;
}
.nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.nav-list .nav-header, .nav-list>li, .nav-list>li>a {
    margin: 0;
}
.nav-list li>a:focus {
    outline: 0;
}
.nav-list>li {
    display: block;
    position: relative;
    float: none;
    padding: 0;
    border-style: solid;
    border-width: 0.5px 0.5px 0px 0px;
    border-color: #359fd9;
}
.nav-list>li:last-child {
    border-bottom-width: 1px;
}
.nav-list>li.active>a {
    font-weight: 700;    
}

.nav-list>li>a {
    display: block;
    height: 39px;
    line-height: 17px;
    padding-left: 7px;
    text-shadow: none!important;
    font-size: 13px;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    color:#585858;
}
.nav-list>li>a:hover{
    color:#266cad !important;
    border-style: solid;
    border-width: 0.01px 0.5px 0px 0px;
    border-color: #359fd9;
    /* border-top: 0.5px solid #359fd9; */
}
.even-list-border>a:hover{
    border-top: 0.5px solid #359fd9 !important;
}
.nav-list>li>a.active:hover{
    color:#FFF !important;    
}
/* .nav-list>li.active:hover{
    color:#FFF !important;    
} */
.nav-list>li>a>.menu-icon {
    display: inline-block;
    min-width: 30px;
    margin-right: 2px;
    vertical-align: sub;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}
.nav-list>li:before {
    display: none;
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
    height: 41px;
    width: 3px;
    max-width: 3px;
    overflow: hidden;
    background-color: #629CC9;
}
@media only screen and (min-width:992px) {
}.main-container.container .sidebar.sidebar-fixed:not(.h-sidebar) .nav-list>li:before {
    left: 0;
}
.nav-list>li:hover:before {
    display: block
}
.nav-list>li a>.arrow {
    display: block;
    width: 14px!important;
    height: 14px;
    line-height: 14px;
    text-shadow: none;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 12px;
    padding: 0;
    text-align: center;
}
.nav-list>li.separator {
    height: 3px;
    background-color: transparent;
    position: static;
    margin: 1px 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.nav-list>li .submenu {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    position: relative;
}
.nav-list>li .submenu>li {
    margin-left: 0;
    position: relative;
}
.nav-list>li .submenu>li>a {
    display: block;
    position: relative;
    padding: 7px 0 9px 37px;
    margin: 0;
    border-top-width: 1px;
    border-top-style: dotted;
}
.nav-list>li .submenu>li a>.menu-icon {
    display: none;
    font-size: 12px;
    font-weight: 400;
    width: 18px;
    height: auto;
    line-height: 12px;
    text-align: center;
    position: absolute;
    left: 10px;
    top: 11px;
    z-index: 1;
    background-color: inherit;
}
.nav-list>li .submenu>li.active>a>.menu-icon, .nav-list>li .submenu>li:hover>a>.menu-icon {
    display: inline-block;
}
.nav-list>li>.submenu {
    border-top: 1px solid;
}
.nav-list>li>.submenu>li:before {
    display: block;
    width: 7px;
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 17px;
    border: 1px dotted;
    border-width: 1px 0 0;
}
.nav-list>li>.submenu:before {
    display: block;
    position: absolute;
    z-index: 1;
    left: 18px;
    top: 0;
    bottom: 0;
    border: 1px dotted;
    border-width: 0 0 0 1px;
}
.nav-list li.active>a>.arrow, .nav-list li.open>a>.arrow, .nav-list li:hover>a>.arrow {
    color: inherit;
}
.nav-list li.open>.submenu, .nav-list>li .submenu.collapsing, .nav-list>li>.submenu li.open>.submenu {
    display: block;
}
.nav-list>li>a>.menu-icon, no_such_element:-o-prefocus {
    vertical-align: middle;
}
.nav-list>li>.submenu li>.submenu {
    display: none;
}
.nav-list>li>.submenu li.active>.submenu, .nav-list>li>.submenu li>.submenu.collapsing {
    display: block;
}
.nav-list>li>.submenu a>.arrow {
    right: 10px;
    top: 10px;
    font-size: 16px;
}
.nav-list>li>.submenu>li:first-child>a {
    border-top-width: 0;
}
.nav-list>li>.submenu li>.submenu>li {
    line-height: 18px;
}
.nav-list>li>.submenu li>.submenu>li:before {
    display: none;
}
.nav-list>li>.submenu li>.submenu>li>a {
    margin-left: 20px;
    padding-left: 22px;
}
.nav-list>li>.submenu li>.submenu>li>.submenu>li>a {
    margin-left: 20px;
    padding-left: 38px;
}
.nav-list>li>.submenu li>.submenu>li a>.menu-icon {
    display: inline-block;
    width: auto;
    position: static;
    background-color: transparent;
    margin-right: 4px;
    color: inherit;
}
.nav-list>li>.submenu li>.submenu>li a .arrow, .nav-list>li>.submenu li>.submenu>li a .menu-icon {
    color: inherit;
}
.nav-list>li>.submenu li.open:not(.hover)>a>.menu-icon {
    display: inline-block;
}
.nav-list a .badge, .nav-list a .label {
    position: absolute;
    top: 9px;
    right: 10px;
    opacity: .88;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
}
.nav-list a .badge .ace-icon, .nav-list a .label .ace-icon {
    vertical-align: middle;
    margin: 0;
}
.nav-list a.dropdown-toggle .badge, .nav-list a.dropdown-toggle .label {
    right: 26px;
}
.nav-list li:hover>a .badge, .nav-list li:hover>a .label {
    opacity: 1;
}
.nav-list .submenu .submenu a .badge, .nav-list .submenu .submenu a .label {
    top: 6px;
}
.sidebar.sidebar-fixed {
    position: fixed;
    top: auto;
    float: none!important;
    z-index: 1027;
}
.sidebar.sidebar-fixed:before {
    height: 5000px;
    top: auto;
    bottom: auto;
}
.sidebar-scroll-native {
    overflow-y: scroll!important;
    overflow-x: hidden;
}
body.mob-safari .sidebar.sidebar-fixed {
    top: 45px;
}
@media (max-width:479px) {
}@media (max-width:991px) {
    body.mob-safari .navbar-fixed-top+.main-container .sidebar.responsive {
    top: 45px;
}
}@media (max-width:479px) {
    body.mob-safari .navbar-fixed-top+.main-container .sidebar.responsive {
    top: 90px;
}
}@media only screen and (max-width:991px) {
    .responsive.sidebar-fixed {
    left: auto;
    margin-left: auto;
}
.navbar-fixed-top+.main-container>.responsive {
    position: fixed;
    left: auto;
    margin-left: auto;
}
.navbar-fixed-top+.main-container>.menu-toggler+.responsive {
    position: absolute;
    left: 190px;
    margin-left: -190px;
}
.navbar-fixed-top+.main-container>.menu-toggler+.responsive.sidebar-fixed, .sidebar.responsive.sidebar-fixed, .sidebar.sidebar-fixed.responsive-max {
    position: fixed;
    left: auto;
    margin-left: auto;
}
.main-container .menu-toggler.fixed {
    position: fixed;
    left: auto;
    z-index: 1026;
}
}.sidebar .sidebar-toggle {
    border-style: solid;
    border-width: 0 0 1px;
    text-align: center;
    padding: 3px 0;
    position: relative;
}
.sidebar .sidebar-toggle>.ace-icon {
    padding: 0 5px;
    line-height: 18px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 100%;
    border: 1px solid;
    position: relative;
}
.sidebar .sidebar-toggle:before {
    display: block;
    height: 0;
    border-top: 1px solid;
    border-color: inherit;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 13px;
}
.sidebar .sidebar-shortcuts {
    text-align: center;
    min-height: 40px;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    border: 0 solid;
}
.sidebar .sidebar-shortcuts:empty {
    display: none;
}
.sidebar .sidebar-shortcuts-large {
    line-height: 37px;
}
.sidebar .sidebar-shortcuts-large>.btn {
    text-align: center;
    width: 41px;
    line-height: 24px;
    padding: 0;
    border-width: 4px;
}
.sidebar .sidebar-shortcuts-large>.btn>.ace-icon {
    margin: 0;
}
.sidebar .sidebar-shortcuts-mini {
    display: none;
    font-size: 0;
    width: 42px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.sidebar .sidebar-shortcuts-mini>.btn {
    border-width: 0;
    font-size: 0;
    line-height: 0;
    padding: 8px;
    margin: 1px;
    border-radius: 0;
    opacity: .85;
    filter: alpha(opacity=85);
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
}.nav-list li.active>a:after {
    display: block;
    position: absolute;
    right: 0;
    top: 4px;
    border: 8px solid transparent;
    border-width: 14px 10px;
}
.nav-list li.open>a:after {
    display: none;
}
.nav-list li.active.no-active-child>a:after {
    display: block;
}
.nav-list>li li.active>a:after {
    top: 2px;
    border-width: 14px 8px;
}
.nav-list li.active:not(.open) li.active>a:after {
    display: none!important;
}
@media (min-width:992px) {
    .sidebar.compact, .sidebar.compact.navbar-collapse {
    width: 105px;
}
.sidebar.compact .sidebar-shortcuts {
    max-height: 40px;
}
.sidebar.compact .nav-list>li:before {
    height: auto;
    bottom: -1px;
}
.sidebar.compact .nav-list>li>a {
    height: auto;
    line-height: 16px;
    padding: 2px 0 8px;
    text-align: center;
}
.sidebar.compact .nav-list>li>a>.menu-icon {
    display: block;
    margin: 0;
    vertical-align: inherit;
    line-height: 32px;
    height: 32px;
    font-size: 20px;
}
.sidebar.compact .nav-list>li.active>.submenu, .sidebar.compact .nav-list>li.open>.submenu, .sidebar.compact .nav-list>li>a>.arrow {
    display: none;
}
.sidebar.compact .nav-list a .badge, .sidebar.compact .nav-list a .label {
    right: 12px;
}
.sidebar.compact+.main-content {
    margin-left: 105px;
}
}@media (min-width:992px) and (max-width:991px) {
    .sidebar.compact+.main-content {
    margin-left: 0;
}
.sidebar.navbar-collapse.compact .nav-list>li>a {
    text-align: left;
    height: 39px;
    line-height: 37px;
    padding: 0 16px 0 7px;
}
.sidebar.navbar-collapse.compact .nav-list>li>a>.menu-icon {
    display: inline-block;
    vertical-align: sub;
    height: auto;
    line-height: inherit;
    margin: 0 2px 0 0;
    font-size: 18px;
}
.sidebar.navbar-collapse.compact .nav-list>li>a>.arrow, .sidebar.navbar-collapse.compact .nav-list>li>a>.menu-text {
    display: inline;
}
.sidebar.navbar-collapse.compact+.main-content {
    margin-left: 0;
}
}@media only screen and (min-width:992px) {
    .nav-list li.hover>.submenu {
    position: absolute;
    left: 100%;
    margin-left: -2px;
    top: -10px;
    bottom: auto;
    z-index: 1026;
    width: 190px;
    border: 1px solid;
    display: none!important;
    padding: 0 2px;
}
.nav-list li.hover.hover-show>.submenu, .nav-list li.hover:hover>.submenu {
    display: block!important;
}
.nav-list li.hover>.submenu:before, .nav-list li.hover>.submenu>li:before {
    display: none;
}
.nav-list li.hover.active>a:after {
    display: block;
}
.nav-list li.hover.active.hover-show>a.dropdown-toggle:after, .nav-list li.hover.active:hover>a.dropdown-toggle:after {
    display: none;
}
.nav-list li.hover .submenu>li.active>a:after {
    display: none!important;
}
.nav-list li.hover>.submenu.bottom {
    top: auto;
    bottom: -10px;
}
.nav-list li.hover>.submenu.bottom:after, .nav-list li.hover>.submenu.bottom:before {
    top: auto;
    bottom: 18px;
}
.nav-list li.hover>.submenu>li>a {
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 18px;
    border-top-width: 0;
}
.nav-list>li.hover>.submenu>li.active>a {
    font-weight: 700;
}
.nav-list>li>.submenu li.hover>.submenu>li>a {
    padding-left: 12px!important;
    margin-left: auto!important;
}
.nav-list>li>.submenu li.hover>.submenu>li>a>.menu-icon {
    margin-right: 2px;
}
.nav-list>li .submenu>li.hover>a {
    padding-left: 22px;
}
.nav-list>li .submenu>li.hover>a>.menu-icon {
    left: 4px;
}
}@media (min-width:992px) and (max-width:991px) {
    .sidebar.navbar-collapse .nav-list li.hover>.submenu {
    position: relative;
    left: auto;
    top: auto;
    z-index: auto;
    margin: auto;
    padding: 0;
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-width: 0;
}
.sidebar.navbar-collapse .nav-list li.hover>.submenu:before, .sidebar.navbar-collapse .nav-list li.hover>.submenu>li:before {
    display: block;
}
.sidebar.navbar-collapse .nav-list li.hover>.submenu li>a {
    padding-left: 37px;
}
.sidebar.navbar-collapse .nav-list>li.hover>.submenu {
    border-top-width: 1px;
}
.sidebar.navbar-collapse .nav-list li.hover>.submenu>li>a>.menu-icon {
    background-color: inherit;
    margin-right: auto;
    width: 18px;
    position: absolute;
}
.sidebar.navbar-collapse .nav-list>li .submenu>li.hover>a>.menu-icon {
    left: 10px;
}
.sidebar.navbar-collapse .nav-list li.hover>.submenu>li.active>a {
    font-weight: 400;
}
.sidebar.navbar-collapse .nav-list li.hover>.submenu>li.active>a>.menu-icon, .sidebar.navbar-collapse .nav-list li.hover>.submenu>li.hover-show>a>.menu-icon, .sidebar.navbar-collapse .nav-list li.hover>.submenu>li:hover>a>.menu-icon {
    display: inline-block;
}
.sidebar.navbar-collapse .nav-list>li>.submenu li.hover>.submenu {
    padding-left: 0;
    padding-right: 0;
}
.sidebar.navbar-collapse .nav-list>li>.submenu li.hover>.submenu>li>a {
    padding-left: 22px!important;
    margin-left: 20px!important;
}
.sidebar.navbar-collapse .nav-list>li>.submenu li.hover>.submenu>li>a>.menu-icon {
    display: inline-block;
    width: auto;
    position: static;
    background-color: transparent;
    margin-right: 4px;
}
.sidebar.navbar-collapse .nav-list>li>.submenu li>.submenu>li.hover>.submenu>li>a {
    margin-left: 20px!important;
    padding-left: 38px!important;
}
.sidebar.navbar-collapse .nav-list li.hover>.submenu>li>a {
    border-top-width: 1px;
    margin-top: 0;
    margin-bottom: 0;
}
.sidebar.navbar-collapse .nav-list>li.hover>.submenu>li:first-child>a {
    border-top-width: 0;
}
.sidebar.navbar-collapse .nav-list li.hover.hover-show>.submenu, .sidebar.navbar-collapse .nav-list li.hover:hover>.submenu, .sidebar.navbar-collapse .nav-list li.hover>.arrow, .sidebar.navbar-collapse .nav-list li.hover>.submenu {
    display: none!important;
}
.sidebar.navbar-collapse .nav-list li.hover.active>.submenu {
    display: block!important;
}
.sidebar.navbar-collapse .nav-list li.hover>.submenu.nav-hide {
    display: none!important;
}
.sidebar.navbar-collapse .nav-list li.hover>.submenu.nav-show {
    display: block!important;
}
.sidebar.navbar-collapse.compact .nav-list>li.hover.active>a:after {
    display: none;
}
}.nav-list li>.arrow {
    display: none;
    position: absolute;
    top: 8px;
    right: 1px;
    z-index: 1027;
}
.nav-list li>.arrow:after, .nav-list li>.arrow:before {
    border: 1px solid transparent;
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    left: -19px;
    top: 0;
    right: auto;
    bottom: auto;
    content: "";
    border-width: 10px;
}
.nav-list li>.arrow:before {
    z-index: 1;
    left: -20px;
}
.nav-list li>.arrow:after {
    z-index: 2;
}
.nav-list li.hover-show>a+.arrow, .nav-list li:hover>a+.arrow {
    display: none;
}
@media only screen and (min-width:992px) {
}.sidebar.menu-min, .sidebar.menu-min.compact, .sidebar.menu-min.navbar-collapse {
    width: 43px;
}
.sidebar.menu-min+.main-content {
    margin-left: 43px;
}
.sidebar.menu-min .nav-list a .badge, .sidebar.menu-min .nav-list a .label {
    position: relative;
    top: -1px;
    right: auto;
    left: 4px;
}
.sidebar.menu-min .nav-list .submenu .submenu a .badge, .sidebar.menu-min .nav-list .submenu .submenu a .label {
    top: 0;
}
.sidebar.menu-min .nav-list>li>a {
    position: relative;
    height: 39px;
    line-height: 37px;
    padding: 0;
}
.sidebar.menu-min .nav-list>li>a>.menu-icon {
    font-size: 18px;
    height: auto;
    line-height: inherit;
    display: inline-block;
    vertical-align: sub;
    margin-right: 0;
    width: 44px;
}
.sidebar.menu-min .nav-list>li>a>.menu-text {
    display: none;
    text-align: left;
    position: absolute;
    top: -1px;
    left: 42px;
    z-index: 1025;
    width: 176px;
    height: 41px;
    line-height: 38px;
    padding-left: 12px;
    border: 1px solid;
}
.sidebar.menu-min .nav-list>li>a.dropdown-toggle>.menu-text {
    top: -1px;
    border-width: 1px 1px 0;
}
.sidebar.menu-min .nav-list>li>a .arrow {
    display: none;
}
.sidebar.menu-min .nav-list>li.active.open>a:after, .sidebar.menu-min .nav-list>li.hover-show>a>.menu-text, .sidebar.menu-min .nav-list>li:hover>a>.menu-text {
    display: block;
}
.sidebar.menu-min .nav-list>li.active>a:after {
    border-width: 10px 6px;
    top: 8px;
}
.sidebar.menu-min .nav-list>li.active.open li.active>a:after {
    display: none;
}
.sidebar.menu-min .nav-list>li>.submenu {
    position: absolute;
    z-index: 1026;
    left: 42px;
    top: -1px;
    margin-top: 40px;
    padding-bottom: 2px;
    width: 176px;
    display: none!important;
    height: auto!important;
    visibility: visible!important;
}
.sidebar.menu-min .nav-list>li>.submenu:before {
    display: none;
}
.sidebar.menu-min .nav-list>li>.submenu:after {
    display: block;
    height: 1px;
    width: 1px;
    position: absolute;
    right: -1px;
    top: -1px;
    border-right: 1px solid;
    border-right-color: inherit;
}
.sidebar.menu-min .nav-list>li.open>.submenu, .sidebar.menu-min .nav-list>li>.submenu li:before {
    display: none;
}
.sidebar.menu-min .nav-list>li>.submenu li>a {
    margin-left: 0;
    padding-left: 22px;
}
.sidebar.menu-min .nav-list>li>.submenu li>a>.menu-icon {
    left: 4px;
}
.sidebar.menu-min .nav-list>li.hover>.submenu {
    padding-bottom: 0;
}
.sidebar.menu-min .nav-list>li.hover-show>.submenu, .sidebar.menu-min .nav-list>li:hover>.submenu {
    display: block!important;
}
.sidebar.menu-min .nav-list>li.hover-show>.arrow, .sidebar.menu-min .nav-list>li:hover>.arrow, .sidebar.menu-min .sidebar-shortcuts-mini {
    display: block;
}
.sidebar.menu-min .nav-list>li>.arrow {
    top: 10px;
}
.sidebar.menu-min .nav-list>li>.arrow:after, .sidebar.menu-min .nav-list>li>.arrow:before {
    border-width: 8px;
    left: -16px;
}
.sidebar.menu-min .nav-list>li>.arrow:before {
    left: -17px;
}
.sidebar.menu-min .nav-list li>.arrow {
    right: -1px;
}
.sidebar.menu-min .sidebar-shortcuts {
    position: relative;
    overflow: visible;
    z-index: 1;
}
.sidebar.menu-min .sidebar-shortcuts-large {
    display: none;
    position: absolute;
    top: 0;
    left: 42px;
    width: 182px;
    padding: 0 2px 3px;
    border: 1px solid;
    border-top-width: 0;
}
.sidebar.menu-min .sidebar-shortcuts.hover-show .sidebar-shortcuts-large, .sidebar.menu-min .sidebar-shortcuts:hover .sidebar-shortcuts-large {
    display: block;
}
.sidebar.menu-min .sidebar-toggle:before {
    left: 5px;
    right: 5px;
}
.sidebar.menu-min .sidebar-toggle>.ace-icon {
    font-size: 14px;
    padding: 0 4px;
    line-height: 15px;
    border-width: 1px;
}
.sidebar.menu-min .nav-list>li>.submenu li>.submenu>li>a {
    margin-left: 2px;
    padding-left: 30px;
}
.sidebar.menu-min .nav-list>li>.submenu li>.submenu>li>.submenu>li>a {
    margin-left: 2px;
    padding-left: 45px;
}
.sidebar.menu-min .nav-list li.active.open>.submenu>li.active>a:after, .sidebar.menu-min .nav-list>li.active.hover-show>a:after, .sidebar.menu-min .nav-list>li.active.open:hover>a:after, .sidebar.menu-min .nav-list>li.active:hover:after, .sidebar.menu-min .nav-list>li.active:hover>a:after, .sidebar.menu-min .nav-list>li>.submenu li.active>a:after {
    display: none;
}
@media (min-width:992px) {
    .sidebar.menu-min .nav-list li.hover>.submenu {
    margin-left: 0;
}
.sidebar.menu-min .nav-list>li>.submenu li.hover>.submenu>li>a, .sidebar.menu-min .nav-list>li>.submenu li>.submenu>li.hover>.submenu>li>a {
    margin-left: 0!important;
}
.sidebar.menu-min.compact .nav-list>li>a {
    height: 39px;
    line-height: 37px;
    padding: 0 16px 0 7px;
    position: relative;
}
.sidebar.menu-min.compact .nav-list>li>a>.menu-icon {
    font-size: 18px;
    height: auto;
    line-height: inherit;
    display: inline-block;
    vertical-align: sub;
    width: auto;
}
}@media only screen and (min-width:992px) {
    .navbar.h-navbar {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
}
.sidebar.h-sidebar {
    position: relative;
    float: none!important;
    width: auto;
    margin-top: 1px;
    border-width: 0!important;
    box-shadow: none;
}
.sidebar.h-sidebar.compact, .sidebar.h-sidebar.menu-min {
    width: auto;
}
.sidebar.h-sidebar.sidebar-fixed {
    position: fixed;
}
.sidebar.h-sidebar.no-gap {
    margin-top: auto;
}
.sidebar.h-sidebar.no-gap .nav-list {
    padding-top: 3px;
}
.sidebar.h-sidebar:before {
    display: block;
    position: absolute;
    z-index: -1;
    left: 0!important;
    right: 0!important;
    top: -17px;
    bottom: auto;
    height: 5px;
    width: auto;
    border-width: 0;
    border-bottom: 1px solid;
    border-bottom-color: inherit;
}
.main-content .sidebar.h-sidebar .nav-list>li:before, .sidebar.h-sidebar.lower-highlight .nav-list>li:before {
    top: 0;
}
.sidebar.h-sidebar .nav-list {
    border-width: 0;
    border-bottom: 2px solid #79B0CE;
}
.sidebar.h-sidebar .nav-list>li {
    float: left;
    border-width: 0 0 0 1px;
    margin-left: 0;
}
.sidebar.h-sidebar .nav-list>li:last-child {
    border-right-width: 1px;
}
.sidebar.h-sidebar .nav-list>li:before {
    left: -1px;
    right: -1px;
    bottom: auto;
    top: -2px;
    max-width: none;
    width: auto;
    height: 2px;
    border-width: 0;
}
.sidebar.h-sidebar .nav-list>li.active:before {
    display: block;
}
.sidebar.h-sidebar .nav-list>li.active:after {
    display: none;
}
.sidebar.h-sidebar .nav-list>li:first-child {
    border-left-width: 0;
    margin-left: 0;
}
.sidebar.h-sidebar .sidebar-shortcuts+.nav-list>li:first-child {
    border-left-width: 1px;
    margin-left: -1px;
}
.sidebar.h-sidebar .nav-list>li>a {
    line-height: 22px;
    height: auto;
    padding: 10px 14px;
}
.sidebar.h-sidebar .nav-list>li>a>.menu-icon {
    display: block;
    margin: 1px 0 4px;
    line-height: inherit;
    width: auto;
}
.sidebar.h-sidebar .nav-list>li.hover.hover-show>a~.arrow, .sidebar.h-sidebar .nav-list>li.hover:hover>a~.arrow, .sidebar.h-sidebar .nav-list>li>a>.arrow {
    display: none;
}
.sidebar.h-sidebar .nav-list>li.hover.pull_right {
    float: none!important;
}
.sidebar.h-sidebar .nav-list>li.hover>.submenu {
    top: 100%;
    left: 1px;
    margin-top: auto;
    margin-left: -2px;
    width: 190px;
    z-index: 1026;
}
.sidebar.h-sidebar .nav-list>li.hover.pull_right>.submenu {
    left: auto;
    right: 1px;
}
.sidebar.h-sidebar .nav-list>li.hover>.arrow {
    right: auto;
    top: auto;
    left: 24px;
    bottom: 7px;
}
.sidebar.h-sidebar .nav-list>li.hover.pull_right>.arrow {
    left: auto;
    right: 24px;
}
.sidebar.h-sidebar .nav-list>li.hover>.arrow:after, .sidebar.h-sidebar .nav-list>li.hover>.arrow:before {
    border-color: transparent;
    border-left-color: transparent!important;
    border-right-color: transparent!important;
    right: auto!important;
    left: -10px!important;
    border-width: 0 8px 8px!important;
}
.sidebar.h-sidebar .nav-list>li.hover>.arrow:before {
    top: -1px!important;
}
.sidebar.h-sidebar .nav-list>li.hover.hover-show>a.dropdown-toggle~.arrow, .sidebar.h-sidebar .nav-list>li.hover:hover>a.dropdown-toggle~.arrow {
    display: block;
}
.sidebar.h-sidebar .nav-list>li.hover-flip>.submenu {
    left: auto;
    right: 1px;
    margin-left: auto;
    margin-right: -2px;
}
.sidebar.h-sidebar .nav-list>li.hover-flip>.arrow {
    left: auto;
    right: 24px;
}
.sidebar.h-sidebar .nav-list li li.hover-flip>.submenu {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -2px;
}
.sidebar.h-sidebar .nav-list li li.hover-flip>.arrow {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0,  mirror=1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    left: 0;
    right: auto;
}
.sidebar.h-sidebar .sidebar-shortcuts-large:after, .sidebar.h-sidebar .sidebar-shortcuts-large:before {
    display: block;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    border: 1px solid transparent;
    border-width: 0 8px 8px;
}
.sidebar.h-sidebar .sidebar-shortcuts-large:after {
    border-right-color: transparent;
    top: -8px;
}
.sidebar.h-sidebar .sidebar-shortcuts-large:before {
    border-bottom-color: #CCC;
    top: -9px;
}
.sidebar.h-sidebar .sidebar-toggle {
    position: absolute;
    top: 12px;
    right: 6px;
    background-color: transparent;
    border-width: 0;
}
.sidebar.h-sidebar .sidebar-shortcuts {
    float: left;
    display: block;
    overflow: visible;
    position: relative;
    margin: 0;
    padding: 12px 8px 9px;
    max-height: none!important;
    background-color: transparent;
}
.sidebar.h-sidebar .sidebar-shortcuts.hover-show .sidebar-shortcuts-large, .sidebar.h-sidebar .sidebar-shortcuts:hover .sidebar-shortcuts-large {
    display: block;
}
.sidebar.h-sidebar .sidebar-shortcuts-large {
    display: none;
    width: 52px;
    height: auto;
    position: absolute;
    z-index: 1025;
    top: 100%;
    margin-top: -5px;
    left: 10px;
    padding: 3px 2px;
    border: 1px solid;
}
.sidebar.h-sidebar .sidebar-shortcuts-mini {
    width: auto;
    max-width: 52px;
    display: block;
    background-color: transparent;
    padding-top: 3px;
    padding-bottom: 5px;
}
.sidebar.h-sidebar .sidebar-shortcuts-mini>.btn {
    padding: 9px;
}
.sidebar.h-sidebar .nav-list a .badge, .sidebar.h-sidebar .nav-list a .label {
    position: absolute;
    top: 11px;
    right: auto!important;
    left: 50%;
    margin-left: 10px;
}
.sidebar.h-sidebar+.main-content {
    margin-left: 0;
}
.sidebar.h-sidebar+.main-content .breadcrumbs {
    margin: 6px 9px;
    border: 1px solid #E5E5E5;
}
.h-sidebar.sidebar-fixed {
    top: auto;
    width: 100%;
    left: 0;
    right: 0;
}
.h-sidebar.sidebar-fixed:before {
    z-index: auto;
}
.h-sidebar.sidebar-fixed, .h-sidebar.sidebar-fixed .nav-list {
    border-right-width: 0;
    border-left-width: 0;
}
.h-sidebar.sidebar-fixed .sidebar-shortcuts {
    border-width: 0;
}
.h-sidebar.sidebar-fixed+.main-content {
    padding-top: 88px;
}
.h-sidebar.sidebar-fixed.no-gap+.main-content {
    padding-top: 74px;
}
.h-sidebar.sidebar-fixed.menu-min+.main-content {
    padding-top: 63px;
}
.h-sidebar.sidebar-fixed.menu-min.no-gap+.main-content {
    padding-top: 49px;
}
}@media only screen and (min-width:992px) and (max-width:991px) {
    .sidebar.h-sidebar+.main-content .breadcrumbs {
    margin: 0;
    border-width: 0 0 1px;
}
}.main-container.container .h-sidebar.sidebar-fixed {
    left: 0;
    right: 0;
    width: auto;
}
@media (min-width:768px) {
    .main-container.container .h-sidebar.sidebar-fixed {
    left: auto!important;
    right: auto!important;
    width: 744px;
}
}@media (min-width:992px) {
    .main-container.container .h-sidebar.sidebar-fixed {
    width: 964px;
}
}@media (min-width:1200px) {
    .main-container.container .h-sidebar.sidebar-fixed {
    width: 1164px;
}
}@media (min-width:992px) {
    .sidebar.h-sidebar.menu-min .nav.nav-list>li>a, .sidebar.h-sidebar.menu-min.compact .nav.nav-list>li>a {
    padding: 9px 12px;
}
.sidebar.h-sidebar.menu-min .nav-list>li>a>.menu-text {
    left: -1px;
    top: 100%;
    width: 190px;
}
.sidebar.h-sidebar.menu-min .nav-list>li>a>.menu-icon {
    margin: 2px 0;
}
.sidebar.h-sidebar.menu-min .nav-list>li.hover>.submenu {
    top: 100%;
    margin-top: 41px;
}
.sidebar.h-sidebar.menu-min .nav-list>li.hover.hover-show>a~.arrow, .sidebar.h-sidebar.menu-min .nav-list>li.hover:hover>a~.arrow {
    display: block;
}
.sidebar.h-sidebar.menu-min .sidebar-toggle {
    top: 6px;
}
.sidebar.h-sidebar.menu-min .sidebar-shortcuts {
    padding: 0;
}
.sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini {
    padding-bottom: 4px;
    padding-top: 4px;
}
.sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini>.btn {
    padding: 8px;
}
.sidebar.h-sidebar.menu-min .sidebar-shortcuts-large {
    left: 1px;
    margin-top: 4px;
}
.sidebar.h-sidebar.compact .nav-list>li>a>.menu-icon {
    font-size: 18px;
    height: auto;
}
.sidebar.h-sidebar.compact.menu-min .nav-list>li>a {
    height: auto;
    line-height: 22px;
}
}.main-container .menu-toggler {
    display: none;
}
@media only screen and (max-width:991px) {

.sidebar.responsive {
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .15);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .15);
    z-index: 1027;
    overflow: hidden;
    max-height: 0;
    -moz-transform: translateX(-200px);
    -webkit-transform: translateX(-200px);
    -o-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    transform: translateX(-200px);
    -webkit-transition: -webkit-transform .2s linear 0s, max-height 0s linear .2s;
    -moz-transition: -moz-transform .2s linear 0s, max-height 0s linear .2s;
    -o-transition: -o-transform .2s linear 0s, max-height 0s linear .2s;
    transition: transform .2s linear 0s, max-height 0s linear .2s;
    position: relative;
    bottom: auto;
    left: 190px;
    margin-left: -190px;
}
}@media (min-width:992px) and (max-width:991px) {
    .sidebar.responsive.compact {
    -moz-transform: translateX(-115px);
    -webkit-transform: translateX(-115px);
    -o-transform: translateX(-115px);
    -ms-transform: translateX(-115px);
    transform: translateX(-115px);
}
.navbar.navbar-fixed-top+.main-container .sidebar.responsive.compact.push_away.display~.footer .footer-inner, .sidebar.responsive.compact.push_away.display+.main-content {
    -moz-transform: translateX(105px);
    -webkit-transform: translateX(105px);
    -o-transform: translateX(105px);
    -ms-transform: translateX(105px);
    transform: translateX(105px);
}
}@media only screen and (max-width:991px) {
    .sidebar.responsive.menu-min {
    -moz-transform: translateX(-53px);
    -webkit-transform: translateX(-53px);
    -o-transform: translateX(-53px);
    -ms-transform: translateX(-53px);
    transform: translateX(-53px);
}
.sidebar.responsive.display {
    -moz-transform: none!important;
    -webkit-transform: none!important;
    -o-transform: none!important;
    -ms-transform: none!important;
    transform: none!important;
    overflow: visible;
    max-height: 2000px;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}
.sidebar.responsive.push_away {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.sidebar.responsive.push_away:before {
    display: block!important;
}
.sidebar.responsive.push_away+.main-content {
    -webkit-transition: -webkit-transform .2s linear 0s;
    -moz-transition: -moz-transform .2s linear 0s;
    -o-transition: -o-transform .2s linear 0s;
    transition: transform .2s linear 0s;
}
.sidebar.responsive.push_away.display+.main-content {
    -moz-transform: translateX(190px);
    -webkit-transform: translateX(190px);
    -o-transform: translateX(190px);
    -ms-transform: translateX(190px);
    transform: translateX(190px);
}
.sidebar.responsive.push_away.display.menu-min+.main-content {
    -moz-transform: translateX(43px);
    -webkit-transform: translateX(43px);
    -o-transform: translateX(43px);
    -ms-transform: translateX(43px);
    transform: translateX(43px);
}
.navbar.navbar-fixed-top+.main-container .sidebar.responsive.push_away:before {
    height: 5000px;
}
.navbar.navbar-fixed-top+.main-container .sidebar.responsive.push_away~.footer .footer-inner {
    -webkit-transition: -webkit-transform .2s linear 0s;
    -moz-transition: -moz-transform .2s linear 0s;
    -o-transition: -o-transform .2s linear 0s;
    transition: transform .2s linear 0s;
}
.navbar.navbar-fixed-top+.main-container .sidebar.responsive.push_away.display~.footer .footer-inner {
    -moz-transform: translateX(190px);
    -webkit-transform: translateX(190px);
    -o-transform: translateX(190px);
    -ms-transform: translateX(190px);
    transform: translateX(190px);
}
.navbar.navbar-fixed-top+.main-container .sidebar.responsive.push_away.display.menu-min~.footer .footer-inner {
    -moz-transform: translateX(43px);
    -webkit-transform: translateX(43px);
    -o-transform: translateX(43px);
    -ms-transform: translateX(43px);
    transform: translateX(43px);
}
.sidebar.responsive+.main-content {
    margin-left: 0!important;
    margin-right: 0!important;
}
.responsive.sidebar-fixed {
    position: fixed;
}
.main-container .menu-toggler.fixed {
    position: fixed;
    left: auto;
    z-index: 1026;
    -moz-backface-visibility: hidden;
}
}@media only screen and (min-width:768px) and (max-width:991px) {
    .main-container.container .sidebar.responsive {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    display: none;
}
.main-container.container .sidebar.responsive:before {
    display: none!important;
}
.main-container.container .sidebar.responsive.display {
    display: block;
}
.main-container.container .sidebar.responsive.push_away+.main-content, .main-container.container .sidebar.responsive.push_away~.footer .footer-inner {
    -webkit-transition: none!important;
    -moz-transition: none!important;
    -o-transition: none!important;
    transition: none!important;
    -moz-transform: none!important;
    -webkit-transform: none!important;
    -o-transform: none!important;
    -ms-transform: none!important;
    transform: none!important;
}
}@media only screen and (max-width:991px) {
    .menu-toggler+.sidebar.responsive {
    margin-top: 40px;
}
.main-container .menu-toggler {
    display: block;
    position: absolute;
    z-index: 1026;
    width: 52px;
    height: 32px;
    margin-right: 2px;
    line-height: normal;
    padding-left: 33px;
    padding-top: 7px;
    padding-bottom: 1px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.main-container .menu-toggler:after, .main-container .menu-toggler:before {
    content: "";
    height: 2px;
    width: 24px;
    left: 4px;
    position: absolute;
}
.main-container .menu-toggler:hover {
    text-decoration: none;
}
.main-container .menu-toggler:focus {
    outline: 0;
}
.main-container .menu-toggler:before {
    border-top: 1px solid #87B87F;
    border-bottom: 1px solid #6FB3E0;
    z-index: 1;
    top: 13px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    -o-transition: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.main-container .menu-toggler:after {
    border-top: 1px solid #FFA24D;
    border-bottom: 1px solid #D15B47;
    top: 19px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    -o-transition: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.main-container .menu-toggler.display:before {
    height: 4px;
    top: 8px;
    border-width: 2px;
}
.main-container .menu-toggler.display:after {
    height: 4px;
    top: 20px;
    border-width: 2px;
}
.main-container .menu-toggler>.toggler-text {
    display: block;
    position: absolute;
    bottom: -9px;
    left: 0;
    border: 1px solid transparent;
    border-width: 9px 42px 0;
    border-top-color: #444;
}
.main-container .menu-toggler>.toggler-text:after {
    display: block;
    content: "MENU";
    color: #FFF;
    position: absolute;
    left: -8px;
    top: -41px;
}
.menu-toggler+.responsive.sidebar-scroll .nav-wrap {
    overflow: hidden;
}
}@media only screen and (max-width:320px) {
    .main-container .menu-toggler {
    width: 0;
}
.main-container .menu-toggler>.toggler-text {
    border-width: 7px 16px;
    bottom: -14px;
}
.main-container .menu-toggler>.toggler-text:after {
    font-size: 9px;
    font-weight: 400;
    color: #FFF;
    position: absolute;
    left: -13px;
    top: -42px;
}
.main-container .menu-toggler:after, .main-container .menu-toggler:before {
    margin-top: 8px;
}
.main-container .menu-toggler.display:after, .main-container .menu-toggler.display:before {
    height: 2px;
    border-width: 1px;
}
.main-container .menu-toggler.display:before {
    top: 13px;
}
.main-container .menu-toggler.display:after {
    top: 19px;
}
}.sidebar-toggle.sidebar-expand {
    display: none;
}
@media (max-width:991px) {
    .menu-toggler.invisible {
    position: fixed;
    z-index: -999;
    visibility: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
}
.sidebar.responsive-min, .sidebar.responsive-min.compact, .sidebar.responsive-min.navbar-collapse {
    width: 43px;
}
.sidebar.responsive-min .nav-list a .badge, .sidebar.responsive-min .nav-list a .label {
    position: relative;
    top: -1px;
    right: auto;
    left: 4px;
}
.sidebar.responsive-min .nav-list .submenu .submenu a .badge, .sidebar.responsive-min .nav-list .submenu .submenu a .label {
    top: 0;
}
.sidebar.responsive-min .nav-list>li>a {
    position: relative;
    height: 39px;
    line-height: 37px;
    padding: 0;
}
.sidebar.responsive-min .nav-list>li>a>.menu-icon {
    font-size: 18px;
    height: auto;
    line-height: inherit;
    display: inline-block;
    vertical-align: sub;
    margin-right: 0;
    width: 44px;
}
.sidebar.responsive-min .nav-list>li>a>.menu-text {
    display: none;
    text-align: left;
    position: absolute;
    top: -1px;
    left: 42px;
    z-index: 1025;
    width: 176px;
    height: 41px;
    line-height: 38px;
    padding-left: 12px;
    border: 1px solid;
}
.sidebar.responsive-min .nav-list>li>a.dropdown-toggle>.menu-text {
    top: -1px;
    border-width: 1px 1px 0;
}
.sidebar.responsive-min .nav-list>li>a .arrow {
    display: none;
}
.sidebar.responsive-min .nav-list>li.active.open>a:after, .sidebar.responsive-min .nav-list>li.hover-show>a>.menu-text, .sidebar.responsive-min .nav-list>li:hover>a>.menu-text {
    display: block;
}
.sidebar.responsive-min .nav-list>li.active>a:after {
    border-width: 10px 6px;
    top: 8px;
}
.sidebar.responsive-min .nav-list>li.active.open li.active>a:after {
    display: none;
}
.sidebar.responsive-min .nav-list>li>.submenu {
    position: absolute;
    z-index: 1026;
    left: 42px;
    top: -1px;
    margin-top: 40px;
    padding-bottom: 2px;
    width: 176px;
    display: none!important;
    height: auto!important;
    visibility: visible!important;
}
.sidebar.responsive-min .nav-list>li>.submenu:before {
    display: none;
}
.sidebar.responsive-min .nav-list>li>.submenu:after {
    content: "";
    display: block;
    height: 1px;
    width: 1px;
    position: absolute;
    right: -1px;
    top: -1px;
    border-right: 1px solid;
    border-right-color: inherit;
}
.sidebar.responsive-min .nav-list>li.open>.submenu, .sidebar.responsive-min .nav-list>li>.submenu li:before {
    display: none;
}
.sidebar.responsive-min .nav-list>li>.submenu li>a {
    margin-left: 0;
    padding-left: 22px;
}
.sidebar.responsive-min .nav-list>li>.submenu li>a>.menu-icon {
    left: 4px;
}
.sidebar.responsive-min .nav-list>li.hover>.submenu {
    padding-bottom: 0;
}
.sidebar.responsive-min .nav-list>li.hover-show>.submenu, .sidebar.responsive-min .nav-list>li:hover>.submenu {
    display: block!important;
}
.sidebar.responsive-min .nav-list>li.hover-show>.arrow, .sidebar.responsive-min .nav-list>li:hover>.arrow, .sidebar.responsive-min .sidebar-shortcuts-mini {
    display: block;
}
.sidebar.responsive-min .nav-list>li>.arrow {
    top: 10px;
}
.sidebar.responsive-min .nav-list>li>.arrow:after, .sidebar.responsive-min .nav-list>li>.arrow:before {
    border-width: 8px;
    left: -16px;
}
.sidebar.responsive-min .nav-list>li>.arrow:before {
    left: -17px;
}
.sidebar.responsive-min .nav-list li>.arrow {
    right: -1px;
}
.sidebar.responsive-min .sidebar-shortcuts {
    position: relative;
    overflow: visible;
    z-index: 1;
}
.sidebar.responsive-min .sidebar-shortcuts-large {
    display: none;
    position: absolute;
    top: 0;
    left: 42px;
    width: 182px;
    padding: 0 2px 3px;
    border: 1px solid;
    border-top-width: 0;
}
.sidebar.responsive-min .sidebar-shortcuts.hover-show .sidebar-shortcuts-large, .sidebar.responsive-min .sidebar-shortcuts:hover .sidebar-shortcuts-large {
    display: block;
}
.sidebar.responsive-min .sidebar-toggle:before {
    left: 5px;
    right: 5px;
}
.sidebar.responsive-min .sidebar-toggle>.ace-icon {
    font-size: 14px;
    padding: 0 4px;
    line-height: 15px;
    border-width: 1px;
}
.sidebar.responsive-min .nav-list>li>.submenu li>.submenu>li>a {
    margin-left: 2px;
    padding-left: 30px;
}
.sidebar.responsive-min .nav-list>li>.submenu li>.submenu>li>.submenu>li>a {
    margin-left: 2px;
    padding-left: 45px;
}
.sidebar.responsive-min .nav-list li.active.open>.submenu>li.active>a:after, .sidebar.responsive-min .nav-list>li.active.hover-show>a:after, .sidebar.responsive-min .nav-list>li.active.open:hover>a:after, .sidebar.responsive-min .nav-list>li.active:hover:after, .sidebar.responsive-min .nav-list>li.active:hover>a:after, .sidebar.responsive-min .nav-list>li>.submenu li.active>a:after {
    display: none;
}
.sidebar.responsive-min:before {
    display: block;
}
.sidebar.responsive-min .sidebar-toggle.sidebar-collapse {
    display: none;
}
.sidebar.responsive-min .sidebar-toggle.sidebar-expand {
    display: block;
}
.sidebar.responsive-min+.main-content {
    margin-left: 43px!important;
}
.rtl .sidebar.responsive-min+.main-content {
    margin-left: auto!important;
    margin-right: 43px!important;
}
.sidebar.responsive-min+.main-content .breadcrumb {
    margin-left: 12px;
}
.sidebar.responsive-max {
    display: block;
    position: relative;
    left: 190px;
    margin-left: -190px;
    z-index: 1027;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .15);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .15);
}
.sidebar.responsive-max .sidebar-toggle.sidebar-collapse, .sidebar.responsive-max:before {
    display: none;
}
.sidebar.responsive-max .sidebar-toggle.sidebar-expand {
    display: block;
    z-index: 1;
}
.sidebar.responsive-max+.main-content {
    margin-left: 43px!important;
}
.sidebar.responsive-max+.main-content .breadcrumb {
    margin-left: 12px;
}
}@media only screen and (min-width:992px) {
    .sidebar.navbar-collapse {
    max-height: none;
}
}@media only screen and (max-width:991px) {
    .sidebar.navbar-collapse {
    position: relative;
    float: none!important;
    margin-top: auto!important;
    z-index: 1;
    width: 100%!important;
    max-height: 340px;
    margin-left: 0;
    margin-right: 0;
}
.sidebar.navbar-collapse:before {
    display: none;
}
.sidebar.navbar-collapse .nav-list>li:last-child {
    border-bottom-width: 0;
}
.sidebar.navbar-collapse .nav-list>li>a {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    line-height: 20px;
    height: 41px;
}
.sidebar.navbar-collapse .nav-list>li:before {
    height: 43px;
}
.sidebar.navbar-collapse li>.arrow {
    display: none!important;
}
.sidebar.navbar-collapse .nav-wrap+.ace-scroll, .sidebar.navbar-collapse .sidebar-toggle {
    display: none;
}
.sidebar.navbar-collapse .nav-list li>.submenu {
    border-left-width: 0;
}
.sidebar.navbar-collapse .sidebar-shortcuts {
    padding: 3px 0 6px;
    max-height: none;
    border-bottom-width: 0;
}
.sidebar.navbar-collapse+.main-content {
    margin-left: 0!important;
    margin-right: 0!important;
}
.sidebar.navbar-collapse+.main-content .breadcrumb {
    margin-left: 12px;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>.submenu {
    position: relative;
    left: auto;
    top: auto;
    width: auto;
    z-index: auto;
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 1px 0 0!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>.submenu:before, .sidebar.navbar-collapse.menu-min .nav-list>li>.submenu>li:before {
    display: block;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>.submenu li>a>.menu-icon {
    left: 10px;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>.submenu:after {
    display: none;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>.submenu li>a {
    padding: 7px 0 9px 37px;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>.submenu li>.submenu>li>a {
    margin-left: 20px;
    padding-left: 22px;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>.submenu li>.submenu>li>.submenu>li>a {
    margin-left: 20px;
    padding-left: 38px;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>a:hover:before {
    width: 3px;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>a {
    text-align: left;
    padding-left: 7px;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>a>.menu-icon {
    display: inline-block;
    width: auto;
    margin-right: 2px;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>a>.menu-text {
    position: relative;
    display: inline;
    line-height: normal;
    padding-left: 0;
    height: auto;
    top: auto;
    left: auto;
    z-index: auto;
    width: auto;
    border-width: 0!important;
    background-color: transparent!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.sidebar.navbar-collapse.menu-min .nav-list a>.arrow {
    display: block;
}
.sidebar.navbar-collapse.menu-min .nav-list>li:hover>.submenu {
    display: none!important;
}
.sidebar.navbar-collapse.menu-min .nav-list>li.active>.submenu, .sidebar.navbar-collapse.menu-min .nav-list>li>.submenu.nav-show {
    display: block!important;
}
.sidebar.navbar-collapse.menu-min .nav-list>li>.submenu.nav-hide {
    display: none!important;
}
.sidebar.navbar-collapse.menu-min .sidebar-shortcuts-large {
    display: block;
    position: static;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    border-width: 0!important;
    background-color: transparent!important;
    width: auto;
    padding: 0;
}
.sidebar.navbar-collapse.menu-min .sidebar-shortcuts-mini {
    display: none;
}
.sidebar.navbar-collapse .nav-list a .badge, .sidebar.navbar-collapse .nav-list a .label {
    position: relative;
    top: -1px;
    right: auto;
    left: 4px;
}
.sidebar.navbar-collapse .nav-list li.active>a:after, .sidebar.navbar-collapse .nav-list li.active>a:before, .sidebar.navbar-collapse .nav-list>li.active:after, .sidebar.navbar-collapse .nav-list>li.highlight.active>a:after, .sidebar.navbar-collapse .nav-list>li.highlight.active>a:before {
    display: none!important;
}
}@media (max-width:991px) and (max-height:400px) {
    .sidebar.navbar-collapse {
    max-height: 300px;
}
}@media (max-width:991px) and (max-height:350px) {
    .sidebar.navbar-collapse {
    max-height: 250px;
}
}@media (max-width:991px) and (max-height:300px) {
    .sidebar.navbar-collapse {
    max-height: 200px;
}
}@media (max-width:991px) and (max-height:250px) {
    .sidebar.navbar-collapse {
    max-height: 150px;
}
}@media (min-width:992px) and (max-width:991px) {
    .sidebar.navbar-collapse .nav-list>li>.submenu li.hover>.submenu>li>a, .sidebar.navbar-collapse .nav-list>li>.submenu li>.submenu>li.hover>.submenu>li>a {
    margin-left: 20px!important;
}
}@media (max-width:991px) {
    .navbar-fixed-top+.main-container .sidebar.navbar-collapse {
    position: fixed;
    z-index: 1027;
}
}@media (min-width:768px) and (max-width:991px) {
    .navbar-fixed-top+.main-container.container .sidebar.navbar-collapse {
    width: 744px!important;
}
}.main-content-inner {
    float: left;
    width: 100%}
.main-content .sidebar:before {
    top: 150px;
}
@media only screen and (min-width:992px) {
body.mob-safari .sidebar.h-sidebar.no-gap+.main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
    top: 114px;
}
body.mob-safari .sidebar.h-sidebar.menu-min+.main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
    top: 106px;
}
body.mob-safari .sidebar.h-sidebar.menu-min.no-gap+.main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
    top: 89px;
}
.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar):before {
    top: 150px;
}
.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar) .nav-list {
    padding-top: 3px;
}
.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts~.nav-list, .h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts~.nav-wrap-up .nav-list {
    padding-top: 0;
}
.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts {
    padding-top: 3px;
    min-height: 43px;
}
.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar).menu-min .sidebar-shortcuts-large {
    line-height: 40px;
}
.h-navbar.navbar-fixed-top+.main-container .main-content .sidebar:not(.h-sidebar) .nav-list {
    padding-top: 0;
}
.h-navbar.navbar-fixed-top+.main-container .main-content .sidebar.sidebar-fixed {
    z-index: 1026;
}
.main-content .h-sidebar {
    padding-top: 1px;
    margin-top: 0;
}
.main-content .h-sidebar:before {
    display: none;
}
.main-content .h-sidebar+.page-content {
    margin-left: auto;
}
.main-content .h-sidebar.sidebar-fixed {
    left: 190px;
}
.main-content .h-sidebar.sidebar-fixed+.page-content {
    margin-top: 74px;
}
.main-content .h-sidebar.sidebar-fixed.menu-min+.page-content {
    margin-top: 49px;
}
.main-content .h-sidebar.menu-min .nav-list>li>a {
    padding: 8px 12px 7px;
}
.sidebar.compact+.main-content .h-sidebar.sidebar-fixed {
    left: 105px;
}
.sidebar.menu-min+.main-content .h-sidebar.sidebar-fixed {
    left: 43px;
}
.navbar.navbar-fixed-top+.main-container .main-content .h-sidebar.sidebar-fixed {
    padding-top: 3px;
}
}.container.main-container .sidebar+.main-content .h-sidebar.sidebar-fixed {
    left: auto;
    right: auto;
}
@media (min-width:992px) {
    .container.main-container .sidebar.compact+.main-content .h-sidebar.sidebar-fixed {
    width: 859px;
}
.container.main-container .main-content .h-sidebar.sidebar-fixed {
    width: 964px;
}
.container.main-container .sidebar+.main-content .h-sidebar.sidebar-fixed {
    width: 774px;
}
.container.main-container .sidebar.menu-min+.main-content .h-sidebar.sidebar-fixed {
    width: 921px;
}
}@media (min-width:1200px) {
    .container.main-container .sidebar.compact+.main-content .h-sidebar.sidebar-fixed {
    width: 1059px;
}
.container.main-container .main-content .h-sidebar.sidebar-fixed {
    width: 1164px;
}
.container.main-container .sidebar+.main-content .h-sidebar.sidebar-fixed {
    width: 974px;
}
.container.main-container .sidebar.menu-min+.main-content .h-sidebar.sidebar-fixed {
    width: 1121px;
}
}@media only screen and (max-width:991px) {
    .main-container .main-content .sidebar.sidebar-fixed {
    z-index: 1026;
}
.navbar-fixed-top+.main-container .main-content .sidebar {
    position: fixed;
    z-index: 1026;
}
}.sidebar-scroll .sidebar-shortcuts {
    min-height: 41px;
    border-bottom: 1px solid;
}
.sidebar-scroll .sidebar-toggle {
    border-top: 1px solid;
}
.sidebar-scroll .nav-list>li:first-child {
    border-top-width: 0;
}
.sidebar-scroll .nav-list>li:last-child {
    border-bottom-width: 0;
}
.sidebar-scroll .nav-wrap .sidebar-shortcuts {
    min-height: 40px;
    border-bottom-width: 0;
}
.sidebar-scroll .nav-wrap .sidebar-shortcuts+.nav-list>li:first-child, .sidebar-scroll .nav-wrap .sidebar-shortcuts+.nav-wrap-up .nav-list>li:first-child {
    border-top-width: 1px;
}
.sidebar-scroll .nav-wrap-t .sidebar-toggle {
    border-top-width: 0;
}
.sidebar-scroll .nav-wrap-t .nav-list>li:last-child {
    border-bottom-width: 1px;
}
.sidebar .nav-wrap+.ace-scroll {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}
.sidebar .nav-wrap+.scrollout {
    right: -8px;
}
.sidebar .submenu.sub-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.ace-scroll, .scroll-content {
    overflow: hidden;
}
.scroll-content {
    position: static;
}
.scroll-disabled.ace-scroll, .scroll-disabled.ace-scroll .scroll-content {
    overflow: visible;
}
.scroll-track {
    position: absolute;
    top: auto;
    bottom: auto;
    right: 0;
    height: auto;
    background-color: #E7E7E7;
    z-index: 99;
    width: 0;
    opacity: 0;
}
.scroll-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    background: #bbd4e5;
}
.scroll-track.scroll-hz {
    top: auto;
    left: auto;
    right: auto;
    bottom: 0;
    height: 8px;
    width: auto;
}
.scroll-hz .scroll-bar {
    height: inherit;
    width: auto;
}
.scroll-track.scroll-active {
    -webkit-transition: width .25s ease .75s, opacity .25s ease .75s;
    -o-transition: width .25s ease .75s, opacity .25s ease .75s;
    transition: width .25s ease .75s, opacity .25s ease .75s;
}
.ace-scroll:hover .scroll-active, .scroll-active.scroll-track.active, .scroll-active.scroll-track.scroll-hover, .scroll-active.scroll-track:active, .scroll-active.scroll-track:hover {
    width: 8px;
    opacity: 1;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.scroll-track.active>.scroll-bar {
    transition-property: none!important;
    transition-duration: 0s!important;
}
.scroll-track.scroll-margin {
    margin-left: -1px;
}
.scroll-track.scroll-left {
    right: auto;
    left: 0;
}
.scroll-track.scroll-left.scroll-margin {
    margin-left: 1px;
}
.scroll-track.scroll-top {
    bottom: auto;
    top: 0;
}
.scroll-track.scroll-top.scroll-margin {
    top: 1px;
}
.scroll-dark .scroll-bar {
    background-color: transparent;
    background-color: rgba(0, 0, 0, .25);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000',  endColorstr='#40000000',  GradientType=0 );
}
.scroll-track.scroll-dark {
    background-color: transparent;
    background-color: rgba(0, 0, 0, .15);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26000000',  endColorstr='#26000000',  GradientType=0 );
}
.scroll-light .scroll-bar {
    background-color: transparent;
    background-color: rgba(0, 0, 0, .14);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24000000',  endColorstr='#24000000',  GradientType=0 );
}
.scroll-track.scroll-light {
    background-color: transparent;
    background-color: rgba(0, 0, 0, .07);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12000000',  endColorstr='#12000000',  GradientType=0 );
}
.scroll-white .scroll-bar {
    background-color: transparent;
    background-color: rgba(255, 255, 255, .33);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55FFFFFF',  endColorstr='#55FFFFFF',  GradientType=0 );
}
.scroll-track.scroll-white {
    background-color: transparent;
    background-color: rgba(255, 255, 255, .2);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33FFFFFF',  endColorstr='#33FFFFFF',  GradientType=0 );
}
.no-track.scroll-track {
    background-color: transparent;
    filter: progid:DXImageTransform.Microsoft.gradient( enabled=false );
}
.scroll-track.scroll-visible {
    opacity: 1;
    width: 8px;
}
.scroll-hz.scroll-visible {
    height: 8px;
    width: auto;
}
.ace-scroll:hover .scroll-thin.scroll-track, .scroll-thin.scroll-track.active, .scroll-thin.scroll-track.scroll-hover, .scroll-thin.scroll-track.scroll-visible, .scroll-thin.scroll-track:active, .scroll-thin.scroll-track:hover {
    width: 6px;
}
.ace-scroll:hover .scroll-thin.scroll-hz, .scroll-thin.scroll-hz.active, .scroll-thin.scroll-hz.scroll-hover, .scroll-thin.scroll-hz.scroll-visible, .scroll-thin.scroll-hz:active, .scroll-thin.scroll-hz:hover {
    width: auto;
    height: 6px;
}
.scroll-chrome.scroll-active .scroll-bar {
    background-color: transparent;
    width: 11px;
}
.scroll-chrome.scroll-active .scroll-bar:before {
    display: block;
    content: "";
    position: absolute;
    top: 1px;
    bottom: 3px;
    left: 1px;
    right: 1px;
    background-color: #D9D9D9;
    border: 1px solid #BBB;
    border-radius: 1px;
}
.scroll-chrome.scroll-active .scroll-track {
    width: 12px;
    background-color: #F2F2F2;
    border: 1px solid;
    border-width: 1px 0 1px 1px;
    border-color: #E6E6E6 transparent #E6E6E6 #DBDBDB;
}
.scroll-chrome.scroll-active .scroll-track:hover .scroll-bar:before {
    background-color: silver;
    border-color: #A6A6A6;
}
.scroll-chrome.scroll-active .scroll-track.active .scroll-bar:before {
    background-color: #A9A9A9;
    border-color: #8B8B8B;
}
.scroll-active.scroll-track.idle-hide {
    opacity: 0;
    filter: alpha(opacity=0);
    width: 8px;
}
.scroll-active.scroll-track.idle-hide.not-idle {
    width: 8px;
    opacity: 1;
    filter: alpha(opacity=100);
}
.scroll-active.scroll-thin.scroll-track.idle-hide {
    width: 6px;
}
.scroll-active.scroll-chrome .scroll-track.idle-hide {
    width: 12px;
}
.nav-wrap+.scroll-active .scroll-track {
    width: 8px;
}
.nav-scroll.scroll-active .scroll-track {
    width: 8px;
    right: 0;
}
.nav-scroll.scroll-active:hover .scroll-track, .nav-wrap:hover+.scroll-active .scroll-track {
    width: 8px;
    opacity: 1;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.nav-scroll.scroll-active:hover .scroll-track {
    width: 8px;
}
.nav-scroll.ace-scroll, .nav-scroll.ace-scroll .scroll-content {
    overflow: hidden;
}
.modal.aside-vc, .nav-scroll.ace-scroll.scroll-disabled, .nav-scroll.ace-scroll.scroll-disabled .scroll-content {
    overflow: visible;
}
.scroll-track.scroll-detached {
    position: absolute;
    z-index: 1029;
    bottom: auto;
    right: auto;
}
.modal-content {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.modal-footer {
    padding-top: 12px;
    padding-bottom: 14px;
    border-top-color: #E4E9EE;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #EFF3F8;
}
.modal-header .close {
    font-size: 32px;
}
.modal-body.padding-25 {
    padding-left: 25px;
    padding-right: 25px;
}
.modal.aside-dark .modal-content {
    background-color: rgba(0, 0, 0, .85);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D8000000',  endColorstr='#D8000000',  GradientType=0 );
}
.modal.aside {
    z-index: 999;
    position: absolute;
}
.aside.aside-contained, .modal.aside-fixed.aside-vc, .modal.aside.in {
    position: fixed;
}
.navbar-fixed-top~.modal.aside-vc {
    z-index: 1025;
}
.modal.aside-fixed.aside-hz, .navbar-fixed-bottom~.modal.aside-hz, .navbar-fixed-top~.modal.aside-hz {
    position: fixed;
    z-index: 1049;
}
.modal.aside.in {
    z-index: 1050;
}
.modal.aside-vc {
    margin: auto;
    width: 0;
    left: auto;
    right: auto;
    top: 0;
    bottom: 0;
    display: block!important;
}
.modal.in.aside-vc {
    width: 100%}
.modal.aside-vc .modal-dialog {
    margin: inherit;
    overflow: inherit;
    width: 250px;
    max-width: 66%;
    height: inherit;
    position: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    opacity: 1;
    transition: transform .3s ease-out 0s;
    -webkit-transition: -webkit-transform .3s ease-out 0s;
}
@media only screen and (max-width:319px) {
    .modal.aside-vc .modal-dialog {
    max-width: none;
    width: 200px;
}
.aside-contained.aside-vc .modal-dialog {
    width: 180px;
}
}@media only screen and (max-width:240px) {
    .modal.aside-vc .modal-dialog {
    max-width: none;
    width: 160px;
}
.aside-contained.aside-vc .modal-dialog {
    width: 140px;
}
}.modal.aside-vc .modal-content {
    height: 100%;
    overflow: hidden;
}
.modal.in.aside-vc .modal-dialog {
    transform: none;
    -webkit-transform: none;
    height: auto;
}
.modal.aside-vc .aside-trigger {
    position: absolute;
    top: 155px;
    right: auto;
    left: auto;
    bottom: auto;
    margin-top: -1px;
    width: 37px;
    outline: 0;
}
.modal.aside-vc .aside-trigger.ace-settings-btn {
    width: 42px;
}
.modal.in.aside-vc .aside-trigger {
    z-index: -1;
}
.aside.modal.in .modal-backdrop.in+.modal-dialog .aside-trigger {
    z-index: auto;
}
@media only screen and (max-height:240px) {
    .modal.aside-vc .aside-trigger {
    top: 130px;
}
}.modal.aside-vc.navbar-offset .modal-dialog {
    top: 45px;
}
.modal.aside-vc.navbar-offset .modal-dialog .aside-trigger {
    top: 110px;
}
@media (max-width:479px) {
}.modal.aside-right {
    right: 0;
}
.modal.aside-right .modal-content {
    border-width: 0 0 0 1px;
    box-shadow: -2px 1px 2px 0 rgba(0, 0, 0, .15);
}
.modal.aside-right .aside-trigger {
    right: 100%}
.modal.aside-right .modal-dialog {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}
.modal.aside-left {
    left: 0;
}
.modal.aside-left .modal-content {
    border-width: 0 1px 0 0;
    box-shadow: 2px -1px 2px 0 rgba(0, 0, 0, .15);
}
.modal.aside-left .aside-trigger {
    left: 100%}
.modal.aside-right .aside-trigger.btn.ace-settings-btn {
    border-radius: 6px 0 0 6px;
}
.modal.aside-left .aside-trigger.btn.ace-settings-btn {
    border-radius: 0 6px 6px 0;
}
.modal.aside-left .modal-dialog {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}
.modal.aside-hz {
    margin: auto;
    height: 0;
    left: 0;
    right: 0;
    top: auto;
    bottom: auto;
    display: block!important;
    overflow: visible;
}
.aside.aside-hidden .modal-content, .wz-finish.ng-hide, .wz-next.ng-hide {
    display: none;
}
.modal.in.aside-hz {
    height: 100%}
.modal.aside-hz .modal-dialog {
    margin: inherit;
    height: auto;
    overflow: inherit;
    max-height: 50%;
    width: inherit;
    position: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    opacity: 1;
    transition: transform .3s ease-out 0s;
    -webkit-transition: -webkit-transform .3s ease-out 0s;
}
@media only screen and (max-height:320px) {
    .modal.aside-hz .modal-dialog {
    max-height: 66%}
}.modal.aside-hz .modal-content {
    width: 100%;
    overflow: hidden;
}
.modal.in.aside-hz .modal-dialog {
    transform: none;
    -webkit-transform: none;
    height: auto;
}
.modal.aside-hz .aside-trigger {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    margin-top: -1px;
    z-index: auto;
    outline: 0;
    margin-left: -15px;
    left: 50%}
.modal.aside-hz .aside-trigger.ace-settings-btn {
    margin-left: -20px;
}
.modal.in.aside-hz .aside-trigger {
    z-index: -1;
}
.modal.aside-top {
    top: 0;
}
.modal.aside-top .modal-dialog {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
}
.modal.aside-top .modal-content {
    border-width: 0;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, .15);
}
.modal.aside-bottom {
    bottom: 0;
}
.modal.aside-bottom .modal-dialog {
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
}
.modal.aside-bottom .modal-content {
    border-width: 0;
    box-shadow: -1px 2px 2px 0 rgba(0, 0, 0, .15);
}
.modal.aside-bottom .aside-trigger {
    bottom: 100%;
    margin-top: auto;
    margin-bottom: -1px;
}
.modal.aside-top .aside-trigger.ace-settings-btn {
    border-radius: 0 0 6px 6px!important;
}
.modal.aside-bottom .aside-trigger.ace-settings-btn {
    border-radius: 6px 6px 0 0!important;
}
@media only screen and (min-width:768px) {
    .container.main-container~.modal.aside-vc .modal-dialog {
    transition: none;
    -webkit-transition: none;
}
.container.main-container~.modal.aside-vc:not(.in) .modal-content {
    display: none;
}
}.aside-vc.in.no-backdrop {
    width: auto;
}
.aside-hz.in.no-backdrop {
    height: auto;
}
.modal.aside .modal-backdrop {
    position: fixed;
    bottom: 0;
    height: auto!important;
}
.modal.aside-hz .aside-trigger.align-left {
    left: 15px;
    margin-left: auto;
    text-align: center;
}
.modal.aside-hz .aside-trigger.align-right {
    text-align: center;
    left: auto;
    margin-left: auto;
    right: 15px;
}
.modal.transition-off .modal-dialog {
    transition: none;
    -webkit-transition: none;
}
.modal.ng-aside .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}
.modal.ng-aside.bottom .modal-content {
    border-top: 1px solid rgba(0, 0, 0, .2);
}
.modal.ng-aside.top .modal-content {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}
.modal.ng-aside.left .modal-content {
    border-right: 1px solid rgba(0, 0, 0, .2);
}
.modal.ng-aside.right .modal-content {
    border-left: 1px solid rgba(0, 0, 0, .2);
}
.modal-backdrop.in[ng-class] {
    bottom: 0;
}
.ng-aside .modal-dialog {
    width: 320px;
}
.breadcrumb, .breadcrumb>li>a, .btn {
    display: inline-block;
}
.breadcrumbs {
    /*position: relative;*/
    z-index: auto;
    border-bottom: 1px solid #E5E5E5;
    background-color: #F5F5F5;
    min-height: 0px;
    line-height: 40px;
    padding: 0 12px 0 0;
}
.breadcrumb {
    background-color: transparent;
    line-height: 20px;
    margin: 6px 22px 0 12px;
    padding: 0;
    font-size: 14px;
    color: #333;
    border-radius: 0;
}
.breadcrumb>li, .breadcrumb>li.active {
    color: #555;
    padding: 0 3px;
}
.breadcrumb>li>a {
    color: #4C8FBD;
}
.breadcrumb>li+li:before {
    font-family: FontAwesome;
    font-size: 14px;
    content: "\f105";
    color: #B2B6BF;
    padding: 0;
    margin: 0 8px 0 0;
    position: relative;
    top: 1px;
}
.breadcrumb .home-icon {
    font-size: 20px;
    margin-left: 2px;
    margin-right: 2px;
    vertical-align: top;
}
@media only screen and (max-width:480px) {
    .modal.aside-vc .modal-dialog {
    width: 240px;
}
.breadcrumb>li>a {
    padding: 0 1px;
}
}@media only screen and (max-width:991px) {
    .menu-toggler+.sidebar.responsive+.main-content .breadcrumb {
    margin-left: 90px;
}
}@media only screen and (max-width:320px) {
    .modal.aside-vc .modal-dialog {
    width: 200px;
}
.breadcrumb {
    margin-left: 8px;
}
.menu-toggler+.sidebar.responsive+.main-content .breadcrumb {
    margin-left: 36px;
}
}@media (min-width:992px) {
    .breadcrumbs-fixed {
    position: fixed;
    right: 0;
    left: 0;
    top: auto;
    z-index: 1024;
}
.breadcrumbs-fixed+.page-content {
    padding-top: 49px;
}
.sidebar+.main-content .breadcrumbs-fixed {
    left: 190px;
}
body.mob-safari .breadcrumbs-fixed {
    top: 45px;
}
.sidebar.compact+.main-content .breadcrumbs-fixed {
    left: 105px;
}
.sidebar.menu-min+.main-content .breadcrumbs-fixed {
    left: 43px;
}
}@media (min-width:992px) and (max-width:991px) {
    .breadcrumbs-fixed, .sidebar.menu-min+.main-content .breadcrumbs-fixed {
    left: 0;
}
.sidebar.responsive-max+.main-content .breadcrumbs-fixed, .sidebar.responsive-min+.main-content .breadcrumbs-fixed {
    left: 43px;
}
}@media (min-width:992px) {
    .container.main-container .sidebar.compact+.main-content .breadcrumbs-fixed {
    width: 859px;
}
.container.main-container .breadcrumbs-fixed {
    left: auto;
    right: auto;
    width: 964px;
}
.container.main-container .sidebar+.main-content .breadcrumbs-fixed {
    left: auto;
    right: auto;
    width: 774px;
}
.container.main-container .sidebar.menu-min+.main-content .breadcrumbs-fixed {
    left: auto;
    right: auto;
    width: 921px;
}
}@media (min-width:1200px) {
    .container.main-container .sidebar.compact+.main-content .breadcrumbs-fixed {
    width: 1059px;
}
.container.main-container .breadcrumbs-fixed {
    width: 1164px;
}
.container.main-container .sidebar+.main-content .breadcrumbs-fixed {
    width: 974px;
}
.container.main-container .sidebar.menu-min+.main-content .breadcrumbs-fixed {
    width: 1121px;
}
}@media (min-width:992px) and (max-width:991px) {
    .container.main-container .breadcrumbs-fixed, .container.main-container .sidebar.compact+.main-content .breadcrumbs-fixed, .container.main-container .sidebar.menu-min+.main-content .breadcrumbs-fixed {
    width: 744px;
}
.container.main-container .sidebar.responsive-max+.main-content .breadcrumbs-fixed, .container.main-container .sidebar.responsive-min+.main-content .breadcrumbs-fixed {
    left: auto;
    right: auto;
    width: 701px;
}
}@media (min-width:992px) {
    .h-sidebar+.main-content .breadcrumbs.breadcrumbs-fixed {
    position: relative;
    top: auto;
    left: auto;
    z-index: auto;
    width: auto;
}
.h-sidebar+.main-content .page-content {
    padding-top: 12px;
}
}.nav-search {
    position: absolute;
    right: 22px;
    line-height: 24px;
}
.breadcrumbs .nav-search {
    top: 6px;
}
.nav-search .form-search {
    margin-bottom: 0;
}
.nav-search .nav-search-input {
    border: 1px solid #6FB3E0;
    width: 152px;
    height: 28px!important;
    padding-top: 2px;
    padding-bottom: 2px;
    /* border-radius: 4px!important; */
    font-size: 14px;
    line-height: 1.3;
    color: #666!important;
    z-index: 11;
    -webkit-transition: width ease .15s;
    -o-transition: width ease .15s;
    transition: width ease .15s;
}
.nav-search .nav-search-input+.dropdown-menu {
    min-width: 0;
    left: 0;
    right: 0;
}
.nav-search .nav-search-input:focus, .nav-search .nav-search-input:hover {
    border-color: #6FB3E0;
}
.nav-search .nav-search-icon {
    color: #6FB3E0!important;
    font-size: 14px!important;
    line-height: 24px!important;
    background-color: transparent;
}
.nav-search.minimized .nav-search-input {
    width: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    max-width: 0;
}
.nav-search.minimized .nav-search-btn:active+.nav-search-input, .nav-search.minimized .nav-search-input:active, .nav-search.minimized .nav-search-input:focus, .nav-search.minimized .nav-search-input:hover, .nav-search.minimized:hover .nav-search-input {
    opacity: 1;
    filter: alpha(opacity=100);
    width: 152px;
    max-width: 152px;
}
.nav-search.minimized .nav-search-icon {
    border: 1px solid;
    border-radius: 100%;
    background-color: #FFF;
    padding: 0 5px!important;
}
.nav-search-icon, .nav-search.minimized .nav-search-input:active~.nav-search-icon, .nav-search.minimized .nav-search-input:focus~.nav-search-icon, .nav-search.minimized .nav-search-input:hover~.nav-search-icon, .nav-search.minimized:hover .nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px!important;
}
.sidebar>.nav-search {
    position: static;
    background-color: #FAFAFA;
    border-bottom: 1px solid #DDD;
    text-align: center;
    height: 35px;
    padding-top: 6px;
}
.sidebar>.nav-search .nav-search-input {
    width: 162px!important;
    border-radius: 0!important;
    max-width: 162px!important;
    opacity: 1!important;
    filter: alpha(opacity=100)!important;
}
.sidebar>.nav-search .nav-search-input+.dropdown-menu {
    text-align: left;
}
.sidebar.menu-min .nav-search .form-search {
    position: absolute;
    left: 5px;
    z-index: 14;
}
.sidebar.menu-min .nav-search .nav-search-input {
    width: 0!important;
    max-width: 0!important;
    opacity: 0!important;
    filter: alpha(opacity=0)!important;
}
.sidebar.menu-min .nav-search .nav-search-input:active, .sidebar.menu-min .nav-search .nav-search-input:focus, .sidebar.menu-min .nav-search .nav-search-input:hover, .sidebar.menu-min .nav-search:hover .nav-search-input {
    width: 162px!important;
    max-width: 162px!important;
    opacity: 1!important;
    filter: alpha(opacity=100)!important;
}
.sidebar.menu-min .nav-search .nav-search-input:active~#nav-search-icon, .sidebar.menu-min .nav-search .nav-search-input:focus~#nav-search-icon, .sidebar.menu-min .nav-search .nav-search-input:hover~#nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px!important;
}
.sidebar.menu-min .nav-search:hover .nav-search-input~.nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px!important;
}
.sidebar.menu-min .nav-search .nav-search-icon {
    border: 1px solid;
    border-radius: 32px;
    background-color: #FFF;
    padding: 0 5px!important;
}
@media (max-width:991px) {
    .sidebar.responsive-min .nav-search .form-search {
    position: absolute;
    left: 5px;
    z-index: 14;
}
.sidebar.responsive-min .nav-search .nav-search-input {
    width: 0!important;
    max-width: 0!important;
    opacity: 0!important;
    filter: alpha(opacity=0)!important;
}
.sidebar.responsive-min .nav-search .nav-search-input:active, .sidebar.responsive-min .nav-search .nav-search-input:focus, .sidebar.responsive-min .nav-search .nav-search-input:hover, .sidebar.responsive-min .nav-search:hover .nav-search-input {
    width: 162px!important;
    max-width: 162px!important;
    opacity: 1!important;
    filter: alpha(opacity=100)!important;
}
.sidebar.responsive-min .nav-search .nav-search-input:active~#nav-search-icon, .sidebar.responsive-min .nav-search .nav-search-input:focus~#nav-search-icon, .sidebar.responsive-min .nav-search .nav-search-input:hover~#nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px!important;
}
.sidebar.responsive-min .nav-search:hover .nav-search-input~.nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px!important;
}
.sidebar.responsive-min .nav-search .nav-search-icon {
    border: 1px solid;
    border-radius: 32px;
    background-color: #FFF;
    padding: 0 5px!important;
}
}@media only screen and (max-width:991px) {
    .nav-search {
    right: 10px;
}
.nav-search .nav-search-input {
    width: 105px;
}
.nav-search .nav-search-btn:active+.nav-search-input, .nav-search .nav-search-input:active, .nav-search .nav-search-input:focus, .nav-search .nav-search-input:hover, .nav-search:hover .nav-search-input {
    width: 145px;
}
}@media only screen and (max-width:767px) {
    .nav-search .nav-search-input {
    width: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    max-width: 0;
}
.nav-search .nav-search-btn:active+.nav-search-input, .nav-search .nav-search-input:active, .nav-search .nav-search-input:focus, .nav-search .nav-search-input:hover, .nav-search:hover .nav-search-input {
    opacity: 1;
    filter: alpha(opacity=100);
    width: 152px;
    max-width: 152px;
}
.nav-search .nav-search-icon {
    border: 1px solid;
    border-radius: 100%;
    background-color: #FFF;
    padding: 0 5px!important;
}
.nav-search .nav-search-input:active~.nav-search-icon, .nav-search .nav-search-input:focus~.nav-search-icon, .nav-search .nav-search-input:hover~.nav-search-icon, .nav-search:hover .nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px!important;
}
}.footer {
    padding-top: 15px;
    height: 0;
    width: 0;
}
.footer .footer-inner {
    /*text-align: center;*/
    position: absolute;
    z-index: auto;
    left: 0;
    right: 0;
    bottom: 0;
}
.sidebar~.footer .footer-inner {
    left: 190px;
}
@media (min-width:992px) {
    .sidebar.compact~.footer .footer-inner {
    left: 105px;
}
}.sidebar.menu-min~.footer .footer-inner {
    left: 43px;
}
@media (min-width:992px) {
    .sidebar.h-sidebar~.footer .footer-inner {
    left: 0;
}
}@media (max-width:991px) {
    .footer .footer-inner, .sidebar.compact~.footer .footer-inner, .sidebar.menu-min~.footer .footer-inner, .sidebar~.footer .footer-inner {
    left: 0;
}
.sidebar.responsive-min~.footer .footer-inner {
    left: 43px;
}
}@media (min-width:768px) and (max-width:991px) {
    .container.main-container .footer .footer-inner {
    left: auto!important;
    right: auto!important;
    margin-left: auto;
    width: 744px;
}
}@media (min-width:992px) {
    .container.main-container .footer .footer-inner {
    left: auto!important;
    right: auto!important;
    width: 964px;
}
}@media (min-width:1200px) {
    .container.main-container .footer .footer-inner {
    width: 1164px;
}
}@media (min-width:768px) and (max-width:991px) {
    .container.main-container .sidebar~.footer .footer-inner {
    left: auto!important;
    right: auto!important;
    margin-left: auto;
    width: 744px;
}
}@media (min-width:992px) {
    .container.main-container .sidebar~.footer .footer-inner {
    left: auto!important;
    right: auto!important;
    margin-left: 190px;
    width: 774px;
}
}@media (min-width:1200px) {
    .container.main-container .sidebar~.footer .footer-inner {
    margin-left: 190px;
    width: 974px;
}
}@media (min-width:992px) {
    .container.main-container .sidebar.compact~.footer .footer-inner {
    margin-left: 105px;
    width: 859px;
}
}@media (min-width:1200px) {
    .container.main-container .sidebar.compact~.footer .footer-inner {
    margin-left: 105px;
    width: 1059px;
}
}@media (min-width:992px) {
    .container.main-container .sidebar.menu-min~.footer .footer-inner {
    margin-left: 43px;
    width: 921px;
}
}@media (min-width:1200px) {
    .container.main-container .sidebar.menu-min~.footer .footer-inner {
    margin-left: 43px;
    width: 1121px;
}
}@media (min-width:992px) {
    .container.main-container .sidebar.h-sidebar~.footer .footer-inner {
    margin-left: 0;
    width: 964px;
}
}@media (min-width:1200px) {
    .container.main-container .sidebar.h-sidebar~.footer .footer-inner {
    margin-left: 0;
    width: 1164px;
}
}@media (min-width:768px) and (max-width:991px) {
    .container.main-container .sidebar.responsive-min~.footer .footer-inner {
    margin-left: 43px;
    width: 701px;
}
}.footer .footer-inner .footer-content {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 8px;
    line-height: 24px;
    background-color: #165788;
    border-top: 2px solid #E5E5E5;    
}
.footer.footer-fixed .footer-inner {
    position: fixed;
    z-index: 999;
    bottom: 0;
}
.footer.footer-fixed .footer-inner .footer-content {
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 1px solid #DDD;
    background-color: #165788;
}
.footer.footer-fixed+.btn-scroll-up {
    z-index: 999;
}
.btn {
    color: #FFF!important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
    background-image: none!important;
    border: 5px solid #FFF;
    border-radius: 0;
    box-shadow: none!important;
    -webkit-transition: background-color .15s, border-color .15s, opacity .15s;
    -o-transition: background-color .15s, border-color .15s, opacity .15s;
    transition: background-color .15s, border-color .15s, opacity .15s;
    vertical-align: middle;
    margin: 0;
    position: relative;
}
.btn-group .dropdown-toggle, .input-group-lg>.form-control, .input-group-lg>.input-group-addon, .input-group-lg>.input-group-btn>.btn {
    border-radius: 0;
}
.btn.active, .open>.btn.active.dropdown-toggle {
    box-shadow: none;
}
.btn-group-lg>.btn, .btn-lg {
    border-width: 5px;
    line-height: 1.4;
    padding: 5px 16px 6px;
}
.btn-group-xlg>.btn, .btn-xlg {
    border-width: 5px;
    line-height: 1.35;
    padding: 7px 16px;
    font-size: 18px;
}
.btn-group-sm>.btn, .btn-sm {
    border-width: 4px;
    font-size: 14px;
    padding: 4px 9px;
    line-height: 1.38;
}
.btn-group-xs>.btn, .btn-xs {
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 3px;
}
.btn-group-mini>.btn, .btn-mini {
    padding: 1px 5px;
    border-width: 3px;
    font-size: 12px;
    line-height: 1.5;
}
.btn-group-minier>.btn, .btn-minier {
    padding: 0 4px;
    line-height: 18px;
    border-width: 2px;
    font-size: 12px;
}
button.btn:active {
    top: 1px;
}
.btn, .btn-default, .btn-default.focus, .btn-default:focus, .btn.focus, .btn:focus {
    background-color: #ABBAC3!important;
    border-color: #ABBAC3;
}
.btn-default.focus:hover, .btn-default:active:hover, .btn-default:focus:active, .btn-default:focus:hover, .btn-default:hover, .btn.focus:hover, .btn:active:hover, .btn:focus:active, .btn:focus:hover, .btn:hover, .open>.btn-default.dropdown-toggle, .open>.btn-default.dropdown-toggle.focus, .open>.btn-default.dropdown-toggle:active, .open>.btn-default.dropdown-toggle:focus, .open>.btn-default.dropdown-toggle:hover, .open>.btn.dropdown-toggle, .open>.btn.dropdown-toggle.focus, .open>.btn.dropdown-toggle:active, .open>.btn.dropdown-toggle:focus, .open>.btn.dropdown-toggle:hover {
    background-color: #8B9AA3!important;
    border-color: #ABBAC3;
}
.btn-default.no-border:active, .btn-default.no-border:hover, .btn.no-border:active, .btn.no-border:hover {
    border-color: #8B9AA3;
}
.btn-default.no-hover:active, .btn-default.no-hover:hover, .btn.no-hover:active, .btn.no-hover:hover {
    background-color: #ABBAC3!important;
}
.btn-default.active, .btn-default.active:focus, .btn-default.active:hover, .btn-default.focus.active, .btn.active, .btn.active:focus, .btn.active:hover, .btn.focus.active, .open>.btn-default.dropdown-toggle.active, .open>.btn.dropdown-toggle.active {
    background-color: #9baab3!important;
    border-color: #8799a4;
}
.btn-default.no-border.active, .btn.no-border.active {
    background-color: #92a3ac!important;
    border-color: #92a3ac;
}
.btn-default.disabled, .btn-default.disabled.active, .btn-default.disabled:active, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled], .btn-default[disabled].active, .btn-default[disabled]:active, .btn-default[disabled]:focus, .btn-default[disabled]:hover, .btn.disabled, .btn.disabled.active, .btn.disabled:active, .btn.disabled:focus, .btn.disabled:hover, .btn[disabled], .btn[disabled].active, .btn[disabled]:active, .btn[disabled]:focus, .btn[disabled]:hover, fieldset[disabled] .btn, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default.active, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn.active, fieldset[disabled] .btn:active, fieldset[disabled] .btn:focus, fieldset[disabled] .btn:hover {
    background-color: #ABBAC3!important;
    border-color: #ABBAC3;
}
.btn-primary, .btn-primary.focus, .btn-primary:focus {
    background-color: #428BCA!important;
    border-color: #428BCA;
    border-radius: 6px;
}
.btn-primary.focus:hover, .btn-primary:active:hover, .btn-primary:focus:active, .btn-primary:focus:hover, .btn-primary:hover, .open>.btn-primary.dropdown-toggle, .open>.btn-primary.dropdown-toggle.focus, .open>.btn-primary.dropdown-toggle:active, .open>.btn-primary.dropdown-toggle:focus, .open>.btn-primary.dropdown-toggle:hover {
    background-color: #1B6AAA!important;
    border-color: #428BCA;
}
.btn-primary.no-border:active, .btn-primary.no-border:hover {
    border-color: #1B6AAA;
}
.btn-primary.no-hover:active, .btn-primary.no-hover:hover {
    background-color: #428BCA!important;
}
.btn-primary.active, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.focus.active, .open>.btn-primary.dropdown-toggle.active {
    background-color: #2f7bba!important;
    border-color: #27689d;
}
.btn-primary.no-border.active {
    background-color: #2b72ae!important;
    border-color: #2b72ae;
}
.btn-primary.disabled, .btn-primary.disabled.active, .btn-primary.disabled:active, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled], .btn-primary[disabled].active, .btn-primary[disabled]:active, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary.active, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
    background-color: #428BCA!important;
    border-color: #428BCA;
}
.btn-info, .btn-info.focus, .btn-info:focus {
    background-color: #6FB3E0!important;
    border-color: #6FB3E0;
}
.btn-info.focus:hover, .btn-info:active:hover, .btn-info:focus:active, .btn-info:focus:hover, .btn-info:hover, .open>.btn-info.dropdown-toggle, .open>.btn-info.dropdown-toggle.focus, .open>.btn-info.dropdown-toggle:active, .open>.btn-info.dropdown-toggle:focus, .open>.btn-info.dropdown-toggle:hover {
    background-color: #4F99C6!important;
    border-color: #6FB3E0;
}
.btn-info.no-border:active, .btn-info.no-border:hover {
    border-color: #4F99C6;
}
.btn-info.no-hover:active, .btn-info.no-hover:hover {
    background-color: #6FB3E0!important;
}
.btn-info.active, .btn-info.active:focus, .btn-info.active:hover, .btn-info.focus.active, .open>.btn-info.dropdown-toggle.active {
    background-color: #5fa6d3!important;
    border-color: #4396cb;
}
.btn-info.no-border.active {
    background-color: #539fd0!important;
    border-color: #539fd0;
}
.btn-info.disabled, .btn-info.disabled.active, .btn-info.disabled:active, .btn-info.disabled:focus, .btn-info.disabled:hover, .btn-info[disabled], .btn-info[disabled].active, .btn-info[disabled]:active, .btn-info[disabled]:focus, .btn-info[disabled]:hover, fieldset[disabled] .btn-info, fieldset[disabled] .btn-info.active, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:hover {
    background-color: #6FB3E0!important;
    border-color: #6FB3E0;
}
.btn-info2, .btn-info2.focus, .btn-info2:focus {
    background-color: #95C6E5!important;
    border-color: #95C6E5;
}
.btn-info2.focus:hover, .btn-info2:active:hover, .btn-info2:focus:active, .btn-info2:focus:hover, .btn-info2:hover, .open>.btn-info2.dropdown-toggle, .open>.btn-info2.dropdown-toggle.focus, .open>.btn-info2.dropdown-toggle:active, .open>.btn-info2.dropdown-toggle:focus, .open>.btn-info2.dropdown-toggle:hover {
    background-color: #67A6CE!important;
    border-color: #95C6E5;
}
.btn-info2.no-border:active, .btn-info2.no-border:hover {
    border-color: #67A6CE;
}
.btn-info2.no-hover:active, .btn-info2.no-hover:hover {
    background-color: #95C6E5!important;
}
.btn-info2.active, .btn-info2.active:focus, .btn-info2.active:hover, .btn-info2.focus.active, .open>.btn-info2.dropdown-toggle.active {
    background-color: #7eb6da!important;
    border-color: #62a6d1;
}
.btn-info2.no-border.active {
    background-color: #72afd6!important;
    border-color: #72afd6;
}
.btn-info2.disabled, .btn-info2.disabled.active, .btn-info2.disabled:active, .btn-info2.disabled:focus, .btn-info2.disabled:hover, .btn-info2[disabled], .btn-info2[disabled].active, .btn-info2[disabled]:active, .btn-info2[disabled]:focus, .btn-info2[disabled]:hover, fieldset[disabled] .btn-info2, fieldset[disabled] .btn-info2.active, fieldset[disabled] .btn-info2:active, fieldset[disabled] .btn-info2:focus, fieldset[disabled] .btn-info2:hover {
    background-color: #95C6E5!important;
    border-color: #95C6E5;
}
.btn-success, .btn-success.focus, .btn-success:focus {
    background-color: #87B87F!important;
    border-color: #87B87F;
}
.btn-success.focus:hover, .btn-success:active:hover, .btn-success:focus:active, .btn-success:focus:hover, .btn-success:hover, .open>.btn-success.dropdown-toggle, .open>.btn-success.dropdown-toggle.focus, .open>.btn-success.dropdown-toggle:active, .open>.btn-success.dropdown-toggle:focus, .open>.btn-success.dropdown-toggle:hover {
    background-color: #629B58!important;
    border-color: #87B87F;
}
.btn-success.no-border:active, .btn-success.no-border:hover {
    border-color: #629B58;
}
.btn-success.no-hover:active, .btn-success.no-hover:hover {
    background-color: #87B87F!important;
}
.btn-success.active, .btn-success.active:focus, .btn-success.active:hover, .btn-success.focus.active, .open>.btn-success.dropdown-toggle.active {
    background-color: #75aa6c!important;
    border-color: #629959;
}
.btn-success.no-border.active {
    background-color: #6ba462!important;
    border-color: #6ba462;
}
.btn-success.disabled, .btn-success.disabled.active, .btn-success.disabled:active, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success[disabled], .btn-success[disabled].active, .btn-success[disabled]:active, .btn-success[disabled]:focus, .btn-success[disabled]:hover, fieldset[disabled] .btn-success, fieldset[disabled] .btn-success.active, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:hover {
    background-color: #87B87F!important;
    border-color: #87B87F;
}
.btn-warning, .btn-warning.focus, .btn-warning:focus {
    background-color: #FFB752!important;
    border-color: #FFB752;
}
.btn-warning.focus:hover, .btn-warning:active:hover, .btn-warning:focus:active, .btn-warning:focus:hover, .btn-warning:hover, .open>.btn-warning.dropdown-toggle, .open>.btn-warning.dropdown-toggle.focus, .open>.btn-warning.dropdown-toggle:active, .open>.btn-warning.dropdown-toggle:focus, .open>.btn-warning.dropdown-toggle:hover {
    background-color: #E59729!important;
    border-color: #FFB752;
}
.btn-warning.no-border:active, .btn-warning.no-border:hover {
    border-color: #E59729;
}
.btn-warning.no-hover:active, .btn-warning.no-hover:hover {
    background-color: #FFB752!important;
}
.btn-warning.active, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning.focus.active, .open>.btn-warning.dropdown-toggle.active {
    background-color: #f2a73e!important;
    border-color: #f0981c;
}
.btn-warning.no-border.active {
    background-color: #f1a02f!important;
    border-color: #f1a02f;
}
.btn-warning.disabled, .btn-warning.disabled.active, .btn-warning.disabled:active, .btn-warning.disabled:focus, .btn-warning.disabled:hover, .btn-warning[disabled], .btn-warning[disabled].active, .btn-warning[disabled]:active, .btn-warning[disabled]:focus, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning.active, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:hover {
    background-color: #FFB752!important;
    border-color: #FFB752;
}
.btn-danger, .btn-danger.focus, .btn-danger:focus {
    background-color: #D15B47!important;
    border-color: #D15B47;
}
.btn-danger.focus:hover, .btn-danger:active:hover, .btn-danger:focus:active, .btn-danger:focus:hover, .btn-danger:hover, .open>.btn-danger.dropdown-toggle, .open>.btn-danger.dropdown-toggle.focus, .open>.btn-danger.dropdown-toggle:active, .open>.btn-danger.dropdown-toggle:focus, .open>.btn-danger.dropdown-toggle:hover {
    background-color: #B74635!important;
    border-color: #D15B47;
}
.btn-danger.no-border:active, .btn-danger.no-border:hover {
    border-color: #B74635;
}
.btn-danger.no-hover:active, .btn-danger.no-hover:hover {
    background-color: #D15B47!important;
}
.btn-danger.active, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger.focus.active, .open>.btn-danger.dropdown-toggle.active {
    background-color: #c4513e!important;
    border-color: #aa4434;
}
.btn-danger.no-border.active {
    background-color: #ba4b39!important;
    border-color: #ba4b39;
}
.btn-danger.disabled, .btn-danger.disabled.active, .btn-danger.disabled:active, .btn-danger.disabled:focus, .btn-danger.disabled:hover, .btn-danger[disabled], .btn-danger[disabled].active, .btn-danger[disabled]:active, .btn-danger[disabled]:focus, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger.active, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:hover {
    background-color: #D15B47!important;
    border-color: #D15B47;
}
.btn-inverse, .btn-inverse.focus, .btn-inverse:focus {
    background-color: #555!important;
    border-color: #555;
}
.btn-inverse.focus:hover, .btn-inverse:active:hover, .btn-inverse:focus:active, .btn-inverse:focus:hover, .btn-inverse:hover, .open>.btn-inverse.dropdown-toggle, .open>.btn-inverse.dropdown-toggle.focus, .open>.btn-inverse.dropdown-toggle:active, .open>.btn-inverse.dropdown-toggle:focus, .open>.btn-inverse.dropdown-toggle:hover {
    background-color: #303030!important;
    border-color: #555;
}
.btn-inverse.no-border:active, .btn-inverse.no-border:hover {
    border-color: #303030;
}
.btn-inverse.no-hover:active, .btn-inverse.no-hover:hover {
    background-color: #555!important;
}
.btn-inverse.active, .btn-inverse.active:focus, .btn-inverse.active:hover, .btn-inverse.focus.active, .open>.btn-inverse.dropdown-toggle.active {
    background-color: #434343!important;
    border-color: #313131;
}
.btn-inverse.no-border.active {
    background-color: #3b3b3b!important;
    border-color: #3b3b3b;
}
.btn-inverse.disabled, .btn-inverse.disabled.active, .btn-inverse.disabled:active, .btn-inverse.disabled:focus, .btn-inverse.disabled:hover, .btn-inverse[disabled], .btn-inverse[disabled].active, .btn-inverse[disabled]:active, .btn-inverse[disabled]:focus, .btn-inverse[disabled]:hover, fieldset[disabled] .btn-inverse, fieldset[disabled] .btn-inverse.active, fieldset[disabled] .btn-inverse:active, fieldset[disabled] .btn-inverse:focus, fieldset[disabled] .btn-inverse:hover {
    background-color: #555!important;
    border-color: #555;
}
.btn-pink, .btn-pink.focus, .btn-pink:focus {
    background-color: #D6487E!important;
    border-color: #D6487E;
}
.btn-pink.focus:hover, .btn-pink:active:hover, .btn-pink:focus:active, .btn-pink:focus:hover, .btn-pink:hover, .open>.btn-pink.dropdown-toggle, .open>.btn-pink.dropdown-toggle.focus, .open>.btn-pink.dropdown-toggle:active, .open>.btn-pink.dropdown-toggle:focus, .open>.btn-pink.dropdown-toggle:hover {
    background-color: #B73766!important;
    border-color: #D6487E;
}
.btn-pink.no-border:active, .btn-pink.no-border:hover {
    border-color: #B73766;
}
.btn-pink.no-hover:active, .btn-pink.no-hover:hover {
    background-color: #D6487E!important;
}
.btn-pink.active, .btn-pink.active:focus, .btn-pink.active:hover, .btn-pink.focus.active, .open>.btn-pink.dropdown-toggle.active {
    background-color: #c74072!important;
    border-color: #af3462;
}
.btn-pink.no-border.active {
    background-color: #be386a!important;
    border-color: #be386a;
}
.btn-pink.disabled, .btn-pink.disabled.active, .btn-pink.disabled:active, .btn-pink.disabled:focus, .btn-pink.disabled:hover, .btn-pink[disabled], .btn-pink[disabled].active, .btn-pink[disabled]:active, .btn-pink[disabled]:focus, .btn-pink[disabled]:hover, fieldset[disabled] .btn-pink, fieldset[disabled] .btn-pink.active, fieldset[disabled] .btn-pink:active, fieldset[disabled] .btn-pink:focus, fieldset[disabled] .btn-pink:hover {
    background-color: #D6487E!important;
    border-color: #D6487E;
}
.btn-purple, .btn-purple.focus, .btn-purple:focus {
    background-color: #9585BF!important;
    border-color: #9585BF;
}
.btn-purple.focus:hover, .btn-purple:active:hover, .btn-purple:focus:active, .btn-purple:focus:hover, .btn-purple:hover, .open>.btn-purple.dropdown-toggle, .open>.btn-purple.dropdown-toggle.focus, .open>.btn-purple.dropdown-toggle:active, .open>.btn-purple.dropdown-toggle:focus, .open>.btn-purple.dropdown-toggle:hover {
    background-color: #7461AA!important;
    border-color: #9585BF;
}
.btn-purple.no-border:active, .btn-purple.no-border:hover {
    border-color: #7461AA;
}
.btn-purple.no-hover:active, .btn-purple.no-hover:hover {
    background-color: #9585BF!important;
}
.btn-purple.active, .btn-purple.active:focus, .btn-purple.active:hover, .btn-purple.focus.active, .open>.btn-purple.dropdown-toggle.active {
    background-color: #8573b5!important;
    border-color: #705ca8;
}
.btn-purple.no-border.active {
    background-color: #7c69af!important;
    border-color: #7c69af;
}
.btn-purple.disabled, .btn-purple.disabled.active, .btn-purple.disabled:active, .btn-purple.disabled:focus, .btn-purple.disabled:hover, .btn-purple[disabled], .btn-purple[disabled].active, .btn-purple[disabled]:active, .btn-purple[disabled]:focus, .btn-purple[disabled]:hover, fieldset[disabled] .btn-purple, fieldset[disabled] .btn-purple.active, fieldset[disabled] .btn-purple:active, fieldset[disabled] .btn-purple:focus, fieldset[disabled] .btn-purple:hover {
    background-color: #9585BF!important;
    border-color: #9585BF;
}
.btn-grey, .btn-grey.focus, .btn-grey:focus {
    background-color: #A0A0A0!important;
    border-color: #A0A0A0;
}
.btn-grey.focus:hover, .btn-grey:active:hover, .btn-grey:focus:active, .btn-grey:focus:hover, .btn-grey:hover, .open>.btn-grey.dropdown-toggle, .open>.btn-grey.dropdown-toggle.focus, .open>.btn-grey.dropdown-toggle:active, .open>.btn-grey.dropdown-toggle:focus, .open>.btn-grey.dropdown-toggle:hover {
    background-color: #888!important;
    border-color: #A0A0A0;
}
.btn-grey.no-border:active, .btn-grey.no-border:hover {
    border-color: #888;
}
.btn-grey.no-hover:active, .btn-grey.no-hover:hover {
    background-color: #A0A0A0!important;
}
.btn-grey.active, .btn-grey.active:focus, .btn-grey.active:hover, .btn-grey.focus.active, .open>.btn-grey.dropdown-toggle.active {
    background-color: #949494!important;
    border-color: #828282;
}
.btn-grey.no-border.active {
    background-color: #8c8c8c!important;
    border-color: #8c8c8c;
}
.btn-grey.disabled, .btn-grey.disabled.active, .btn-grey.disabled:active, .btn-grey.disabled:focus, .btn-grey.disabled:hover, .btn-grey[disabled], .btn-grey[disabled].active, .btn-grey[disabled]:active, .btn-grey[disabled]:focus, .btn-grey[disabled]:hover, fieldset[disabled] .btn-grey, fieldset[disabled] .btn-grey.active, fieldset[disabled] .btn-grey:active, fieldset[disabled] .btn-grey:focus, fieldset[disabled] .btn-grey:hover {
    background-color: #A0A0A0!important;
    border-color: #A0A0A0;
}
.btn-yellow {
    color: #963!important;
    text-shadow: 0 -1px 0 rgba(255, 255, 255, .4)!important;
}
.btn-yellow, .btn-yellow.focus, .btn-yellow:focus {
    background-color: #FEE188!important;
    border-color: #FEE188;
}
.btn-yellow.focus:hover, .btn-yellow:active:hover, .btn-yellow:focus:active, .btn-yellow:focus:hover, .btn-yellow:hover, .open>.btn-yellow.dropdown-toggle, .open>.btn-yellow.dropdown-toggle.focus, .open>.btn-yellow.dropdown-toggle:active, .open>.btn-yellow.dropdown-toggle:focus, .open>.btn-yellow.dropdown-toggle:hover {
    background-color: #F7D05B!important;
    border-color: #FEE188;
}
.btn-yellow.no-border:active, .btn-yellow.no-border:hover {
    border-color: #F7D05B;
}
.btn-yellow.no-hover:active, .btn-yellow.no-hover:hover {
    background-color: #FEE188!important;
}
.btn-yellow.active, .btn-yellow.active:focus, .btn-yellow.active:hover, .btn-yellow.focus.active, .open>.btn-yellow.dropdown-toggle.active {
    background-color: #fbd972!important;
    border-color: #f9cf4f;
}
.btn-yellow.no-border.active {
    background-color: #fad463!important;
    border-color: #fad463;
}
.btn-yellow.disabled, .btn-yellow.disabled.active, .btn-yellow.disabled:active, .btn-yellow.disabled:focus, .btn-yellow.disabled:hover, .btn-yellow[disabled], .btn-yellow[disabled].active, .btn-yellow[disabled]:active, .btn-yellow[disabled]:focus, .btn-yellow[disabled]:hover, fieldset[disabled] .btn-yellow, fieldset[disabled] .btn-yellow.active, fieldset[disabled] .btn-yellow:active, fieldset[disabled] .btn-yellow:focus, fieldset[disabled] .btn-yellow:hover {
    background-color: #FEE188!important;
    border-color: #FEE188;
}
.btn-yellow.active, .btn-yellow.focus, .btn-yellow:focus, .btn-yellow:hover, .open>.btn-yellow.active.dropdown-toggle, .open>.btn-yellow.dropdown-toggle {
    color: #963;
}
.btn-light {
    color: #888!important;
    text-shadow: 0 -1px 0 rgba(250, 250, 250, .25)!important;
}
.btn.btn-link, .btn.btn-link:hover, .btn.btn-white {
    text-shadow: none!important;
}
.btn-light, .btn-light.focus, .btn-light:focus {
    background-color: #E7E7E7!important;
    border-color: #E7E7E7;
}
.btn-light.focus:hover, .btn-light:active:hover, .btn-light:focus:active, .btn-light:focus:hover, .btn-light:hover, .open>.btn-light.dropdown-toggle, .open>.btn-light.dropdown-toggle.focus, .open>.btn-light.dropdown-toggle:active, .open>.btn-light.dropdown-toggle:focus, .open>.btn-light.dropdown-toggle:hover {
    background-color: #D9D9D9!important;
    border-color: #E7E7E7;
}
.btn-light.no-border:active, .btn-light.no-border:hover {
    border-color: #D9D9D9;
}
.btn-light.no-hover:active, .btn-light.no-hover:hover {
    background-color: #E7E7E7!important;
}
.btn-light.active, .btn-light.active:focus, .btn-light.active:hover, .btn-light.focus.active, .open>.btn-light.dropdown-toggle.active {
    background-color: #e0e0e0!important;
    border-color: #cecece;
}
.btn-light.no-border.active {
    background-color: #d8d8d8!important;
    border-color: #d8d8d8;
}
.btn-light.disabled, .btn-light.disabled.active, .btn-light.disabled:active, .btn-light.disabled:focus, .btn-light.disabled:hover, .btn-light[disabled], .btn-light[disabled].active, .btn-light[disabled]:active, .btn-light[disabled]:focus, .btn-light[disabled]:hover, fieldset[disabled] .btn-light, fieldset[disabled] .btn-light.active, fieldset[disabled] .btn-light:active, fieldset[disabled] .btn-light:focus, fieldset[disabled] .btn-light:hover {
    background-color: #E7E7E7!important;
    border-color: #E7E7E7;
}
.btn.btn-white, .btn.btn-white.no-hover:active, .btn.btn-white.no-hover:hover {
    background-color: #FFF!important;
}
.btn.btn-white, .btn.btn-white.active.no-border, .btn.btn-white.disabled, .btn.btn-white.disabled.active, .btn.btn-white.disabled:active, .btn.btn-white.disabled:focus, .btn.btn-white.disabled:hover, .btn.btn-white:active.no-border, .btn.btn-white:focus.no-border, .btn.btn-white:hover.no-border, .btn.btn-white[disabled], .btn.btn-white[disabled].active, .btn.btn-white[disabled]:active, .btn.btn-white[disabled]:focus, .btn.btn-white[disabled]:hover, .open>.btn.btn-white.active.dropdown-toggle.no-border, .open>.btn.btn-white.dropdown-toggle.no-border, fieldset[disabled] .btn.btn-white, fieldset[disabled] .btn.btn-white.active, fieldset[disabled] .btn.btn-white:active, fieldset[disabled] .btn.btn-white:focus, fieldset[disabled] .btn.btn-white:hover {
    border-color: #CCC;
}
.btn-light.active, .btn-light.focus, .btn-light:focus, .btn-light:hover, .open>.btn-light.active.dropdown-toggle, .open>.btn-light.dropdown-toggle {
    color: #888;
}
.btn-light.btn-mini:after, .btn-light.btn-xs:after {
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
}
.btn-light.btn-sm:after {
    left: -4px;
    right: -4px;
    top: -4px;
    bottom: -4px;
}
.btn-light .btn-lg:after {
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
}
.btn.btn-white {
    color: #444!important;
}
.btn.btn-white.active, .btn.btn-white:focus {
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1)!important;
}
.btn.btn-white.active.btn-bold, .btn.btn-white:focus.btn-bold {
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, .15)!important;
}
.btn.btn-white.active:after {
    display: none;
}
.btn.btn-white.active, .btn.btn-white:active, .btn.btn-white:focus, .btn.btn-white:hover, .open>.btn.btn-white.active.dropdown-toggle, .open>.btn.btn-white.dropdown-toggle {
    background-color: #EBEBEB!important;
    border-color: #CCC;
    color: #3a3434!important;
}
.btn-white.btn-default {
    border-color: #ABBAC3;
    color: #80909a!important;
}
.btn-white.btn-default.active, .btn-white.btn-default:active, .btn-white.btn-default:focus, .btn-white.btn-default:hover, .open>.btn-white.btn-default.active.dropdown-toggle, .open>.btn-white.btn-default.dropdown-toggle {
    background-color: #eff2f4!important;
    border-color: #ABBAC3;
    color: #6b8595!important;
}
.btn-white.btn-default.active.no-border, .btn-white.btn-default.disabled, .btn-white.btn-default.disabled.active, .btn-white.btn-default.disabled:active, .btn-white.btn-default.disabled:focus, .btn-white.btn-default.disabled:hover, .btn-white.btn-default:active.no-border, .btn-white.btn-default:focus.no-border, .btn-white.btn-default:hover.no-border, .btn-white.btn-default[disabled], .btn-white.btn-default[disabled].active, .btn-white.btn-default[disabled]:active, .btn-white.btn-default[disabled]:focus, .btn-white.btn-default[disabled]:hover, .open>.btn-white.btn-default.active.dropdown-toggle.no-border, .open>.btn-white.btn-default.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-default, fieldset[disabled] .btn-white.btn-default.active, fieldset[disabled] .btn-white.btn-default:active, fieldset[disabled] .btn-white.btn-default:focus, fieldset[disabled] .btn-white.btn-default:hover {
    border-color: #ABBAC3;
}
.btn-white.btn-primary {
    border-color: #8aafce;
    color: #6688a6!important;
}
.btn-white.btn-primary.active, .btn-white.btn-primary:active, .btn-white.btn-primary:focus, .btn-white.btn-primary:hover, .open>.btn-white.btn-primary.active.dropdown-toggle, .open>.btn-white.btn-primary.dropdown-toggle {
    background-color: #eaf2f8!important;
    border-color: #8aafce;
    color: #537c9f!important;
}
.btn-white.btn-primary.active.no-border, .btn-white.btn-primary.disabled, .btn-white.btn-primary.disabled.active, .btn-white.btn-primary.disabled:active, .btn-white.btn-primary.disabled:focus, .btn-white.btn-primary.disabled:hover, .btn-white.btn-primary:active.no-border, .btn-white.btn-primary:focus.no-border, .btn-white.btn-primary:hover.no-border, .btn-white.btn-primary[disabled], .btn-white.btn-primary[disabled].active, .btn-white.btn-primary[disabled]:active, .btn-white.btn-primary[disabled]:focus, .btn-white.btn-primary[disabled]:hover, .open>.btn-white.btn-primary.active.dropdown-toggle.no-border, .open>.btn-white.btn-primary.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-primary, fieldset[disabled] .btn-white.btn-primary.active, fieldset[disabled] .btn-white.btn-primary:active, fieldset[disabled] .btn-white.btn-primary:focus, fieldset[disabled] .btn-white.btn-primary:hover {
    border-color: #8aafce;
}
.btn-white.btn-success {
    border-color: #a7c9a1;
    color: #81a87b!important;
}
.btn-white.btn-success.active, .btn-white.btn-success:active, .btn-white.btn-success:focus, .btn-white.btn-success:hover, .open>.btn-white.btn-success.active.dropdown-toggle, .open>.btn-white.btn-success.dropdown-toggle {
    background-color: #edf4eb!important;
    border-color: #a7c9a1;
    color: #6ea465!important;
}
.btn-white.btn-success.active.no-border, .btn-white.btn-success.disabled, .btn-white.btn-success.disabled.active, .btn-white.btn-success.disabled:active, .btn-white.btn-success.disabled:focus, .btn-white.btn-success.disabled:hover, .btn-white.btn-success:active.no-border, .btn-white.btn-success:focus.no-border, .btn-white.btn-success:hover.no-border, .btn-white.btn-success[disabled], .btn-white.btn-success[disabled].active, .btn-white.btn-success[disabled]:active, .btn-white.btn-success[disabled]:focus, .btn-white.btn-success[disabled]:hover, .open>.btn-white.btn-success.active.dropdown-toggle.no-border, .open>.btn-white.btn-success.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-success, fieldset[disabled] .btn-white.btn-success.active, fieldset[disabled] .btn-white.btn-success:active, fieldset[disabled] .btn-white.btn-success:focus, fieldset[disabled] .btn-white.btn-success:hover {
    border-color: #a7c9a1;
}
.btn-white.btn-danger {
    border-color: #d7a59d;
    color: #b7837a!important;
}
.btn-white.btn-danger.active, .btn-white.btn-danger:active, .btn-white.btn-danger:focus, .btn-white.btn-danger:hover, .open>.btn-white.btn-danger.active.dropdown-toggle, .open>.btn-white.btn-danger.dropdown-toggle {
    background-color: #fbf4f3!important;
    border-color: #d7a59d;
    color: #b46f64!important;
}
.btn-white.btn-danger.active.no-border, .btn-white.btn-danger.disabled, .btn-white.btn-danger.disabled.active, .btn-white.btn-danger.disabled:active, .btn-white.btn-danger.disabled:focus, .btn-white.btn-danger.disabled:hover, .btn-white.btn-danger:active.no-border, .btn-white.btn-danger:focus.no-border, .btn-white.btn-danger:hover.no-border, .btn-white.btn-danger[disabled], .btn-white.btn-danger[disabled].active, .btn-white.btn-danger[disabled]:active, .btn-white.btn-danger[disabled]:focus, .btn-white.btn-danger[disabled]:hover, .open>.btn-white.btn-danger.active.dropdown-toggle.no-border, .open>.btn-white.btn-danger.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-danger, fieldset[disabled] .btn-white.btn-danger.active, fieldset[disabled] .btn-white.btn-danger:active, fieldset[disabled] .btn-white.btn-danger:focus, fieldset[disabled] .btn-white.btn-danger:hover {
    border-color: #d7a59d;
}
.btn-white.btn-warning {
    border-color: #e7b979;
    color: #daa458!important;
}
.btn-white.btn-warning.active, .btn-white.btn-warning:active, .btn-white.btn-warning:focus, .btn-white.btn-warning:hover, .open>.btn-white.btn-warning.active.dropdown-toggle, .open>.btn-white.btn-warning.dropdown-toggle {
    background-color: #fef7ec!important;
    border-color: #e7b979;
    color: #db9a3d!important;
}
.btn-white.btn-warning.active.no-border, .btn-white.btn-warning.disabled, .btn-white.btn-warning.disabled.active, .btn-white.btn-warning.disabled:active, .btn-white.btn-warning.disabled:focus, .btn-white.btn-warning.disabled:hover, .btn-white.btn-warning:active.no-border, .btn-white.btn-warning:focus.no-border, .btn-white.btn-warning:hover.no-border, .btn-white.btn-warning[disabled], .btn-white.btn-warning[disabled].active, .btn-white.btn-warning[disabled]:active, .btn-white.btn-warning[disabled]:focus, .btn-white.btn-warning[disabled]:hover, .open>.btn-white.btn-warning.active.dropdown-toggle.no-border, .open>.btn-white.btn-warning.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-warning, fieldset[disabled] .btn-white.btn-warning.active, fieldset[disabled] .btn-white.btn-warning:active, fieldset[disabled] .btn-white.btn-warning:focus, fieldset[disabled] .btn-white.btn-warning:hover {
    border-color: #e7b979;
}
.btn-white.btn-info, .btn-white.btn-info.active.no-border, .btn-white.btn-info.disabled, .btn-white.btn-info.disabled.active, .btn-white.btn-info.disabled:active, .btn-white.btn-info.disabled:focus, .btn-white.btn-info.disabled:hover, .btn-white.btn-info:active.no-border, .btn-white.btn-info:focus.no-border, .btn-white.btn-info:hover.no-border, .btn-white.btn-info[disabled], .btn-white.btn-info[disabled].active, .btn-white.btn-info[disabled]:active, .btn-white.btn-info[disabled]:focus, .btn-white.btn-info[disabled]:hover, .open>.btn-white.btn-info.active.dropdown-toggle.no-border, .open>.btn-white.btn-info.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-info, fieldset[disabled] .btn-white.btn-info.active, fieldset[disabled] .btn-white.btn-info:active, fieldset[disabled] .btn-white.btn-info:focus, fieldset[disabled] .btn-white.btn-info:hover {
    border-color: #8fbcd9;
}
.btn-white.btn-info {
    color: #70a0c1!important;
}
.btn-white.btn-info.active, .btn-white.btn-info:active, .btn-white.btn-info:focus, .btn-white.btn-info:hover, .open>.btn-white.btn-info.active.dropdown-toggle, .open>.btn-white.btn-info.dropdown-toggle {
    background-color: #eef5fa!important;
    border-color: #8fbcd9;
    color: #5896bf!important;
}
.btn-white.btn-inverse {
    border-color: #959595;
    color: #555!important;
}
.btn-white.btn-inverse.active, .btn-white.btn-inverse:active, .btn-white.btn-inverse:focus, .btn-white.btn-inverse:hover, .open>.btn-white.btn-inverse.active.dropdown-toggle, .open>.btn-white.btn-inverse.dropdown-toggle {
    background-color: #e4e4e4!important;
    border-color: #959595;
    color: #4c4545!important;
}
.btn-white.btn-inverse.active.no-border, .btn-white.btn-inverse.disabled, .btn-white.btn-inverse.disabled.active, .btn-white.btn-inverse.disabled:active, .btn-white.btn-inverse.disabled:focus, .btn-white.btn-inverse.disabled:hover, .btn-white.btn-inverse:active.no-border, .btn-white.btn-inverse:focus.no-border, .btn-white.btn-inverse:hover.no-border, .btn-white.btn-inverse[disabled], .btn-white.btn-inverse[disabled].active, .btn-white.btn-inverse[disabled]:active, .btn-white.btn-inverse[disabled]:focus, .btn-white.btn-inverse[disabled]:hover, .open>.btn-white.btn-inverse.active.dropdown-toggle.no-border, .open>.btn-white.btn-inverse.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-inverse, fieldset[disabled] .btn-white.btn-inverse.active, fieldset[disabled] .btn-white.btn-inverse:active, fieldset[disabled] .btn-white.btn-inverse:focus, fieldset[disabled] .btn-white.btn-inverse:hover {
    border-color: #959595;
}
.btn-white.btn-pink, .btn-white.btn-pink.active.no-border, .btn-white.btn-pink.disabled, .btn-white.btn-pink.disabled.active, .btn-white.btn-pink.disabled:active, .btn-white.btn-pink.disabled:focus, .btn-white.btn-pink.disabled:hover, .btn-white.btn-pink:active.no-border, .btn-white.btn-pink:focus.no-border, .btn-white.btn-pink:hover.no-border, .btn-white.btn-pink[disabled], .btn-white.btn-pink[disabled].active, .btn-white.btn-pink[disabled]:active, .btn-white.btn-pink[disabled]:focus, .btn-white.btn-pink[disabled]:hover, .open>.btn-white.btn-pink.active.dropdown-toggle.no-border, .open>.btn-white.btn-pink.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-pink, fieldset[disabled] .btn-white.btn-pink.active, fieldset[disabled] .btn-white.btn-pink:active, fieldset[disabled] .btn-white.btn-pink:focus, fieldset[disabled] .btn-white.btn-pink:hover {
    border-color: #d299ae;
}
.btn-white.btn-pink {
    color: #af6f87!important;
}
.btn-white.btn-pink.active, .btn-white.btn-pink:active, .btn-white.btn-pink:focus, .btn-white.btn-pink:hover, .open>.btn-white.btn-pink.active.dropdown-toggle, .open>.btn-white.btn-pink.dropdown-toggle {
    background-color: #fbeff4!important;
    border-color: #d299ae;
    color: #ac5978!important;
}
.btn-white.btn-purple {
    border-color: #b7b1c6;
    color: #7d6fa2!important;
}
.btn-white.btn-purple.active, .btn-white.btn-purple:active, .btn-white.btn-purple:focus, .btn-white.btn-purple:hover, .open>.btn-white.btn-purple.active.dropdown-toggle, .open>.btn-white.btn-purple.dropdown-toggle {
    background-color: #efedf5!important;
    border-color: #b7b1c6;
    color: #6d5b9c!important;
}
.btn-white.btn-purple.active.no-border, .btn-white.btn-purple.disabled, .btn-white.btn-purple.disabled.active, .btn-white.btn-purple.disabled:active, .btn-white.btn-purple.disabled:focus, .btn-white.btn-purple.disabled:hover, .btn-white.btn-purple:active.no-border, .btn-white.btn-purple:focus.no-border, .btn-white.btn-purple:hover.no-border, .btn-white.btn-purple[disabled], .btn-white.btn-purple[disabled].active, .btn-white.btn-purple[disabled]:active, .btn-white.btn-purple[disabled]:focus, .btn-white.btn-purple[disabled]:hover, .open>.btn-white.btn-purple.active.dropdown-toggle.no-border, .open>.btn-white.btn-purple.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-purple, fieldset[disabled] .btn-white.btn-purple.active, fieldset[disabled] .btn-white.btn-purple:active, fieldset[disabled] .btn-white.btn-purple:focus, fieldset[disabled] .btn-white.btn-purple:hover {
    border-color: #b7b1c6;
}
.btn-white.btn-yellow {
    border-color: #ecd181;
    color: #d3a61a!important;
}
.btn-white.btn-yellow.active, .btn-white.btn-yellow:active, .btn-white.btn-yellow:focus, .btn-white.btn-yellow:hover, .open>.btn-white.btn-yellow.active.dropdown-toggle, .open>.btn-white.btn-yellow.dropdown-toggle {
    background-color: #fdf7e4!important;
    border-color: #ecd181;
    color: #c29712!important;
}
.btn-white.btn-yellow.active.no-border, .btn-white.btn-yellow.disabled, .btn-white.btn-yellow.disabled.active, .btn-white.btn-yellow.disabled:active, .btn-white.btn-yellow.disabled:focus, .btn-white.btn-yellow.disabled:hover, .btn-white.btn-yellow:active.no-border, .btn-white.btn-yellow:focus.no-border, .btn-white.btn-yellow:hover.no-border, .btn-white.btn-yellow[disabled], .btn-white.btn-yellow[disabled].active, .btn-white.btn-yellow[disabled]:active, .btn-white.btn-yellow[disabled]:focus, .btn-white.btn-yellow[disabled]:hover, .open>.btn-white.btn-yellow.active.dropdown-toggle.no-border, .open>.btn-white.btn-yellow.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-yellow, fieldset[disabled] .btn-white.btn-yellow.active, fieldset[disabled] .btn-white.btn-yellow:active, fieldset[disabled] .btn-white.btn-yellow:focus, fieldset[disabled] .btn-white.btn-yellow:hover {
    border-color: #ecd181;
}
.btn-white.btn-grey, .btn-white.btn-grey.active.no-border, .btn-white.btn-grey.disabled, .btn-white.btn-grey.disabled.active, .btn-white.btn-grey.disabled:active, .btn-white.btn-grey.disabled:focus, .btn-white.btn-grey.disabled:hover, .btn-white.btn-grey:active.no-border, .btn-white.btn-grey:focus.no-border, .btn-white.btn-grey:hover.no-border, .btn-white.btn-grey[disabled], .btn-white.btn-grey[disabled].active, .btn-white.btn-grey[disabled]:active, .btn-white.btn-grey[disabled]:focus, .btn-white.btn-grey[disabled]:hover, .open>.btn-white.btn-grey.active.dropdown-toggle.no-border, .open>.btn-white.btn-grey.dropdown-toggle.no-border, fieldset[disabled] .btn-white.btn-grey, fieldset[disabled] .btn-white.btn-grey.active, fieldset[disabled] .btn-white.btn-grey:active, fieldset[disabled] .btn-white.btn-grey:focus, fieldset[disabled] .btn-white.btn-grey:hover {
    border-color: #c6c6c6;
}
.btn-white.btn-grey {
    color: #8c8c8c!important;
}
.btn-white.btn-grey.active, .btn-white.btn-grey:active, .btn-white.btn-grey:focus, .btn-white.btn-grey:hover, .open>.btn-white.btn-grey.active.dropdown-toggle, .open>.btn-white.btn-grey.dropdown-toggle {
    background-color: #ededed!important;
    border-color: #c6c6c6;
    color: #857979!important;
}
.btn-white.btn-transparent {
    background-color: rgba(0, 0, 0, .4)!important;
}
.btn-white.btn-transparent:hover {
    background-color: rgba(0, 0, 0, .5)!important;
}
.btn-white.btn-transparent:active, .btn-white.btn-transparent:focus {
    background-color: rgba(0, 0, 0, .6)!important;
}
.btn-white.no-border {
    border-color: transparent!important;
}
.btn.disabled.active, .btn.disabled:active, .btn.disabled:focus, .btn[disabled].active, .btn[disabled]:active, .btn[disabled]:focus {
    outline: 0;
}
.btn.disabled:active, .btn[disabled]:active {
    top: 0;
    left: 0;
}
.btn.active:after {
    display: inline-block;
    content: "";
    position: absolute;
    border-bottom: 1px solid #EFE5B5;
    left: -4px;
    right: -4px;
    bottom: -4px;
}
.btn.btn-link, .btn.btn-no-border {
    border-width: 0!important;
}
.btn.active.btn-sm:after {
    left: -3px;
    right: -3px;
    bottom: -3px;
}
.btn.active.btn-lg:after {
    left: -5px;
    right: -5px;
    bottom: -5px;
}
.btn.active.btn-mini:after, .btn.active.btn-minier:after, .btn.active.btn-xs:after {
    left: -1px;
    right: -1px;
    bottom: -2px;
}
.btn.active.btn-minier:after {
    bottom: -1px;
}
.btn.active.btn-yellow:after {
    border-bottom-color: #C96338;
}
.btn.active.btn-light {
    color: #515151;
}
.btn.active.btn-light:after {
    border-bottom-color: #B5B5B5;
}
.btn>.ace-icon {
    margin-right: 4px;
}
.btn>.ace-icon.icon-on-right {
    margin-right: 0;
    margin-left: 4px;
}
.btn>.icon-only.ace-icon {
    margin: 0!important;
    text-align: center;
    padding: 0;
}
.btn-lg>.ace-icon {
    margin-right: 6px;
}
.btn-lg>.ace-icon.icon-on-right {
    margin-right: 0;
    margin-left: 6px;
}
.btn-sm>.ace-icon {
    margin-right: 3px;
}
.btn-sm>.ace-icon.icon-on-right {
    margin-right: 0;
    margin-left: 3px;
}
.btn-mini>.ace-icon, .btn-minier>.ace-icon, .btn-xs>.ace-icon {
    margin-right: 2px;
}
.btn-mini>.ace-icon.icon-on-right, .btn-minier>.ace-icon.icon-on-right, .btn-xs>.ace-icon.icon-on-right {
    margin-right: 0;
    margin-left: 2px;
}
.btn.btn-link {
    background: 0 0!important;
    color: #08C!important;
    padding: 4px 12px!important;
    line-height: 20px!important;
}
.btn.btn-link:hover {
    background: 0 0!important;
}
.btn.btn-link.active, .btn.btn-link:active, .btn.btn-link:focus, .open>.btn.btn-link.active, .open>.btn.btn-link:active, .open>.btn.btn-link:focus {
    background: 0 0!important;
    text-decoration: underline;
    color: #009ceb!important;
}
.btn.btn-link.active:after, .btn.btn-link:active:after, .btn.btn-link:focus:after {
    display: none;
}
.btn.btn-link.disabled, .btn.btn-link[disabled] {
    background: 0 0!important;
    opacity: .65;
    filter: alpha(opacity=65);
    text-decoration: none!important;
}
.btn-group:first-child {
    margin-left: 0;
}
.btn-group>.btn, .btn-group>.btn+.btn, .btn-group>.btn:first-child {
    margin: 0 1px 0 0;
}
.btn-group>.btn>.caret {
    margin-top: 15px;
    margin-left: 1px;
    border-width: 5px;
    border-top-color: #FFF;
}
.btn-group>.btn.btn-sm>.caret {
    margin-top: 10px;
    border-width: 4px;
}
.btn-group>.btn.btn-lg>.caret {
    margin-top: 18px;
    border-width: 6px;
}
.btn-group>.btn.btn-mini>.caret, .btn-group>.btn.btn-xs>.caret {
    margin-top: 9px;
    border-width: 4px;
}
.btn-group>.btn.btn-minier>.caret {
    margin-top: 7px;
    border-width: 3px;
}
.btn-group>.btn+.btn.dropdown-toggle {
    padding-right: 3px;
    padding-left: 3px;
}
.btn-group>.btn+.btn-lg.dropdown-toggle {
    padding-right: 4px;
    padding-left: 4px;
}
.btn-group+.btn, .btn-group>.btn {
    margin: 0 1px 0 0;
    border-width: 3px;
}
.btn-group+.btn.active:after, .btn-group>.btn.active:after {
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-bottom-width: 1px;
}
.btn-group+.btn-lg, .btn-group>.btn-lg {
    border-width: 4px;
}
.btn-group+.btn-lg.active:after, .btn-group>.btn-lg.active:after {
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-bottom-width: 1px;
}
.btn-group+.btn-sm, .btn-group>.btn-sm {
    border-width: 2px;
}
.btn-group+.btn-mini, .btn-group+.btn-minier, .btn-group+.btn-xs, .btn-group>.btn-mini, .btn-group>.btn-minier, .btn-group>.btn-xs, .btn.btn-white {
    border-width: 1px;
}
.btn-group+.btn-sm.active:after, .btn-group>.btn-sm.active:after {
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-bottom-width: 1px;
}
.btn-group+.btn-mini.active:after, .btn-group+.btn-minier.active:after, .btn-group+.btn-xs.active:after, .btn-group>.btn-mini.active:after, .btn-group>.btn-minier.active:after, .btn-group>.btn-xs.active:after {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-width: 1px;
}
.btn.btn-bold, .btn.btn-round {
    border-bottom-width: 2px;
}
.btn-group-vertical>.btn:first-child:not(:last-child), .btn-group-vertical>.btn:last-child:not(:first-child) {
    border-radius: 0;
}
.btn-group-vertical>.btn, .btn-group-vertical>.btn+.btn {
    margin: 1px 0 0;
}
.btn-group-vertical>.btn:first-child {
    margin-top: 0;
}
.btn-group.btn-overlap>.btn {
    margin-right: -1px;
}
.btn-group.btn-corner>.btn:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}
.btn-group.btn-corner>.btn:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}
.btn-group.btn-corner>.btn.btn-sm:first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}
.btn-group.btn-corner>.btn.btn-sm:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
.btn-group.btn-corner>.btn.btn-mini:first-child, .btn-group.btn-corner>.btn.btn-xs:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.btn-group.btn-corner>.btn.btn-mini:last-child, .btn-group.btn-corner>.btn.btn-xs:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
.btn.btn-round {
    border-radius: 4px!important;
}
.btn.btn-app {
    display: inline-block;
    width: 100px;
    font-size: 18px;
    font-weight: 400;
    color: #FFF;
    text-align: center;
    text-shadow: 0 -1px -1px rgba(0, 0, 0, .2)!important;
    border: none;
    border-radius: 12px;
    padding: 12px 0 8px;
    margin: 2px;
    line-height: 1.7;
    position: relative;
}
.btn-app, .btn-app.btn-default, .btn-app.btn-default.disabled:hover, .btn-app.btn-default.no-hover:hover, .btn-app.disabled:hover, .btn-app.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #BCC9D5 0, #ABBAC3 100%)!important;
    
    background-color:  #b4c2cc !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app .btn-default option:hover, .btn-app:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #a3b5c5 0, #93a6b2 100%)!important;
    
    background-color:  #9baebc !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-primary, .btn-app.btn-primary.disabled option:hover, .btn-app.btn-primary.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #3B98D6 0, #197EC1 100%)!important;
    
    background-color:  #2a8bcb !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-primary:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #267eb8 0, #136194 100%)!important;
    
    background-color:  #1d6fa6 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-info, .btn-app.btn-info.disabled:hover, .btn-app.btn-info.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #75B5E6 0, #5BA4D5 100%)!important;
    
    background-color:  #68adde !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-info:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #4a9ede 0, #348dc9 100%)!important;
    
    background-color:  #3f96d4 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-success, .btn-app.btn-success.disabled:hover, .btn-app.btn-success.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #8EBF60 0, #7DAA50 100%)!important;
    
    background-color:  #85b558 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-success:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #74a844 0, #648740 100%)!important ;
    
    background-color:  #6c9842 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-danger, .btn-app.btn-danger.disabled:hover, .btn-app.btn-danger.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #D55B52 0, #D12723 100%)!important;
    
    background-color:  #d3413b !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-danger:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #c43a30 0, #a51f1c 100%)!important;
    
    background-color:  #b52c26 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-warning, .btn-app.btn-warning.disabled:hover, .btn-app.btn-warning.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #FFBF66 0, #FFA830 100%)!important;
    
    background-color:  #ffb44b !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-warning:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #fa3 0, #fc9200 100%)!important;
    
    background-color:  #fe9e19 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-purple, .btn-app.btn-purple.disabled:hover, .btn-app.btn-purple.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #A696CE 0, #8A7CB4 100%)!important;
    
    background-color:  #9889c1 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-purple:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #8973be 0, #6d5ca1 100%)!important;
    
    background-color:  #7b68af !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-pink, .btn-app.btn-pink.disabled:hover, .btn-app.btn-pink.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #DB5E8C 0, #CE3970 100%)!important;
    
    background-color:  #d54c7e !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-pink:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #d2346e 0, #aa2a59 100%)!important;
    
    background-color:  #be2f64 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-inverse, .btn-app.btn-inverse.disabled:hover, .btn-app.btn-inverse.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #555 0, #333 100%)!important;
    
    background-color:  #444 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-inverse:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #3b3b3b 0, #1a1a1a 100%)!important;
    
    background-color:  #2b2b2b !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-grey, .btn-app.btn-grey.disabled:hover, .btn-app.btn-grey.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #898989 0, #696969 100%)!important;
    
    background-color:  #797979 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn-app.btn-grey:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #7c7c7c 0, #5c5c5c 100%)!important;
    
    background-color:  #6c6c6c !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn.btn-app.btn-light {
    color: #5A5A5A!important;
    text-shadow: 0 1px 1px #EEE!important;
}
.btn.btn-app.btn-light, .btn.btn-app.btn-light.disabled:hover, .btn.btn-app.btn-light.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #F4F4F4 0, #E6E6E6 100%)!important;
    
    background-color:  #ededed !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn.btn-app.btn-light:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #e7e7e7 0, #d9d9d9 100%)!important;
    
    background-color:  #e0e0e0 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn.btn-app.btn-yellow {
    color: #963!important;
    text-shadow: 0 -1px 0 rgba(255, 255, 255, .4)!important;
}
.btn.btn-app.btn-yellow, .btn.btn-app.btn-yellow.disabled:hover, .btn.btn-app.btn-yellow.no-hover:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #FFE8A5 0, #FCD76A 100%)!important;
    
    background-color:  #fee088 !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.btn.btn-app.btn-yellow:hover {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #ffe18b 0, #fbd051 100%)!important;
    
    background-color:  #fdd96e !important;
    
    background-repeat:  repeat-x !important;
    
    background-attachment:  scroll !important;
    
}
.badge, .label {
    font-weight: 400;
    background-color: #ABBAC3;
    text-shadow: none;
}
.btn.btn-app>.ace-icon {
    opacity: .88;
}
.btn.btn-app:hover>.ace-icon {
    opacity: 1;
}
.btn.btn-app.btn-sm {
    width: 80px;
    font-size: 16px;
    border-radius: 10px;
    line-height: 1.5;
}
.btn.btn-app.btn-xs {
    width: 64px;
    font-size: 15px;
    border-radius: 8px;
    padding-bottom: 7px;
    padding-top: 8px;
    line-height: 1.45;
}
.btn.btn-app>.ace-icon {
    display: block;
    font-size: 42px;
    margin: 0 0 4px;
    line-height: 36px;
    min-width: 0;
    padding: 0;
}
.dropdown-50, .dropdown-menu.dropdown-only-icon {
    min-width: 50px;
}
.btn.btn-app.btn-sm>.ace-icon {
    display: block;
    font-size: 32px;
    line-height: 30px;
    margin: 0 0 3px;
}
.btn.btn-app.btn-xs>.ace-icon {
    display: block;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
}
.btn.btn-app.no-radius {
    border-radius: 0;
}
.btn.btn-app.radius-4 {
    border-radius: 4px;
}
.btn.btn-app>.badge, .btn.btn-app>.label {
    position: absolute!important;
    top: -2px;
    right: -2px;
    padding: 1px 3px;
    text-align: center;
    font-size: 12px;
    color: #FFF;
}
.btn.btn-app>.badge.badge-left, .btn.btn-app>.badge.label-left, .btn.btn-app>.label.badge-left, .btn.btn-app>.label.label-left {
    right: auto;
    left: -2px;
}
.btn.btn-app>.badge-yellow, .btn.btn-app>.label-yellow {
    color: #963;
}
.btn.btn-app>.badge-light, .btn.btn-app>.label-light {
    color: #888;
}
.btn.btn-app>.label {
    padding: 1px 6px 3px;
    font-size: 14px;
}
.badge, .label {
    font-size: 12px;
}
.btn.btn-app.no-radius>.badge, .btn.btn-app.radius-4>.badge {
    border-radius: 3px;
}
.badge.no-radius, .btn.btn-app.no-radius>.badge.no-radius, .btn.btn-app.radius-4>.badge.no-radius, .label {
    border-radius: 0;
}
.btn.btn-app.active {
    color: #FFF;
}
.btn.btn-app.active:after {
    display: none;
}
.btn.btn-app.active.btn-yellow {
    color: #963;
    border-color: #FEE188;
}
.btn.btn-app.active.btn-light {
    color: #515151;
}
.btn-group>.btn-app:first-child:not(:last-child):not(.dropdown-toggle) {
    margin-right: 24px;
}
.label.arrowed-in-right, .label.arrowed-right {
    margin-right: 5px;
}
.btn-group>.btn-app+.btn-app.dropdown-toggle {
    position: absolute;
    width: auto;
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: -23px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    right: 0;
}
.label.arrowed, .label.arrowed-in {
    margin-left: 5px;
}
.btn.btn-app.btn-light, .btn.btn-app.btn-yellow {
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .08) inset!important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .08) inset!important;
}
.dropdown-menu, .ui-datepicker {
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
}
.label {
    color: #FFF;
    display: inline-block;
}
.label[class*=col-][class*=arrow] {
    min-height: 0;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
}.badge {
    padding-top: 1px;
    padding-bottom: 3px;
    line-height: 15px;
}
.badge.radius-1 {
    border-radius: 1px;
}
.badge.radius-2 {
    border-radius: 2px;
}
.badge.radius-3 {
    border-radius: 3px;
}
.badge.radius-4 {
    border-radius: 4px;
}
.badge.radius-5 {
    border-radius: 5px;
}
.badge.radius-6 {
    border-radius: 6px;
}
.badge-transparent, .badge.badge-transparent, .label-transparent, .label.label-transparent {
    background-color: transparent;
}
.badge-grey, .badge.badge-grey, .label-grey, .label.label-grey {
    background-color: #A0A0A0;
}
.badge-info, .badge.badge-info, .label-info, .label.label-info {
    background-color: #3A87AD;
}
.badge-primary, .badge.badge-primary, .label-primary, .label.label-primary {
    background-color: #428BCA;
}
.badge-success, .badge.badge-success, .label-success, .label.label-success {
    background-color: #82AF6F;
}
.badge-danger, .badge-important, .badge.badge-danger, .badge.badge-important, .label-danger, .label-important, .label.label-danger, .label.label-important {
    background-color: #D15B47;
}
.badge-inverse, .badge.badge-inverse, .label-inverse, .label.label-inverse {
    background-color: #333;
}
.badge-warning, .badge.badge-warning, .label-warning, .label.label-warning {
    background-color: #F89406;
}
.badge-pink, .badge.badge-pink, .label-pink, .label.label-pink {
    background-color: #D6487E;
}
.badge-purple, .badge.badge-purple, .label-purple, .label.label-purple {
    background-color: #9585BF;
}
.badge-yellow, .badge.badge-yellow, .label-yellow, .label.label-yellow {
    background-color: #FEE188;
}
.badge-light, .badge.badge-light, .label-light, .label.label-light {
    background-color: #E7E7E7;
}
.badge-yellow, .label-yellow {
    color: #963;
    border-color: #FEE188;
}
.badge-light, .label-light {
    color: #888;
}
.label.arrowed, .label.arrowed-in {
    position: relative;
    z-index: 1;
}
.label.arrowed-in:before, .label.arrowed:before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    border: 1px solid transparent;
    border-right-color: #ABBAC3;
}
.label.arrowed-in:before {
    border-color: #ABBAC3 #ABBAC3 #ABBAC3 transparent;
}
.label.arrowed-in-right, .label.arrowed-right {
    position: relative;
    z-index: 1;
}
.label.arrowed-in-right:after, .label.arrowed-right:after {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    border: 1px solid transparent;
    border-left-color: #ABBAC3;
}
.label.arrowed-in-right:after {
    border-color: #ABBAC3 transparent #ABBAC3 #ABBAC3;
}
.label-info.arrowed:before {
    border-right-color: #3A87AD;
}
.label-info.arrowed-in:before {
    border-color: #3A87AD #3A87AD #3A87AD transparent;
}
.label-info.arrowed-right:after {
    border-left-color: #3A87AD;
}
.label-info.arrowed-in-right:after {
    border-color: #3A87AD transparent #3A87AD #3A87AD;
}
.label-primary.arrowed:before {
    border-right-color: #428BCA;
}
.label-primary.arrowed-in:before {
    border-color: #428BCA #428BCA #428BCA transparent;
}
.label-primary.arrowed-right:after {
    border-left-color: #428BCA;
}
.label-primary.arrowed-in-right:after {
    border-color: #428BCA transparent #428BCA #428BCA;
}
.label-success.arrowed:before {
    border-right-color: #82AF6F;
}
.label-success.arrowed-in:before {
    border-color: #82AF6F #82AF6F #82AF6F transparent;
}
.label-success.arrowed-right:after {
    border-left-color: #82AF6F;
}
.label-success.arrowed-in-right:after {
    border-color: #82AF6F transparent #82AF6F #82AF6F;
}
.label-warning.arrowed:before {
    border-right-color: #F89406;
}
.label-danger.arrowed:before, .label-important.arrowed:before {
    border-right-color: #D15B47;
}
.label-warning.arrowed-in:before {
    border-color: #F89406 #F89406 #F89406 transparent;
}
.label-warning.arrowed-right:after {
    border-left-color: #F89406;
}
.label-danger.arrowed-right:after, .label-important.arrowed-right:after {
    border-left-color: #D15B47;
}
.label-warning.arrowed-in-right:after {
    border-color: #F89406 transparent #F89406 #F89406;
}
.label-important.arrowed-in:before {
    border-color: #D15B47 #D15B47 #D15B47 transparent;
}
.label-important.arrowed-in-right:after {
    border-color: #D15B47 transparent #D15B47 #D15B47;
}
.label-danger.arrowed-in:before {
    border-color: #D15B47 #D15B47 #D15B47 transparent;
}
.label-danger.arrowed-in-right:after {
    border-color: #D15B47 transparent #D15B47 #D15B47;
}
.label-inverse.arrowed:before {
    border-right-color: #333;
}
.label-inverse.arrowed-in:before {
    border-color: #333 #333 #333 transparent;
}
.label-inverse.arrowed-right:after {
    border-left-color: #333;
}
.label-inverse.arrowed-in-right:after {
    border-color: #333 transparent #333 #333;
}
.label-pink.arrowed:before {
    border-right-color: #D6487E;
}
.label-pink.arrowed-in:before {
    border-color: #D6487E #D6487E #D6487E transparent;
}
.label-pink.arrowed-right:after {
    border-left-color: #D6487E;
}
.label-pink.arrowed-in-right:after {
    border-color: #D6487E transparent #D6487E #D6487E;
}
.label-purple.arrowed:before {
    border-right-color: #9585BF;
}
.label-purple.arrowed-in:before {
    border-color: #9585BF #9585BF #9585BF transparent;
}
.label-purple.arrowed-right:after {
    border-left-color: #9585BF;
}
.label-purple.arrowed-in-right:after {
    border-color: #9585BF transparent #9585BF #9585BF;
}
.label-yellow.arrowed:before {
    border-right-color: #FEE188;
}
.label-yellow.arrowed-in:before {
    border-color: #FEE188 #FEE188 #FEE188 transparent;
}
.label-yellow.arrowed-right:after {
    border-left-color: #FEE188;
}
.label-yellow.arrowed-in-right:after {
    border-color: #FEE188 transparent #FEE188 #FEE188;
}
.label-light.arrowed:before {
    border-right-color: #E7E7E7;
}
.label-light.arrowed-in:before {
    border-color: #E7E7E7 #E7E7E7 #E7E7E7 transparent;
}
.label-light.arrowed-right:after {
    border-left-color: #E7E7E7;
}
.label-light.arrowed-in-right:after {
    border-color: #E7E7E7 transparent #E7E7E7 #E7E7E7;
}
.label-grey.arrowed:before {
    border-right-color: #A0A0A0;
}
.label-grey.arrowed-in:before {
    border-color: #A0A0A0 #A0A0A0 #A0A0A0 transparent;
}
.label-grey.arrowed-right:after {
    border-left-color: #A0A0A0;
}
.label-grey.arrowed-in-right:after {
    border-color: #A0A0A0 transparent #A0A0A0 #A0A0A0;
}
.label {
    line-height: 1.15;
    height: 20px;
}
.label.arrowed:before {
    left: -10px;
    border-width: 10px 5px;
}
.label-lg.arrowed, .label-lg.arrowed-in {
    margin-left: 6px;
}
.label.arrowed-in:before {
    left: -5px;
    border-width: 10px 5px;
}
.label.arrowed-right:after {
    right: -10px;
    border-width: 10px 5px;
}
.label-lg.arrowed-in-right, .label-lg.arrowed-right {
    margin-right: 6px;
}
.label.arrowed-in-right:after {
    right: -5px;
    border-width: 10px 5px;
}
.label-lg {
    padding: .3em .6em .4em;
    font-size: 14px;
    line-height: 1.1;
    height: 24px;
}
.label-lg.arrowed:before {
    left: -12px;
    border-width: 12px 6px;
}
.label-xlg.arrowed, .label-xlg.arrowed-in {
    margin-left: 7px;
}
.label-lg.arrowed-in:before {
    left: -6px;
    border-width: 12px 6px;
}
.label-lg.arrowed-right:after {
    right: -12px;
    border-width: 12px 6px;
}
.label-xlg.arrowed-in-right, .label-xlg.arrowed-right {
    margin-right: 7px;
}
.label-lg.arrowed-in-right:after {
    right: -6px;
    border-width: 12px 6px;
}
.label-xlg {
    padding: .3em .7em .4em;
    font-size: 14px;
    line-height: 1.3;
    height: 28px;
}
.label-xlg.arrowed:before {
    left: -14px;
    border-width: 14px 7px;
}
.label-sm.arrowed, .label-sm.arrowed-in {
    margin-left: 4px;
}
.label-xlg.arrowed-in:before {
    left: -7px;
    border-width: 14px 7px;
}
.label-xlg.arrowed-right:after {
    right: -14px;
    border-width: 14px 7px;
}
.label-sm.arrowed-in-right, .label-sm.arrowed-right {
    margin-right: 4px;
}
.label-xlg.arrowed-in-right:after {
    right: -7px;
    border-width: 14px 7px;
}
.label-sm {
    padding: .2em .4em .3em;
    font-size: 11px;
    line-height: 1;
    height: 18px;
}
.label-sm.arrowed:before {
    left: -8px;
    border-width: 9px 4px;
}
.label-sm.arrowed-in:before {
    left: -4px;
    border-width: 9px 4px;
}
.label-sm.arrowed-right:after {
    right: -8px;
    border-width: 9px 4px;
}
.label-sm.arrowed-in-right:after {
    right: -4px;
    border-width: 9px 4px;
}
.label>.ace-icon, .label>span {
    line-height: 1;
    vertical-align: bottom;
}
.label.label-white {
    color: #879da9;
    border: 1px solid #ABBAC3;
    background-color: #f2f5f6;
    border-right-width: 1px;
    border-left-width: 2px;
}
.label-white.label-success {
    color: #7b9e6c;
    border-color: #9fbf92;
    background-color: #edf3ea;
}
.label-white.label-warning {
    color: #d9993e;
    border-color: #e4ae62;
    background-color: #fef6eb;
}
.label-white.label-primary {
    color: #6688a6;
    border-color: #8aafce;
    background-color: #eaf2f8;
}
.label-white.label-danger {
    color: #bd7f75;
    border-color: #d28679;
    background-color: #fcf4f2;
}
.label-white.label-info {
    color: #4e7a8f;
    border-color: #7aa1b4;
    background-color: #eaf3f7;
}
.label-white.label-inverse {
    color: #404040;
    border-color: #737373;
    background-color: #ededed;
}
.label-white.label-pink {
    color: #af6f87;
    border-color: #d299ae;
    background-color: #fbeff4;
}
.label-white.label-purple {
    color: #7d6fa2;
    border-color: #b7b1c6;
    background-color: #efedf5;
}
.label-white.label-yellow {
    color: #cfa114;
    border-color: #ecd181;
    background-color: #fdf7e4;
}
.label-white.label-grey {
    color: #878787;
    border-color: #cecece;
    background-color: #ededed;
}
@media screen and (-webkit-min-device-pixel-ratio:1.08) and (-webkit-max-device-pixel-ratio:1.15), screen and (min--moz-device-pixel-ratio:1.08) and (max--moz-device-pixel-ratio:1.15) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
    border-width: 10.5px 6px 11px;
    left: -11px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
    border-width: 10.5px 6px 11px;
    right: -11px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in:before {
    border-width: 10.5px 5px 11px;
    left: -6px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in-right:after {
    border-width: 10.5px 5px 11px;
    right: -6px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.2) and (-webkit-max-device-pixel-ratio:1.45), screen and (min--moz-device-pixel-ratio:1.2) and (max--moz-device-pixel-ratio:1.45), screen and (-webkit-min-device-pixel-ratio:1.6) and (-webkit-max-device-pixel-ratio:1.9), screen and (min--moz-device-pixel-ratio:1.6) and (max--moz-device-pixel-ratio:1.9) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
    border-width: 10.5px 6px;
    left: -11px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
    border-width: 10.5px 6px;
    right: -11px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in:before {
    border-width: 10.5px 5px 10px;
    left: -6px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in-right:after {
    border-width: 10.5px 5px 10px;
    right: -6px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.5) and (-webkit-max-device-pixel-ratio:1.6), screen and (min--moz-device-pixel-ratio:1.5) and (max--moz-device-pixel-ratio:1.6) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
    border-width: 10px 6px;
    left: -12px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
    border-width: 10px 6px;
    right: -12px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.7) and (-webkit-max-device-pixel-ratio:1.8), screen and (min--moz-device-pixel-ratio:1.7) and (max--moz-device-pixel-ratio:1.8) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
    border-width: 10px 6px;
    left: -11.5px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
    border-width: 10px 6px;
    right: -11.5px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in:before {
    border-width: 10px 5px;
    left: -6px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in-right:after {
    border-width: 10px 5px;
    right: -6px;
}
}@media screen and (-webkit-min-device-pixel-ratio:0.8) and (-webkit-max-device-pixel-ratio:0.9), screen and (min--moz-device-pixel-ratio:0.8) and (max--moz-device-pixel-ratio:0.9) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
    border-width: 11px 6px;
    left: -11.5px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
    border-width: 11px 6px;
    right: -11.5px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in:before {
    border-width: 11px 5px;
    left: -6px;
}
.label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in-right:after {
    border-width: 11px 5px;
    right: -6px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.08) and (-webkit-max-device-pixel-ratio:1.15), screen and (min--moz-device-pixel-ratio:1.08) and (max--moz-device-pixel-ratio:1.15) {
    .label-lg.arrowed:before {
    left: -11px;
}
.label-lg.arrowed-right:after {
    right: -11px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.7) and (-webkit-max-device-pixel-ratio:1.8), screen and (min--moz-device-pixel-ratio:1.7) and (max--moz-device-pixel-ratio:1.8) {
    .label-lg.arrowed:before {
    left: -11.5px;
}
.label-lg.arrowed-right:after {
    right: -11.5px;
}
.label-lg.arrowed-in:before {
    border-width: 12.5px 6px 12px;
    left: -6px;
}
.label-lg.arrowed-in-right:after {
    border-width: 12.5px 6px 12px;
    right: -6px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.08) and (-webkit-max-device-pixel-ratio:1.15), screen and (min--moz-device-pixel-ratio:1.08) and (max--moz-device-pixel-ratio:1.15) {
    .label-xlg.arrowed:before {
    left: -13px;
}
.label-xlg.arrowed-right:after {
    right: -13px;
}
.label-xlg.arrowed-in-right:after, .label-xlg.arrowed-in:before {
    border-width: 14px 7px 14.5px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.2) and (-webkit-max-device-pixel-ratio:1.3), screen and (min--moz-device-pixel-ratio:1.2) and (max--moz-device-pixel-ratio:1.3) {
    .label-xlg.arrowed:before {
    border-width: 14.5px 7px;
    left: -13.5px;
}
.label-xlg.arrowed-right:after {
    border-width: 14.5px 7px;
    right: -13.5px;
}
.label-xlg.arrowed-in-right:after, .label-xlg.arrowed-in:before {
    border-width: 14.5px 7px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.3) and (-webkit-max-device-pixel-ratio:1.4), screen and (-webkit-min-device-pixel-ratio:1.5) and (-webkit-max-device-pixel-ratio:1.6), screen and (min--moz-device-pixel-ratio:1.3) and (max--moz-device-pixel-ratio:1.4), screen and (min--moz-device-pixel-ratio:1.5) and (max--moz-device-pixel-ratio:1.6) {
    .label-xlg.arrowed:before {
    border-width: 14.5px 7.5px;
    left: -14.5px;
}
.label-xlg.arrowed-right:after {
    border-width: 14.5px 7.5px;
    right: -14.5px;
}
.label-xlg.arrowed-in-right:after, .label-xlg.arrowed-in:before {
    border-width: 14.5px 7px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.08) and (-webkit-max-device-pixel-ratio:1.15), screen and (min--moz-device-pixel-ratio:1.08) and (max--moz-device-pixel-ratio:1.15) {
    .label-sm.arrowed:before {
    border-width: 9px 5px;
    left: -9px;
}
.label-sm.arrowed-right:after {
    border-width: 9px 5px;
    right: -9px;
}
.label-sm.arrowed-in-right:after, .label-sm.arrowed-in:before {
    border-width: 10px 4px;
}
}@media screen and (-webkit-min-device-pixel-ratio:1.2) and (-webkit-max-device-pixel-ratio:1.3), screen and (min--moz-device-pixel-ratio:1.2) and (max--moz-device-pixel-ratio:1.3) {
    .label-sm.arrowed:before {
    border-width: 9.5px 5px;
    left: -10px;
}
.label-sm.arrowed-right:after {
    border-width: 9.5px 5px;
    right: -10px;
}
.label-sm.arrowed-in-right:after, .label-sm.arrowed-in:before {
    border-width: 9.5px 4px;
}
}.dropdown-menu>.active>a>.ace-icon, .dropdown-menu>li>a:focus>.ace-icon, .dropdown-menu>li>a:hover>.ace-icon, .dropdown-submenu:focus>a>.ace-icon, .dropdown-submenu:hover>a>.ace-icon, .icon-white, .nav-list>.active>a>.ace-icon, .nav-pills>.active>a>.ace-icon, .navbar-inverse .nav>.active>a>.ace-icon {
    background-image: none;
}
.dropdown-menu {
    border-radius: 0!important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
}
.dropdown-menu>li>a {
    font-size: 14px;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 4px;
    margin-bottom: 1px;
    margin-top: 1px;
}
.dropdown-menu.dropdown-only-icon>li {
    margin: 0 4px;
}
.dropdown-menu.dropdown-only-icon>li>a .ace-icon {
    width: 18px;
    display: inline-block;
    text-align: center;
}
.dropdown-menu.dropdown-only-icon>li>a .icon-2x {
    width: 36px;
}
.dropdown-menu>li.active:hover>a, .dropdown-menu>li.active>a, .dropdown-menu>li:hover>a, .dropdown-menu>li>a:active, .dropdown-menu>li>a:focus {
    background: #4F99C6;
    color: #FFF;
}
.dropdown-default>li.active:hover>a, .dropdown-default>li.active>a, .dropdown-default>li:hover>a, .dropdown-default>li>a:active, .dropdown-default>li>a:focus {
    background: #ABBAC3;
    color: #FFF;
}
.dropdown-info>li.active:hover>a, .dropdown-info>li.active>a, .dropdown-info>li:hover>a, .dropdown-info>li>a:active, .dropdown-info>li>a:focus {
    background: #6FB3E0;
    color: #FFF;
}
.dropdown-primary>li.active:hover>a, .dropdown-primary>li.active>a, .dropdown-primary>li:hover>a, .dropdown-primary>li>a:active, .dropdown-primary>li>a:focus {
    background: #428BCA;
    color: #FFF;
}
.dropdown-success>li.active:hover>a, .dropdown-success>li.active>a, .dropdown-success>li:hover>a, .dropdown-success>li>a:active, .dropdown-success>li>a:focus {
    background: #87B87F;
    color: #FFF;
}
.dropdown-warning>li.active:hover>a, .dropdown-warning>li.active>a, .dropdown-warning>li:hover>a, .dropdown-warning>li>a:active, .dropdown-warning>li>a:focus {
    background: #FFA24D;
    color: #FFF;
}
.dropdown-danger>li.active:hover>a, .dropdown-danger>li.active>a, .dropdown-danger>li:hover>a, .dropdown-danger>li>a:active, .dropdown-danger>li>a:focus {
    background: #D15B47;
    color: #FFF;
}
.dropdown-inverse>li.active:hover>a, .dropdown-inverse>li.active>a, .dropdown-inverse>li:hover>a, .dropdown-inverse>li>a:active, .dropdown-inverse>li>a:focus {
    background: #555;
    color: #FFF;
}
.dropdown-purple>li.active:hover>a, .dropdown-purple>li.active>a, .dropdown-purple>li:hover>a, .dropdown-purple>li>a:active, .dropdown-purple>li>a:focus {
    background: #9585BF;
    color: #FFF;
}
.dropdown-pink>li.active:hover>a, .dropdown-pink>li.active>a, .dropdown-pink>li:hover>a, .dropdown-pink>li>a:active, .dropdown-pink>li>a:focus {
    background: #D6487E;
    color: #FFF;
}
.dropdown-grey>li.active:hover>a, .dropdown-grey>li.active>a, .dropdown-grey>li:hover>a, .dropdown-grey>li>a:active, .dropdown-grey>li>a:focus {
    background: #A0A0A0;
    color: #FFF;
}
.dropdown-light>li.active:hover>a, .dropdown-light>li.active>a, .dropdown-light>li:hover>a, .dropdown-light>li>a:active, .dropdown-light>li>a:focus {
    background: #E7E7E7;
    color: #333;
}
.dropdown-lighter>li.active:hover>a, .dropdown-lighter>li.active>a, .dropdown-lighter>li:hover>a, .dropdown-lighter>li>a:active, .dropdown-lighter>li>a:focus {
    background: #EFEFEF;
    color: #444;
}
.dropdown-lightest>li.active:hover>a, .dropdown-lightest>li.active>a, .dropdown-lightest>li:hover>a, .dropdown-lightest>li>a:active, .dropdown-lightest>li>a:focus {
    background: #F3F3F3;
    color: #444;
}
.dropdown-yellow>li.active:hover>a, .dropdown-yellow>li.active>a, .dropdown-yellow>li:hover>a, .dropdown-yellow>li>a:active, .dropdown-yellow>li>a:focus {
    background: #FEE188;
    color: #444;
}
.dropdown-yellow2>li.active:hover>a, .dropdown-yellow2>li.active>a, .dropdown-yellow2>li:hover>a, .dropdown-yellow2>li>a:active, .dropdown-yellow2>li>a:focus {
    background: #F9E8B3;
    color: #444;
}
.dropdown-light-blue>li.active:hover>a, .dropdown-light-blue>li.active>a, .dropdown-light-blue>li:hover>a, .dropdown-light-blue>li>a:active, .dropdown-light-blue>li>a:focus {
    background: #ECF3F9;
    color: #456;
}
.dropdown-menu.dropdown-close {
    top: 92%;
    left: -5px;
}
.dropdown-menu.dropdown-close.dropdown-menu-right {
    left: auto;
    right: -5px;
}
.dropdown-menu.dropdown-closer {
    top: 80%;
    left: -10px;
}
.dropdown-menu.dropdown-closer.dropdown-menu-right {
    right: -10px;
    left: auto;
}
.dropup>.dropdown-menu, .navbar-fixed-bottom .dropdown>.dropdown-menu {
    top: auto!important;
    bottom: 100%}
.dropup>.dropdown-menu.dropdown-close, .navbar-fixed-bottom .dropdown>.dropdown-menu.dropdown-close {
    bottom: 92%}
.dropup>.dropdown-menu.dropdown-closer, .navbar-fixed-bottom .dropdown>.dropdown-menu.dropdown-closer {
    bottom: 80%}
.dropdown-submenu>.dropdown-menu {
    border-radius: 0;
}
.dropdown-submenu>a:after {
    margin-right: -5px;
}
.dropdown-75 {
    min-width: 75px;
}
.dropdown-100 {
    min-width: 100px;
}
.dropdown-125 {
    min-width: 125px;
}
.dropdown-150 {
    min-width: 150px;
}
.dropdown-hover {
    position: relative;
}
.dropdown-hover:before {
    display: block;
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background-color: transparent;
}
.dropdown-hover:hover>.dropdown-menu {
    display: block;
}
.dropdown-menu.dropdown-caret:after, .dropdown-menu.dropdown-caret:before {
    content: "";
    display: inline-block;
    position: absolute;
}
.dropdown-menu>.dropdown-hover>.dropdown-menu {
    top: -5px;
    left: 99%;
    right: auto;
}
.dropdown-menu>.dropdown-hover>.dropdown-menu.dropdown-menu-right {
    left: auto;
    right: 99%}
.dropdown-menu>.dropdown-hover.dropup>.dropdown-menu, .dropup .dropdown-menu>.dropdown-hover>.dropdown-menu {
    top: auto;
    bottom: -5px;
}
.dropdown-menu.dropdown-caret:before {
    border-bottom: 7px solid rgba(0, 0, 0, .2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    left: 9px;
    top: -6px;
}
.dropdown-menu.dropdown-caret:after {
    border-bottom: 6px solid #FFF;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    left: 10px;
    top: -5px;
}
.dropdown-menu.dropdown-caret-right.dropdown-caret:before, .dropdown-menu.dropdown-menu-right.dropdown-caret:before {
    left: auto;
    right: 9px;
}
.dropdown-menu.dropdown-caret-right.dropdown-caret:after, .dropdown-menu.dropdown-menu-right.dropdown-caret:after {
    left: auto;
    right: 10px;
}
.dropup>.dropdown-menu.dropdown-caret:before, .navbar-fixed-bottom .dropdown>.dropdown-menu.dropdown-caret:before {
    border-bottom-width: 0;
    border-top: 7px solid rgba(0, 0, 0, .2);
    bottom: -7px;
    top: auto;
}
.dropup>.dropdown-menu.dropdown-caret:after, .navbar-fixed-bottom .dropdown>.dropdown-menu.dropdown-caret:after {
    border-bottom-width: 0;
    border-top: 6px solid #FFF;
    bottom: -6px;
    top: auto;
}
.dropdown-colorpicker>.dropdown-menu {
    padding: 4px;
    min-width: 130px;
    max-width: 130px;
    top: 80%;
    left: -7px;
}
.dropdown-colorpicker>.dropdown-menu.dropdown-menu-right {
    right: -7px;
    left: auto;
}
.dropdown-colorpicker>.dropdown-menu>li {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px;
}
.dropdown-colorpicker>.dropdown-menu>li>.colorpick-btn {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    border-radius: 0;
    position: relative;
    -webkit-transition: all ease .1s;
    -o-transition: all ease .1s;
    transition: all ease .1s;
}
.dropdown-colorpicker>.dropdown-menu>li>.colorpick-btn:hover {
    text-decoration: none;
    opacity: .8;
    filter: alpha(opacity=80);
    -webkit-transform: scale(1.08, 1.08);
    -ms-transform: scale(1.08, 1.08);
    -o-transform: scale(1.08, 1.08);
    transform: scale(1.08, 1.08);
}
.dropdown-colorpicker>.dropdown-menu>li>.colorpick-btn.selected:after {
    content: "\f00c";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 11px;
    color: #FFF;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 20px;
}
.btn-colorpicker {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #DDD;
    vertical-align: middle;
    border-radius: 0;
}
.dropdown-navbar {
    padding: 0;
    width: 240px;
    -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, .25);
    box-shadow: 0 2px 4px rgba(30, 30, 100, .25);
    border-color: #BCD4E5;
}
.dropdown-navbar>li {
    padding: 0 8px;
    background-color: #FFF;
}
.dropdown-navbar>li.dropdown-header {
    text-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 34px;
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
    border-bottom: 1px solid;
}
.dropdown-navbar>li>.ace-icon, .dropdown-navbar>li>a>.ace-icon {
    margin-right: 5px!important;
    color: #555;
    font-size: 14px;
}
.dropdown-navbar>li>a {
    padding: 10px 2px;
    margin: 0;
    border-top: 1px solid;
    font-size: 12px;
    line-height: 16px;
    color: #555!important;
    background-color: transparent!important;
    white-space: normal;
    border-top-color: #E4ECF3;
}
.dropdown-navbar>li>a .progress {
    margin-bottom: 0;
    margin-top: 4px;
}
.dropdown-navbar>li>a .badge {
    line-height: 16px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
}
.dropdown-navbar>li.dropdown-header+li>a, .dropdown-navbar>li:first-child>a {
    border-top-width: 0;
}
.dropdown-navbar>li.dropdown-footer>a {
    color: #4F99C6!important;
    text-align: center;
    font-size: 14px;
}
.dropdown-navbar>li.dropdown-footer>a:hover {
    background-color: #FFF;
    text-decoration: underline;
}
.dropdown-navbar>li.dropdown-footer>a:hover>.ace-icon {
    text-decoration: none;
}
.dropdown-navbar>li:hover {
    background-color: #F4F9FC;
}
.dropdown-navbar>li.dropdown-header {
    background-color: #ECF2F7;
    color: #8090A0;
    border-bottom-color: #BCD4E5;
}
.dropdown-navbar>li.dropdown-header>.ace-icon {
    color: #8090A0;
}
.dropdown-navbar.navbar-pink {
    border-color: #E5BCD4;
}
.dropdown-navbar.navbar-pink>li:hover {
    background-color: #FCF4F9;
}
.dropdown-navbar.navbar-pink>li.dropdown-header {
    background-color: #F7ECF2;
    color: #B471A0;
    border-bottom-color: #E5BCD4;
}
.dropdown-navbar.navbar-pink>li.dropdown-header>.ace-icon {
    color: #C06090;
}
.dropdown-navbar.navbar-pink>li>a {
    border-top-color: #F3E4EC;
}
.dropdown-navbar.navbar-grey {
    border-color: #E5E5E5;
}
.dropdown-navbar.navbar-grey>li:hover {
    /*background-color: #F8F8F8;*/
    background-color: #165788;
}
.dropdown-navbar.navbar-grey>li.dropdown-header {
    background-color: #F2F2F2;
    color: #3A87AD;
    border-bottom-color: #E5E5E5;
}
.dropdown-navbar.navbar-grey>li.dropdown-header>.ace-icon {
    color: #3A87AD;
}
.dropdown-navbar.navbar-grey>li>a {
    border-top-color: #EEE;
}
.dropdown-navbar.navbar-green {
    border-color: #B4D5AC;
}
.dropdown-navbar.navbar-green>li:hover {
    background-color: #F4F9EF;
}
.dropdown-navbar.navbar-green>li.dropdown-header {
    background-color: #EBF7E4;
    color: #8A6;
    border-bottom-color: #B4D5AC;
}
.dropdown-navbar.navbar-green>li.dropdown-header>.ace-icon {
    color: #90C060;
}
.dropdown-navbar.navbar-green>li>a {
    border-top-color: #ECF3E4;
}
.dropdown-navbar [class*=btn].ace-icon {
    display: inline-block;
    margin: 0 5px 0 0;
    width: 24px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}
.dropdown-navbar .msg-photo {
    max-width: 42px;
    float: left;
    margin-top: 2px;
}
.dropdown-navbar .msg-body {
    display: block;
    line-height: 20px;
    white-space: normal;
    vertical-align: middle;
    margin-left: 50px;
}
.dropdown-navbar .msg-title {
    display: inline-block;
    line-height: 14px;
}
.dropdown-navbar .msg-time {
    display: block;
    font-size: 11px;
    color: #777;
}
.dropdown-navbar .msg-time>.ace-icon {
    font-size: 14px;
    color: #555;
}
.dropdown-navbar>.dropdown-content {
    padding: 0;
}
.dropdown-navbar>.dropdown-content .dropdown-navbar {
    list-style: none;
    margin: 0;
    padding: 0!important;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    display: block;
    float: none;
    border-width: 0;
    position: static;
    z-index: auto;
}
.dropdown-navbar .nav-tabs {
    border-width: 0;
    box-shadow: none;
    background-color: #F9F9F9!important;
    top: auto;
    width: 100%}
.dropdown-navbar .nav-tabs>li {
    display: table-cell;
    width: 1%;
    float: none!important;
}
.dropdown-navbar .nav-tabs>li>a {
    margin: 0 0 0 1px!important;
    text-align: center;
    box-shadow: none!important;
    background-color: #F9F9F9!important;
    border-width: 0!important;
    border-bottom: 2px solid #BECAD1!important;
}
.dropdown-navbar .nav-tabs>li>a:before {
    display: block;
    content: "";
    position: absolute;
    left: -1px;
    top: 3px;
    bottom: 3px;
    width: 1px;
    background-color: #BECAD1;
}
.dropdown-navbar .nav-tabs>li:first-child>a {
    margin-left: 0!important;
}
.dropdown-navbar .nav-tabs>li:first-child>a:before {
    display: none;
}
.dropdown-navbar .nav-tabs>li.active>a {
    border-bottom: 2px solid #79B0CE!important;
    color: #5085AF!important;
    background-color: #ECF2F7!important;
}
.dropdown-navbar .tab-content {
    padding: 0!important;
    border-color: #FFF!important;
}
.dropdown-navbar .tab-content .dropdown-menu {
    display: block;
    position: static;
    margin: 0!important;
    padding-top: 2px;
    border-color: #FFF!important;
    box-shadow: none!important;
    width: 100%}
.dropdown-navbar.navbar-pink .nav-tabs>li.active>a {
    border-bottom: 2px solid #E5BCD4!important;
    color: #C06090!important;
    background-color: #F7ECF2!important;
}
.dropdown-navbar.navbar-pink .nav-tabs>li:not(.active)>a:hover {
    color: #BA7396;
}
.dropdown-navbar.navbar-pink .nav-tabs>li>a:before {
    background-color: #CEBBCB;
}
.dropdown-navbar.navbar-pink .nav-tabs>li>a {
    border-bottom-color: #CEBBCD!important;
}
.form-line {
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #EEE;
}
.form-actions {
    display: block;
    background-color: #F5F5F5;
    border-top: 1px solid #E5E5E5;
    /* margin-bottom: 20px; */
    margin-top: 20px;
    padding: 19px 20px 20px;
}
.help-button {
    display: inline-block;
    height: 22px;
    width: 22px;
    line-height: 22px;
    text-align: center;
    padding: 0;
    background-color: #65BCDA;
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    cursor: default;
    margin-left: 4px;
    border-radius: 100%;
    border: 2px solid #FFF;
    -webkit-box-shadow: 0 1px 0 1px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .2);
}
.help-button:hover {
    background-color: #65BCDA;
    text-shadow: none;
}
label {
    font-weight: 400;
    margin-top: 5px;
    padding-left: 20px;
}
.form-group>label[class*=col-] {
    margin-bottom: 4px;
    color: #165788;
}
td>label, th>label {
    margin-bottom: 0;
    line-height: inherit;
    vertical-align: middle;
}
label.inline {
    margin-bottom: 3px;
}
input[type=email], input[type=url], input[type=search], input[type=tel], input[type=color], input[type=text], input[type=password], input[type=datetime], input[type=datetime-local], input[type=date], input[type=month], input[type=time], input[type=week], input[type=number], textarea {
    border-radius: 0!important;
    /*color: #696969;*/
    background-color: #FFF;
    border: 1px solid #D5D5D5;
    border-radius: 3px !important;
    padding: 2px 2px 4px;
    font-size: 14px;
    font-family: inherit;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
}
input[type=email]:hover, input[type=url]:hover, input[type=search]:hover, input[type=tel]:hover, input[type=color]:hover, input[type=text]:hover, input[type=password]:hover, input[type=datetime]:hover, input[type=datetime-local]:hover, input[type=date]:hover, input[type=month]:hover, input[type=time]:hover, input[type=week]:hover, input[type=number]:hover, textarea:hover {
    border-color: #b5b5b5;
}
input[type=email]:focus, input[type=url]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=color]:focus, input[type=text]:focus, input[type=password]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=date]:focus, input[type=month]:focus, input[type=time]:focus, input[type=week]:focus, input[type=number]:focus, textarea:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    /*color: #535050;*/
    border-color: #F59942;
    background-color: #FFF;
    outline: 0;
}
.form-control::-webkit-input-placeholder, input::-webkit-input-placeholder {
    color: silver;
}
.form-control:-moz-placeholder, input:-moz-placeholder {
    color: silver;
    opacity: 1;
}
.form-control::-moz-placeholder, input::-moz-placeholder {
    color: silver;
    opacity: 1;
}
.form-control:-ms-input-placeholder, input:-ms-input-placeholder {
    color: silver;
}
.form-control, select {
    border-radius: 0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    /*color: #858585;*/
    background-color: #FFF;
    border: 1px solid #D5D5D5;
}

.form-control:focus, select:focus {
    color: #696969;
    border-color: #F59942;
    background-color: #FFF;
    outline: 0;
}
textarea.form-control {
    padding: 5px 9px;
}
select {
    padding: 3px 4px;
    border-radius: 3px;
    /*height: 30px;*/
}

select.form-control {
    padding: 4px 6px;
}
select.form-control[multiple], select[multiple] {
    height: auto;
}
select.input-sm {
    border-radius: 0;
    padding: 2px 3px;
}
select.input-lg {
    border-radius: 0;
    padding: 6px 8px;
}
input.block {
    display: block;
    margin-bottom: 9px;
}

select option, select.form-control option {
    padding: 3px 4px 5px;
}
select option:active, select option:focus, select option:hover, select.form-control option:active, select.form-control option:focus, select.form-control option:hover {
    background-color: #EEE;
    color: #444;
}
select [disabled] {
    background-color:#F5F5F5;
}
select option.no-option, select.form-control option.no-option {
    padding: 1px 0;
}
input[disabled] {
    color: #848484!important;
    background-color: #EEE!important;
}
input[disabled]:hover {
    border-color: #D5D5D5!important;
}
input[readonly] {
    color: #939192;
    /* background: #F5F5F5!important; */
    cursor: default;
}
/* input:not([type="color"]):read-only {
    color: #939192;
     background: #F5F5F5!important;  
    cursor: default;
} */
input[readonly]:hover {
    border-color: #c3c3c3;
}
input[readonly]:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #AAA;
    background-color: #F9F9F9;
}
textarea[readonly] {
    color: #939192;
    background: #F5F5F5!important;
    cursor: default;
}
textarea[readonly]:hover {
    border-color: #c3c3c3;
}
textarea[readonly]:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #AAA;
    background-color: #F9F9F9;
}
.help-inline {
    font-size: 14px!important;
}
.input-icon {
    position: relative;
}
span.input-icon {
    display: inline-block;
}
.input-icon>input {
    padding-left: 24px;
    padding-right: 6px;
}
.input-icon.input-icon-right>input {
    padding-left: 6px;
    padding-right: 24px;
}
.input-icon>.ace-icon {
    padding: 0 3px;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 3px;
    line-height: 30px;
    display: inline-block;
    color: #909090;
    font-size: 16px;
}
.input-icon.input-icon-right>.ace-icon {
    left: auto;
    right: 3px;
}
.input-icon>input:focus+.ace-icon {
    color: #579;
}
.input-icon~.help-inline {
    padding-left: 8px;
}
.form-inline .checkbox [type=checkbox]+label, .form-inline .radio [type=radio]+label, .form-search .checkbox [type=checkbox]+label, .form-search .radio [type=radio]+label {
    float: left;
    margin-left: -20px;
}
.form-inline .form-inline .checkbox [type=checkbox]+label, .form-inline .form-inline .radio [type=radio]+label, .form-inline .form-search .checkbox [type=checkbox]+label, .form-inline .form-search .radio [type=radio]+label, .form-search .form-inline .checkbox [type=checkbox]+label, .form-search .form-inline .radio [type=radio]+label, .form-search .form-search .checkbox [type=checkbox]+label, .form-search .form-search .radio [type=radio]+label {
    margin-left: 0;
    margin-right: 3px;
}
.form-search .input-append .search-query:focus, .form-search .input-prepend .search-query:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.input-append .uneditable-input, .input-append input, .input-append select, .input-prepend .uneditable-input, .input-prepend input, .input-prepend select {
    border-radius: 0;
}
.input-mini {
    width: 60px;
    max-width: 100%}
.input-small {
    width: 90px;
    max-width: 100%}
.input-md, .input-medium {
    width: 250px;
    max-width: 100%}
.input-large {
    width: 210px;
    max-width: 100%}
.input-xlarge {
    width: 270px;
    height: 70px;
    max-width: 100%}
.input-xxlarge {
    width: 530px;
    max-width: 100%}
input.input-lg {
    font-size: 18px;
}

.form-group.has-success .input-group .input-group-addon {
    border-color: #9cc573;
}
.form-group.has-error .input-group .input-group-addon {
    border-color: #f2a696;
}
.form-group.has-warning .input-group .input-group-addon {
    border-color: #e3c94c;
}
.form-group.has-info .input-group .input-group-addon {
    border-color: #72aec2;
}
.input-group>.btn {
    line-height: 20px;
    padding: 0 6px;
}
.input-group>.btn.btn-sm {
    line-height: 22px;
}
.input-group>.btn+.btn {
    margin-left: 1px;
}
.nav-tabs, .nav-tabs>li:first-child>a {
    margin-left: 0;
}
.input-group>.btn-group>.btn {
    line-height: 23px;
}
.input-group>.btn-group>.btn.btn-sm {
    line-height: 26px;
}
.input-group>.btn-group>.btn.btn-sm>.caret, .input-group>.btn-group>.btn>.caret, .input-group>.btn.btn-sm>.caret, .input-group>.btn>.caret {
    margin-top: 10px;
}
.input-group.input-group-compact {
    width: 1px;
}
.input-group-lg>.form-control, .input-group-lg>.input-group-addon, .input-group-lg>.input-group-btn>.btn {
    padding-top: 6px;
    padding-bottom: 6px;
}
.form-group input[type=email], .form-group input[type=url], .form-group input[type=search], .form-group input[type=tel], .form-group input[type=color], .form-group input[type=text], .form-group input[type=password], .form-group input[type=datetime], .form-group input[type=datetime-local], .form-group input[type=date], .form-group input[type=month], .form-group input[type=time], .form-group input[type=week], .form-group input[type=number], .form-group select, .form-group textarea {
    background: #FFF;
}
    .form-group select[disabled] {
        background: #F5F5F5;
    }
.form-group.has-success input, .form-group.has-success select, .form-group.has-success textarea {
    border-color: #9cc573;
    color: #8BAD4C;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-group.has-success input:focus, .form-group.has-success select:focus, .form-group.has-success textarea:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(130, 188, 58, .3);
    box-shadow: 0 0 0 2px rgba(130, 188, 58, .3);
    color: #6f8a3c;
    border-color: #779c52;
    background-color: #f4f9f0;
}
.form-group.has-success .ace-icon, .form-group.has-success input:focus+.ace-icon, .form-group.has-success select:focus+.ace-icon, .form-group.has-success textarea:focus+.ace-icon {
    color: #8BAD4C;
}
.form-group.has-success .btn .ace-icon {
    color: inherit;
}
.form-group.has-success .control-label, .form-group.has-success .help-block, .form-group.has-success .help-inline {
    color: #7BA065;
}
.form-group.has-info input, .form-group.has-info select, .form-group.has-info textarea {
    border-color: #72aec2;
    color: #4B89AA;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-group.has-info input:focus, .form-group.has-info select:focus, .form-group.has-info textarea:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(58, 120, 188, .3);
    box-shadow: 0 0 0 2px rgba(58, 120, 188, .3);
    color: #3b6c87;
    border-color: #488ea5;
    background-color: #f1f7f9;
}
.form-group.has-info .ace-icon, .form-group.has-info input:focus+.ace-icon, .form-group.has-info select:focus+.ace-icon, .form-group.has-info textarea:focus+.ace-icon {
    color: #4B89AA;
}
.form-group.has-info .btn .ace-icon {
    color: inherit;
}
.form-group.has-info .control-label, .form-group.has-info .help-block, .form-group.has-info .help-inline {
    color: #657BA0;
}
.form-group.has-error input, .form-group.has-error select, .form-group.has-error textarea {
    border-color: #f2a696;
    color: #D68273;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-group.has-error input:focus, .form-group.has-error select:focus, .form-group.has-error textarea:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(219, 137, 120, .3);
    box-shadow: 0 0 0 2px rgba(219, 137, 120, .3);
    color: #ca5f4c;
    border-color: #d77b68;
    background-color: #fef9f8;
}
.form-group.has-error .ace-icon, .form-group.has-error input:focus+.ace-icon, .form-group.has-error select:focus+.ace-icon, .form-group.has-error textarea:focus+.ace-icon {
    color: #D68273;
}
.form-group.has-error .btn .ace-icon {
    color: inherit;
}
.form-group.has-error .control-label, .form-group.has-error .help-block, .form-group.has-error .help-inline {
    color: #D16E6C;
}
.form-group.has-warning input, .form-group.has-warning select, .form-group.has-warning textarea {
    border-color: #e3c94c;
    color: #D3BD50;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-group.has-warning input:focus, .form-group.has-warning select:focus, .form-group.has-warning textarea:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(216, 188, 65, .3);
    box-shadow: 0 0 0 2px rgba(216, 188, 65, .3);
    color: #c0a830;
    border-color: #d5b630;
    background-color: #fdfbf3;
}
.form-group.has-warning .ace-icon, .form-group.has-warning input:focus+.ace-icon, .form-group.has-warning select:focus+.ace-icon, .form-group.has-warning textarea:focus+.ace-icon {
    color: #D3BD50;
}
.form-group.has-warning .btn .ace-icon {
    color: inherit;
}
.form-group.has-warning .control-label, .form-group.has-warning .help-block, .form-group.has-warning .help-inline {
    color: #D19D59;
}
.form-group input:disabled, .form-group input[disabled] {
    color: #848484!important;
    background-color: #EEE!important;
}
.input-transparent, input[type].input-transparent, textarea.input-transparent {
    background-color: rgba(0, 0, 0, .5);
    color: #bbb;
    border-color: transparent!important;
}
.input-transparent:focus, input[type].input-transparent:focus, textarea.input-transparent:focus {
    background-color: rgba(0, 0, 0, .66);
    color: #f0f0f0;
}
@media only screen and (max-width:767px) {
    .help-inline, .input-icon+.help-inline {
    padding-left: 0;
    display: block!important;
}
}.tab-content {
    border: 1px solid #C5D0DC;
    padding: 16px 12px;
    position: relative;
}
.tab-content.no-padding {
    padding: 0;
}
.tab-content.no-border {
    border: none;
    padding: 12px;
}
.tab-content.padding-32 {
    padding: 32px 24px;
}
.tab-content.no-border.padding-32 {
    padding: 32px;
}
.tab-content.padding-30 {
    padding: 30px 23px;
}
.tab-content.no-border.padding-30 {
    padding: 30px;
}
.tab-content.padding-28 {
    padding: 28px 21px;
}
.tab-content.no-border.padding-28 {
    padding: 28px;
}
.tab-content.padding-26 {
    padding: 26px 20px;
}
.tab-content.no-border.padding-26 {
    padding: 26px;
}
.tab-content.padding-24 {
    padding: 24px 18px;
}
.tab-content.no-border.padding-24 {
    padding: 24px;
}
.tab-content.padding-22 {
    padding: 22px 17px;
}
.tab-content.no-border.padding-22 {
    padding: 22px;
}
.tab-content.padding-20 {
    padding: 20px 15px;
}
.tab-content.no-border.padding-20 {
    padding: 20px;
}
.tab-content.padding-18 {
    padding: 18px 14px;
}
.tab-content.no-border.padding-18 {
    padding: 18px;
}
.tab-content.padding-16 {
    padding: 16px 12px;
}
.tab-content.no-border.padding-16 {
    padding: 16px;
}
.tab-content.padding-14 {
    padding: 14px 11px;
}
.tab-content.no-border.padding-14 {
    padding: 14px;
}
.tab-content.padding-12 {
    padding: 12px 9px;
}
.tab-content.no-border.padding-12 {
    padding: 12px;
}
.tab-content.padding-10 {
    padding: 10px 8px;
}
.tab-content.no-border.padding-10 {
    padding: 10px;
}
.tab-content.padding-8 {
    padding: 8px 6px;
}
.tab-content.no-border.padding-8 {
    padding: 8px;
}
.tab-content.padding-6 {
    padding: 6px 5px;
}
.tab-content.no-border.padding-6 {
    padding: 6px;
}
.tab-content.padding-4 {
    padding: 4px 3px;
}
.tab-content.no-border.padding-4 {
    padding: 4px;
}
.tab-content.no-border.padding-2, .tab-content.padding-2 {
    padding: 2px;
}
.tab-content.no-border.padding-0, .tab-content.padding-0 {
    padding: 0;
}
.nav.nav-tabs.padding-32 {
    padding-left: 32px;
}
.tabs-left>.nav.nav-tabs.padding-32, .tabs-right>.nav.nav-tabs.padding-32 {
    padding-left: 0;
    padding-top: 32px;
}
.nav.nav-tabs.padding-30 {
    padding-left: 30px;
}
.tabs-left>.nav.nav-tabs.padding-30, .tabs-right>.nav.nav-tabs.padding-30 {
    padding-left: 0;
    padding-top: 30px;
}
.nav.nav-tabs.padding-28 {
    padding-left: 28px;
}
.tabs-left>.nav.nav-tabs.padding-28, .tabs-right>.nav.nav-tabs.padding-28 {
    padding-left: 0;
    padding-top: 28px;
}
.nav.nav-tabs.padding-26 {
    padding-left: 26px;
}
.tabs-left>.nav.nav-tabs.padding-26, .tabs-right>.nav.nav-tabs.padding-26 {
    padding-left: 0;
    padding-top: 26px;
}
.nav.nav-tabs.padding-24 {
    padding-left: 24px;
}
.tabs-left>.nav.nav-tabs.padding-24, .tabs-right>.nav.nav-tabs.padding-24 {
    padding-left: 0;
    padding-top: 24px;
}
.nav.nav-tabs.padding-22 {
    padding-left: 22px;
}
.tabs-left>.nav.nav-tabs.padding-22, .tabs-right>.nav.nav-tabs.padding-22 {
    padding-left: 0;
    padding-top: 22px;
}
.nav.nav-tabs.padding-20 {
    padding-left: 20px;
}
.tabs-left>.nav.nav-tabs.padding-20, .tabs-right>.nav.nav-tabs.padding-20 {
    padding-left: 0;
    padding-top: 20px;
}
.nav.nav-tabs.padding-18 {
    padding-left: 18px;
}
.tabs-left>.nav.nav-tabs.padding-18, .tabs-right>.nav.nav-tabs.padding-18 {
    padding-left: 0;
    padding-top: 18px;
}
.nav.nav-tabs.padding-16 {
    padding-left: 16px;
}
.tabs-left>.nav.nav-tabs.padding-16, .tabs-right>.nav.nav-tabs.padding-16 {
    padding-left: 0;
    padding-top: 16px;
}
.nav.nav-tabs.padding-14 {
    padding-left: 14px;
}
.tabs-left>.nav.nav-tabs.padding-14, .tabs-right>.nav.nav-tabs.padding-14 {
    padding-left: 0;
    padding-top: 14px;
}
.nav.nav-tabs.padding-12 {
    padding-left: 12px;
}
.tabs-left>.nav.nav-tabs.padding-12, .tabs-right>.nav.nav-tabs.padding-12 {
    padding-left: 0;
    padding-top: 12px;
}
.nav.nav-tabs.padding-10 {
    padding-left: 10px;
}
.tabs-left>.nav.nav-tabs.padding-10, .tabs-right>.nav.nav-tabs.padding-10 {
    padding-left: 0;
    padding-top: 10px;
}
.nav.nav-tabs.padding-8 {
    padding-left: 8px;
}
.tabs-left>.nav.nav-tabs.padding-8, .tabs-right>.nav.nav-tabs.padding-8 {
    padding-left: 0;
    padding-top: 8px;
}
.nav.nav-tabs.padding-6 {
    padding-left: 6px;
}
.tabs-left>.nav.nav-tabs.padding-6, .tabs-right>.nav.nav-tabs.padding-6 {
    padding-left: 0;
    padding-top: 6px;
}
.nav.nav-tabs.padding-4 {
    padding-left: 4px;
}
.tabs-left>.nav.nav-tabs.padding-4, .tabs-right>.nav.nav-tabs.padding-4 {
    padding-left: 0;
    padding-top: 4px;
}
.nav.nav-tabs.padding-2 {
    padding-left: 2px;
}
.tabs-left>.nav.nav-tabs.padding-2, .tabs-right>.nav.nav-tabs.padding-2 {
    padding-left: 0;
    padding-top: 2px;
}
.nav-tabs {
    border-color: #C5D0DC;
    margin-bottom: 0!important;
    position: relative;
    top: 1px;
}
.nav-tabs>li>a {
    padding: 7px 12px 8px;
}
.nav-tabs>li>a, .nav-tabs>li>a:focus {
    border-radius: 0!important;
    border-color: #C5D0DC;
    background-color: #F9F9F9;
    color: #999;
    margin-right: -1px;
    line-height: 18px;
    position: relative;
}
.nav-tabs>li>a:hover {
    background-color: #FFF;
    color: #4C8FBD;
    border-color: #C5D0DC;
}
.nav-tabs>li>a:active, .nav-tabs>li>a:focus {
    outline: 0!important;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #576373;
    border-color: #C5D0DC #C5D0DC transparent;
    border-top: 2px solid #4C8FBD;
    background-color: #FFF;
    z-index: 1;
    line-height: 18px;
    margin-top: -1px;
    box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, .15);
}
.tabs-below>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    margin-top: -1px;
    border-color: #C5D0DC;
    border-bottom-width: 0;
}
.tabs-below>.nav-tabs>li>a, .tabs-below>.nav-tabs>li>a:focus, .tabs-below>.nav-tabs>li>a:hover {
    border-color: #C5D0DC;
}
.tabs-below>.nav-tabs>li.active>a, .tabs-below>.nav-tabs>li.active>a:focus, .tabs-below>.nav-tabs>li.active>a:hover {
    border-color: transparent #C5D0DC #C5D0DC;
    border-top-width: 1px;
    border-bottom: 2px solid #4C8FBD;
    margin-top: 0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .15);
}
.tabs-left>.nav-tabs>li>a, .tabs-right>.nav-tabs>li>a {
    min-width: 60px;
}
.tabs-left>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    border-color: #C5D0DC;
    float: left;
}
.tabs-left>.nav-tabs>li {
    float: none!important;
}
.tabs-left>.nav-tabs>li>a, .tabs-left>.nav-tabs>li>a:focus, .tabs-left>.nav-tabs>li>a:hover {
    border-color: #C5D0DC;
    margin: 0 -1px 0 0;
}
.tabs-left>.nav-tabs>li.active>a, .tabs-left>.nav-tabs>li.active>a:focus, .tabs-left>.nav-tabs>li.active>a:hover {
    border-color: #C5D0DC transparent #C5D0DC #C5D0DC;
    border-top-width: 1px;
    border-left: 2px solid #4C8FBD;
    margin: 0 -1px;
    -webkit-box-shadow: -2px 0 3px 0 rgba(0, 0, 0, .15)!important;
    box-shadow: -2px 0 3px 0 rgba(0, 0, 0, .15)!important;
}
.tabs-right>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    border-color: #C5D0DC;
    float: right;
}
.tabs-right>.nav-tabs>li {
    float: none!important;
}
.tabs-right>.nav-tabs>li>a, .tabs-right>.nav-tabs>li>a:focus, .tabs-right>.nav-tabs>li>a:hover {
    border-color: #C5D0DC;
    margin: 0 -1px;
}
.tabs-right>.nav-tabs>li.active>a, .tabs-right>.nav-tabs>li.active>a:focus, .tabs-right>.nav-tabs>li.active>a:hover {
    border-color: #C5D0DC #C5D0DC #C5D0DC transparent;
    border-top-width: 1px;
    border-right: 2px solid #4C8FBD;
    margin: 0 -2px 0 -1px;
    -webkit-box-shadow: 2px 0 3px 0 rgba(0, 0, 0, .15);
    box-shadow: 2px 0 3px 0 rgba(0, 0, 0, .15);
}
.nav-tabs>li>a .badge {
    padding: 1px 5px;
    line-height: 15px;
    opacity: .75;
    vertical-align: initial;
}
.nav-tabs>li>a .ace-icon {
    opacity: .75;
}
.nav-tabs>li.active>a .ace-icon, .nav-tabs>li.active>a .badge {
    opacity: 1;
}
.nav-tabs li .ace-icon {
    width: 1.25em;
    display: inline-block;
    text-align: center;
}
.nav-tabs>li.open .dropdown-toggle {
    background-color: #4F99C6;
    border-color: #4F99C6;
    color: #FFF;
}
.nav-tabs>li.open .dropdown-toggle>.ace-icon {
    color: #FFF!important;
}
.tabs-left .tab-content, .tabs-right .tab-content {
    overflow: auto;
}
.nav-pills .open .dropdown-toggle, .nav>li.dropdown.open.active>a:focus, .nav>li.dropdown.open.active>a:hover {
    background-color: #4F99C6;
    border-color: #4F99C6;
    color: #FFF;
}
.nav-pills .open .dropdown-toggle>.ace-icon, .nav>li.dropdown.open.active>a:focus>.ace-icon, .nav>li.dropdown.open.active>a:hover>.ace-icon {
    color: #FFF!important;
}
.nav-tabs>li:not(.active):not(.open)>a:not(:hover)>.ace-icon:first-child.disabled {
    color: #909090!important;
}
.nav-tabs.tab-size-bigger>li>a {
    padding-left: 14px;
    padding-right: 14px;
}
.nav-tabs.tab-size-bigger>li>a>.ace-icon:first-child {
    display: block;
    margin-bottom: 6px;
    width: auto;
}
.nav-tabs.tab-space-1>li>a {
    margin-right: 1px;
}
.nav-tabs.tab-space-2>li>a {
    margin-right: 2px;
}
.nav-tabs.tab-space-3>li>a {
    margin-right: 3px;
}
.nav-tabs.tab-space-4>li>a {
    margin-right: 4px;
}
.nav-tabs[class*=tab-color-]>li>a, .nav-tabs[class*=tab-color-]>li>a:focus, .nav-tabs[class*=tab-color-]>li>a:hover {
    color: #FFF;
    border-color: transparent;
    margin-right: 3px;
}
.nav-tabs[class*=tab-color-]>li>a>.badge {
    border-radius: 2px;
}
.nav-tabs[class*=tab-color-]>li:not(.active)>a:hover {
    opacity: .85;
    border-color: rgba(0, 0, 0, .15);
    border-bottom-color: transparent;
}
.nav-tabs[class*=tab-color-]>li:not(.active)>a>.ace-icon:first-child {
    color: #FFF!important;
}
.nav-tabs[class*=tab-color-]>li:not(.active)>a>.badge {
    color: rgba(0, 0, 0, .4)!important;
    background-color: #FFF!important;
    border-radius: 2px;
}
.nav-tabs.tab-color-blue>li>a, .nav-tabs.tab-color-blue>li>a:focus {
    background-color: #7DB4D8;
}
.nav-tabs[class*=tab-color-]>li.active>a, .nav-tabs[class*=tab-color-]>li.active>a:focus, .nav-tabs[class*=tab-color-]>li.active>a:hover {
    background-color: #FFF;
    color: #4f80a0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.nav-tabs.tab-color-blue>li.active>a, .nav-tabs.tab-color-blue>li.active>a:focus, .nav-tabs.tab-color-blue>li.active>a:hover {
    color: #4c718a;
    border-color: #7DB4D8 #7DB4D8 transparent;
}
.tabs-below .nav-tabs.tab-color-blue>li.active>a {
    border-color: transparent #7DB4D8 #7DB4D8;
}
.nav-tabs.tab-color-blue {
    border-bottom-color: #C5D0DC;
}
.nav-tabs.background-blue {
    padding-top: 6px;
    background-color: #EFF3F8;
    border: 1px solid #C5D0DC;
}
.tabs-below .nav-tabs.background-blue {
    padding-top: 0;
    padding-bottom: 6px;
}
.tabs-below .nav-tabs.tab-color-blue {
    border-top: none;
    border-bottom-color: #C5D0DC;
}
.accordion-style1.panel-group .panel {
    border-radius: 0;
    border-color: #CDD8E3;
    background-color: #FFF;
    box-shadow: none;
}
.progress, .widget-box {
    -webkit-box-shadow: none;
}
.accordion-style1.panel-group .panel:last-child {
    border-bottom-width: 1px;
}
.accordion-style1.panel-group .panel .collapse {
    background-color: #FFF;
}
.accordion-style1.panel-group .panel+.panel {
    margin-top: 2px;
}
.accordion-style1.panel-group .panel-heading+.panel-collapse .panel-body {
    border-top-color: #CDD8E3!important;
}
.accordion-style1.panel-group .panel-heading {
    padding: 0;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle {
    color: #4C8FBD;
    background-color: #EEF4F9;
    position: relative;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    padding: 10px;
    display: block;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle.collapsed {
    color: #478FCA;
    font-weight: 400;
    background-color: #F9F9F9;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle:hover {
    color: #6EA6CC;
    background-color: #F1F8FD;
    text-decoration: none;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle:active, .accordion-style1.panel-group .panel-heading .accordion-toggle:focus {
    outline: 0;
    text-decoration: none;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle>.ace-icon:first-child {
    width: 16px;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle:hover>.ace-icon:first-child {
    text-decoration: none;
}
.accordion-style1.panel-group .collapse.in>.panel-body, .accordion-style1.panel-group .panel-body {
    border-top: 1px solid #CDD8E3;
}
.accordion-style1.panel-group.no-padding {
    padding: 0;
}
.accordion-style2.panel-group .panel {
    border-width: 0;
}
.accordion-style2.panel-group .panel:last-child {
    border-bottom-width: 0;
}
.accordion-style2.panel-group .panel+.panel {
    margin-top: 4px;
}
.accordion-style2.panel-group .panel .panel-body {
    border-top: none;
}
.accordion-style2.panel-group .panel-heading .accordion-toggle {
    background-color: #EDF3F7;
    border: 2px solid #6EAED1;
    border-width: 0 0 0 2px;
}
.accordion-style2.panel-group .panel-heading .accordion-toggle:hover {
    text-decoration: none;
}
.accordion-style2.panel-group .panel-heading .accordion-toggle.collapsed {
    background-color: #F3F3F3;
    color: #606060;
    border-width: 0 0 0 1px;
    border-color: #D9D9D9;
}
.accordion-style2.panel-group .panel-heading .accordion-toggle.collapsed:hover {
    background-color: #F6F6F6;
    color: #438EB9;
    text-decoration: none;
}
.accordion-style2.panel-group .collapse.in>.panel-body, .accordion-style2.panel-group .panel-body {
    border-top: none;
}
.accordion-style2.panel-group .accordion-style2.panel-group .panel {
    border-bottom: 1px dotted #D9D9D9;
}
.accordion-style2.panel-group .accordion-style2.panel-group .panel:last-child {
    border-bottom: none;
}
.accordion-style2.panel-group .accordion-style2.panel-group .panel .panel-heading, .accordion-style2.panel-group .accordion-style2.panel-group .panel .panel-heading .accordion-toggle {
    background-color: transparent;
    border-width: 0;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 8px;
}
.accordion-style2.panel-group .accordion-style2.panel-group .panel .panel-heading {
    padding-top: 0;
    padding-bottom: 0;
}
/*.table-bordered, td, th {
    border-radius: 0!important;
}
.table>thead>tr {
    background-position:  0% 0%;
    
    color: #707070;
    font-weight: 400;
    background-image: linear-gradient(to bottom, #F8F8F8 0, #ECECEC 100%);
    
    background-color:  #F2F2F2;
    
    background-repeat:  repeat-x;
    
    background-attachment:  scroll;
    
}
.table>thead>tr>th {
    border-color: #ddd;
    font-weight: 700;
}
.table>thead>tr>th .ace-icon:first-child {
    margin-right: 2px;
}
.table>thead>tr>th:first-child {
    border-left-color: #F1F1F1;
}
.table>thead>tr>th:last-child {
    border-right-color: #F1F1F1;
}
.table.table-bordered>thead>tr>th {
    vertical-align: middle;
}
.table.table-bordered>thead>tr>th:first-child {
    border-left-color: #ddd;
}
td.center, th.center {
    text-align: center;
}
td .lbl, th .lbl {
    margin-bottom: 0;
}
td .lbl:only-child, th .lbl:only-child {
    vertical-align: top;
}
.table-header {
    background-color: #307ECC;
    color: #FFF;
    font-size: 14px;
    line-height: 38px;
    padding-left: 12px;
    margin-bottom: 1px;
}
.table-header .close {
    margin-right: 8px;
    margin-top: 0;
    opacity: .45;
    filter: alpha(opacity=45);
}
.table-header .close:hover {
    opacity: .75;
    filter: alpha(opacity=75);
}
th.detail-col {
    width: 48px;
    text-align: center;
}
tr.detail-row {
    display: none;
}
tr.detail-row.open {
    display: block;
    display: table-row;
}
tr.detail-row>td {
    background-color: #f1f6f8;
    border-top: 3px solid #d1e1ea!important;
}
.table-detail {
    background-color: #fff;
    border: 1px solid #dcebf7;
    width: 100%;
    padding: 12px;
}
.table-detail td>.profile-user-info {
    width: 100%}*/
.widget-box {
    padding: 0;
    box-shadow: none;
    margin: 3px 0;
    border: 1px solid #CCC;
}
@media only screen and (max-width:767px) {
    .widget-box {
    margin-top: 7px;
    margin-bottom: 7px;
}
}.widget-header {
    background-position:  0% 0%;
    
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    position: relative;
    /*min-height: 38px;*/
    /*color:  #669FC7;*/
    color:  #FFF;
    border-bottom:  1px solid #DDD;
    
    padding-left:  12px;
    
    background-image:  linear-gradient(to bottom, #1B6AAA 0, #165788 100%);
    
    background-color:  #f7f7f7;
    
    background-repeat:  repeat-x;
    
    background-attachment:  scroll;
    
}
.widget-box.transparent>.widget-header, .widget-header-flat {
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.widget-header:after, .widget-header:before {
    content: "";
    display: table;
    line-height: 0;
}
.widget-header:after {
    clear: right;
}
.widget-box.collapsed>.widget-header {
    border-bottom-width: 0;
}
.collapsed.fullscreen>.widget-header {
    border-bottom-width: 1px;
}
.collapsed>.widget-body {
    display: none;
}
.widget-header-flat {
    background: #F7F7F7;
}
.widget-header-large {
    min-height: 49px;
    padding-left: 18px;
}
.widget-header-small {
    min-height: 31px;
    padding-left: 10px;
}
.widget-header>.widget-title {
    line-height: 36px;
    padding: 0;
    margin: 0;
    display: inline;
}
.widget-header>.widget-title>.ace-icon {
    margin-right: 5px;
    font-weight: 400;
    display: inline-block;
}
.infobox .infobox-content:first-child, .infobox>.badge, .infobox>.stat, .percentage {
    font-weight: 700;
}
.widget-header-large>.widget-title {
    line-height: 48px;
}
.widget-header-small>.widget-title {
    line-height: 30px;
}
.widget-toolbar {
    display: inline-block;
    padding: 0 10px;
    line-height: 37px;
    float: right;
    position: relative;
}
.widget-header-large>.widget-toolbar {
    line-height: 48px;
}
.widget-header-small>.widget-toolbar {
    line-height: 29px;
}
.widget-toolbar.no-padding {
    padding: 0;
}
.widget-toolbar.padding-5 {
    padding: 0 5px;
}
.widget-toolbar:before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: -1px;
    border: 1px solid #D9D9D9;
    border-width: 0 1px 0 0;
}
.popover-notitle+.popover .popover-title, .popover.popover-notitle .popover-title, .widget-toolbar.no-border:before {
    display: none;
}
.widget-header-large>.widget-toolbar:before {
    top: 6px;
    bottom: 6px;
}
[class*=widget-color-]>.widget-header>.widget-toolbar:before {
    border-color: #EEE;
}
.widget-color-orange>.widget-header>.widget-toolbar:before {
    border-color: #FEA;
}
.widget-color-dark>.widget-header>.widget-toolbar:before {
    border-color: #222;
    box-shadow: -1px 0 0 rgba(255, 255, 255, .2), inset 1px 0 0 rgba(255, 255, 255, .1);
}
.widget-toolbar label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
}
.widget-toolbar>.widget-menu>a, .widget-toolbar>a {
    font-size: 14px;
    margin: 0 1px;
    display: inline-block;
    padding: 0;
    line-height: 24px;
}
.widget-toolbar>.widget-menu>a:hover, .widget-toolbar>a:hover {
    text-decoration: none;
}
.widget-header-large>.widget-toolbar>.widget-menu>a, .widget-header-large>.widget-toolbar>a {
    font-size: 15px;
    margin: 0 1px;
}
.widget-toolbar>.btn {
    line-height: 27px;
    margin-top: -2px;
}
.widget-toolbar>.btn.smaller {
    line-height: 26px;
}
.widget-toolbar>.btn.bigger {
    line-height: 28px;
}
.widget-toolbar>.btn-sm {
    line-height: 24px;
}
.widget-toolbar>.btn-sm.smaller {
    line-height: 23px;
}
.widget-toolbar>.btn-sm.bigger {
    line-height: 25px;
}
.widget-toolbar>.btn-xs {
    line-height: 22px;
}
.widget-toolbar>.btn-xs.smaller {
    line-height: 21px;
}
.widget-toolbar>.btn-xs.bigger {
    line-height: 23px;
}
.widget-toolbar>.btn-minier {
    line-height: 18px;
}
.widget-toolbar>.btn-minier.smaller {
    line-height: 17px;
}
.widget-toolbar>.btn-minier.bigger {
    line-height: 19px;
}
.widget-toolbar>.btn-lg {
    line-height: 36px;
}
.widget-toolbar>.btn-lg.smaller {
    line-height: 34px;
}
.widget-toolbar>.btn-lg.bigger {
    line-height: 38px;
}
.widget-toolbar-dark {
    background: #444;
}
.widget-toolbar-light {
    background: rgba(255, 255, 255, .85);
}
.widget-toolbar>.widget-menu {
    display: inline-block;
    position: relative;
}
.widget-toolbar>.widget-menu>a[data-action], .widget-toolbar>a[data-action] {
    -webkit-transition: transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
}
.widget-toolbar>.widget-menu>a[data-action]>.ace-icon, .widget-toolbar>a[data-action]>.ace-icon {
    margin-right: 0;
}
.widget-toolbar>.widget-menu>a[data-action]:focus, .widget-toolbar>a[data-action]:focus {
    text-decoration: none;
    outline: 0;
}
.widget-toolbar>.widget-menu>a[data-action]:hover, .widget-toolbar>a[data-action]:hover {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
.widget-body {
    background-color: #FFF;
}
.widget-main {
    padding: 4px;
}
.widget-main.padding-32 {
    padding: 32px;
}
.widget-main.padding-30 {
    padding: 30px;
}
.widget-main.padding-28 {
    padding: 28px;
}
.widget-main.padding-26 {
    padding: 26px;
}
.widget-main.padding-24 {
    padding: 24px;
}
.widget-main.padding-22 {
    padding: 22px;
}
.widget-main.padding-20 {
    padding: 20px;
}
.widget-main.padding-18 {
    padding: 18px;
}
.widget-main.padding-16 {
    padding: 16px;
}
.widget-main.padding-14 {
    padding: 14px;
}
.widget-main.padding-12 {
    padding: 12px;
}
.widget-main.padding-10 {
    padding: 10px;
}
.widget-main.padding-8 {
    padding: 8px;
}
.widget-main.padding-6 {
    padding: 6px;
}
.widget-main.padding-4 {
    padding: 4px;
}
.widget-main.padding-2 {
    padding: 2px;
}
.widget-main.no-padding, .widget-main.padding-0 {
    padding: 0;
}
.widget-toolbar .progress {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
}
.widget-toolbar>.dropdown, .widget-toolbar>.dropup {
    display: inline-block;
}
.widget-toolbox.toolbox-vertical, .widget-toolbox.toolbox-vertical+.widget-main {
    display: table-cell;
    vertical-align: top;
}
.widget-box>.widget-header>.widget-toolbar>.widget-menu>[data-action=settings], .widget-box>.widget-header>.widget-toolbar>[data-action=settings], .widget-color-dark>.widget-header>.widget-toolbar>.widget-menu>[data-action=settings], .widget-color-dark>.widget-header>.widget-toolbar>[data-action=settings] {
    color: #99CADB;
}
.widget-box>.widget-header>.widget-toolbar>.widget-menu>[data-action=reload], .widget-box>.widget-header>.widget-toolbar>[data-action=reload], .widget-color-dark>.widget-header>.widget-toolbar>.widget-menu>[data-action=reload], .widget-color-dark>.widget-header>.widget-toolbar>[data-action=reload] {
    color: #ACD392;
}
.widget-box>.widget-header>.widget-toolbar>.widget-menu>[data-action=collapse], .widget-box>.widget-header>.widget-toolbar>[data-action=collapse], .widget-color-dark>.widget-header>.widget-toolbar>.widget-menu>[data-action=collapse], .widget-color-dark>.widget-header>.widget-toolbar>[data-action=collapse] {
    color: #AAA;
}
.widget-box>.widget-header>.widget-toolbar>.widget-menu>[data-action=close], .widget-box>.widget-header>.widget-toolbar>[data-action=close], .widget-color-dark>.widget-header>.widget-toolbar>.widget-menu>[data-action=close], .widget-color-dark>.widget-header>.widget-toolbar>[data-action=close] {
    color: #E09E96;
}
.widget-box[class*=widget-color-]>.widget-header {
    color: #FFF;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.widget-color-blue {
    border-color: #307ECC;
}
.widget-color-blue>.widget-header {
    background: #307ECC;
    border-color: #307ECC;
}
.widget-color-blue2 {
    border-color: #5090C1;
}
.widget-color-blue2>.widget-header {
    background: #5090C1;
    border-color: #5090C1;
}
.widget-color-blue3 {
    border-color: #6379AA;
}
.widget-color-blue3>.widget-header {
    background: #6379AA;
    border-color: #6379AA;
}
.widget-color-green {
    border-color: #82AF6F;
}
.widget-color-green>.widget-header {
    background: #82AF6F;
    border-color: #82AF6F;
}
.widget-color-green2 {
    border-color: #2E8965;
}
.widget-color-green2>.widget-header {
    background: #2E8965;
    border-color: #2E8965;
}
.widget-color-green3 {
    border-color: #4EBC30;
}
.widget-color-green3>.widget-header {
    background: #4EBC30;
    border-color: #4EBC30;
}
.widget-color-red {
    border-color: #E2755F;
}
.widget-color-red>.widget-header {
    background: #E2755F;
    border-color: #E2755F;
}
.widget-color-red2 {
    border-color: #E04141;
}
.widget-color-red2>.widget-header {
    background: #E04141;
    border-color: #E04141;
}
.widget-color-red3 {
    border-color: #D15B47;
}
.widget-color-red3>.widget-header {
    background: #D15B47;
    border-color: #D15B47;
}
.widget-color-purple {
    border-color: #7E6EB0;
}
.widget-color-purple>.widget-header {
    background: #7E6EB0;
    border-color: #7E6EB0;
}
.widget-color-pink {
    border-color: #CE6F9E;
}
.widget-color-pink>.widget-header {
    background: #CE6F9E;
    border-color: #CE6F9E;
}
.widget-color-orange {
    border-color: #E8B10D;
}
.widget-color-orange>.widget-header {
    color: #855D10!important;
    border-color: #E8B10D;
    background: #FFC657;
}
.widget-color-dark {
    border-color: #5a5a5a;
}
.widget-color-dark>.widget-header {
    border-color: #666;
    background: #404040;
}
.widget-color-grey {
    border-color: #9e9e9e;
}
.widget-color-grey>.widget-header {
    border-color: #aaa;
    background: #848484;
}
.widget-box.transparent {
    border-width: 0;
}
.widget-box.transparent>.widget-header {
    background: 0 0;
    border-width: 0;
    border-bottom: 1px solid #DCE8F1;
    color: #4383B4;
    padding-left: 3px;
}
.widget-box.transparent>.widget-header-large {
    padding-left: 5px;
}
.widget-box.transparent>.widget-header-small {
    padding-left: 1px;
}
.widget-box.transparent>.widget-body {
    border-width: 0;
    background-color: transparent;
}
[class*=widget-color-]>.widget-header>.widget-toolbar>.widget-menu>[data-action], [class*=widget-color-]>.widget-header>.widget-toolbar>[data-action] {
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
}
[class*=widget-color-]>.widget-header>.widget-toolbar>.widget-menu>[data-action=settings], [class*=widget-color-]>.widget-header>.widget-toolbar>[data-action=settings] {
    color: #D3E4ED;
}
[class*=widget-color-]>.widget-header>.widget-toolbar>.widget-menu>[data-action=reload], [class*=widget-color-]>.widget-header>.widget-toolbar>[data-action=reload] {
    color: #DEEAD3;
}
[class*=widget-color-]>.widget-header>.widget-toolbar>.widget-menu>[data-action=collapse], [class*=widget-color-]>.widget-header>.widget-toolbar>[data-action=collapse] {
    color: #E2E2E2;
}
[class*=widget-color-]>.widget-header>.widget-toolbar>.widget-menu>[data-action=close], [class*=widget-color-]>.widget-header>.widget-toolbar>[data-action=close] {
    color: #FFD9D5;
}
.widget-color-orange>.widget-header>.widget-toolbar>.widget-menu>[data-action], .widget-color-orange>.widget-header>.widget-toolbar>[data-action] {
    text-shadow: none;
}
.widget-color-orange>.widget-header>.widget-toolbar>.widget-menu>[data-action=settings], .widget-color-orange>.widget-header>.widget-toolbar>[data-action=settings] {
    color: #559AAB;
}
.widget-color-orange>.widget-header>.widget-toolbar>.widget-menu>[data-action=reload], .widget-color-orange>.widget-header>.widget-toolbar>[data-action=reload] {
    color: #7CA362;
}
.widget-color-orange>.widget-header>.widget-toolbar>.widget-menu>[data-action=collapse], .widget-color-orange>.widget-header>.widget-toolbar>[data-action=collapse] {
    color: #777;
}
.widget-color-orange>.widget-header>.widget-toolbar>.widget-menu>[data-action=close], .widget-color-orange>.widget-header>.widget-toolbar>[data-action=close] {
    color: #A05656;
}
.widget-box.light-border[class*=widget-color-]:not(.fullscreen) {
    border-width: 0;
}
.widget-box.light-border[class*=widget-color-]:not(.fullscreen)>.widget-header {
    border: 1px solid;
    border-color: inherit;
}
.widget-box.light-border[class*=widget-color-]:not(.fullscreen)>.widget-body {
    border: 1px solid #D6D6D6;
    border-width: 0 1px 1px;
}
.widget-box.no-border {
    border-width: 0;
}
.widget-box.fullscreen {
    position: fixed;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    border-width: 3px;
    z-index: 1040!important;
}
.widget-box.fullscreen:not([class*=widget-color-]) {
    border-color: #AAA;
}
.widget-body .table {
    border-top: 1px solid #E5E5E5;
}
.widget-body .table thead:first-child tr {
    background: #FFF;
}
[class*=widget-color-]>.widget-body .table thead:first-child tr {
    background-position:  0% 0%;
    
    background-image:  linear-gradient(to bottom, #F8F8F8 0, #ECECEC 100%);
    
    background-color:  #f2f2f2;
    
    background-repeat:  repeat-x;
    
    background-attachment:  scroll;
    
}
.infobox-dark>.infobox-icon>.ace-icon:before, .tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100);
}
.widget-body .table.table-bordered thead:first-child>tr {
    border-top-width: 0;
}
.widget-main.no-padding .table {
    margin-bottom: 0;
    border-width: 0;
}
.widget-main.no-padding .table-bordered td:first-child, .widget-main.no-padding .table-bordered th:first-child {
    border-left-width: 0;
}
.transparent>.widget-body .widget-main .table-bordered>tbody>tr>td:last-child, .transparent>.widget-body .widget-main .table-bordered>tfoot>tr>td:last-child, .transparent>.widget-body .widget-main .table-bordered>thead>tr>th:last-child, .widget-main.no-padding .table-bordered>tbody>tr>td:last-child, .widget-main.no-padding .table-bordered>tfoot>tr>td:last-child, .widget-main.no-padding .table-bordered>thead>tr>th:last-child {
    border-right-width: 0!important;
}
.transparent>.widget-body .widget-main .table-bordered>tbody>tr:last-child>td, .widget-main.no-padding .table-bordered>tbody>tr:last-child>td {
    border-bottom-width: 0!important;
}
.table-bordered>thead.thin-border-bottom>tr>td, .table-bordered>thead.thin-border-bottom>tr>th {
    border-bottom-width: 1px;
}
.widget-body .alert:last-child {
    margin-bottom: 0;
}
.widget-main .tab-content {
    border-width: 0;
}
.widget-toolbar>.nav-tabs {
    border-bottom-width: 0;
    margin-bottom: 0;
    top: auto;
    margin-top: 3px!important;
}
.widget-toolbar>.nav-tabs>li {
    margin-bottom: auto;
}
.widget-toolbar>.nav-tabs>li>a {
    box-shadow: none;
    position: relative;
    top: 1px;
    margin-top: 1px;
}
.widget-toolbar>.nav-tabs>li:not(.active)>a {
    border-color: transparent;
    background-color: transparent;
}
.widget-toolbar>.nav-tabs>li:not(.active)>a:hover {
    background-color: transparent;
}
.widget-toolbar>.nav-tabs>li.active>a {
    background-color: #FFF;
    border-bottom-color: transparent;
    box-shadow: none;
    margin-top: auto;
}
.widget-header-small>.widget-toolbar>.nav-tabs>li>a {
    line-height: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
}
.widget-header-small>.widget-toolbar>.nav-tabs>li.active>a {
    border-top-width: 2px;
}
.widget-header-large>.widget-toolbar>.nav-tabs>li>a {
    line-height: 22px;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-top: 4px;
}
.widget-header-large>.widget-toolbar>.nav-tabs>li.active>a {
    margin-top: 3px;
}
[class*=widget-color-]>.widget-header>.widget-toolbar>.nav-tabs>li>a {
    border-color: transparent;
    background-color: transparent;
    color: #FFF;
    margin-right: 1px;
}
[class*=widget-color-]>.widget-header>.widget-toolbar>.nav-tabs>li>a:hover {
    background-color: #FFF;
    color: #555;
    border-top-color: #FFF;
}
[class*=widget-color-]>.widget-header>.widget-toolbar>.nav-tabs>li.active>a {
    background-color: #FFF;
    color: #555;
    border-top-width: 1px;
    margin-top: 0;
}
.widget-toolbar>.nav-tabs .widget-color-orange>.widget-header>li>a {
    color: #855D10;
}
.transparent>.widget-header>.widget-toolbar>.nav-tabs>li>a {
    color: #555;
    background-color: transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
}
.transparent>.widget-header>.widget-toolbar>.nav-tabs>li.active>a {
    border-top-color: #4C8FBD;
    border-right: 1px solid #C5D0DC;
    border-left: 1px solid #C5D0DC;
    background-color: #FFF;
    box-shadow: none;
}
.widget-toolbox {
    background-color: #EEE;
}
.widget-toolbox:first-child {
    padding: 2px;
    border-bottom: 1px solid #CCC;
}
.widget-toolbox:last-child {
    padding: 2px;
    border-top: 1px solid #CCC;
}
.transparent>.widget-body>.widget-toolbox:last-child {
    border: none;
    border-top: 1px solid #CCC;
}
.widget-toolbox>.btn-toolbar {
    margin: 0!important;
    padding: 0;
}
.widget-toolbox.center {
    text-align: center;
}
.widget-toolbox.toolbox-vertical {
    border-bottom-width: 0;
    border-right: 1px solid #D9D9D9;
    padding: 6px 4px;
    width: 1px;
}
.widget-toolbox.padding-16 {
    padding: 16px;
}
.widget-toolbox.padding-14 {
    padding: 14px;
}
.widget-toolbox.padding-12 {
    padding: 12px;
}
.widget-toolbox.padding-10 {
    padding: 10px;
}
.widget-toolbox.padding-8 {
    padding: 8px;
}
.widget-toolbox.padding-6 {
    padding: 6px;
}
.widget-toolbox.padding-4 {
    padding: 4px;
}
.widget-toolbox.padding-2 {
    padding: 2px;
}
.widget-toolbox.padding-0 {
    padding: 0;
}
.widget-box-overlay {
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px;
    left: -1px;
    z-index: 999;
    text-align: center;
    min-height: 100%;
    background-color: rgba(0, 0, 0, .3);
}
.widget-box-overlay>.loading-icon {
    position: relative;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
}
.widget-box.collapsed .widget-box-overlay>.loading-icon {
    top: 10%}
.widget-box-overlay>.loading-icon.icon-spin {
    -moz-animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    -o-animation-duration: 1.2s;
    -ms-animation-duration: 1.2s;
    animation-duration: 1.2s;
}
.widget-main>form, .widget-main>form .input-append, .widget-main>form .input-prepend {
    margin-bottom: 0;
}
.widget-main.no-padding>form>fieldset, .widget-main.padding-0>form>fieldset {
    padding: 16px;
}
.widget-main.no-padding>form>fieldset+.form-actions, .widget-main.padding-0>form>fieldset+.form-actions {
    padding: 10px 0 12px;
}
.widget-main.no-padding>form>.form-actions, .widget-main.padding-0>form>.form-actions {
    margin: 0;
    padding: 10px 12px 12px;
}
.widget-placeholder {
    border: 2px dashed #D9D9D9;
}
.infobox, .popover, .popover-title, .progress, .tooltip-inner {
    border-radius: 0;
}
.widget-container-col {
    min-height: 10px;
}
.tooltip-inner {
    background-color: #333;
    color: #FFF;
    font-size: 12px;
    text-shadow: 1px 1px 0 rgba(42, 45, 50, .5);
    padding: 5px 9px;
}
.tooltip.top .tooltip-arrow {
    border-bottom-width: 0;
    border-top-color: #333;
}
.tooltip.right .tooltip-arrow {
    border-left-width: 0;
    border-right-color: #333;
}
.tooltip.left .tooltip-arrow {
    border-right-width: 0;
    border-left-color: #333;
}
.tooltip.bottom .tooltip-arrow {
    border-top-width: 0;
    border-bottom-color: #333;
}
.tooltip-error+.tooltip>.tooltip-inner, .tooltip.tooltip-error>.tooltip-inner {
    background-color: #C94D32;
    text-shadow: 1px 1px 0 rgba(100, 60, 20, .3);
}
.tooltip-error+.tooltip.top .tooltip-arrow, .tooltip.tooltip-error.top .tooltip-arrow {
    border-top-color: #C94D32;
}
.tooltip-error+.tooltip.right .tooltip-arrow, .tooltip.tooltip-error.right .tooltip-arrow {
    border-right-color: #C94D32;
}
.tooltip-error+.tooltip.left .tooltip-arrow, .tooltip.tooltip-error.left .tooltip-arrow {
    border-left-color: #C94D32;
}
.tooltip-error+.tooltip.bottom .tooltip-arrow, .tooltip.tooltip-error.bottom .tooltip-arrow {
    border-bottom-color: #C94D32;
}
.tooltip-success+.tooltip>.tooltip-inner, .tooltip.tooltip-success>.tooltip-inner {
    background-color: #629B58;
    text-shadow: 1px 1px 0 rgba(60, 100, 20, .3);
}
.tooltip-success+.tooltip.top .tooltip-arrow, .tooltip.tooltip-success.top .tooltip-arrow {
    border-top-color: #629B58;
}
.tooltip-success+.tooltip.right .tooltip-arrow, .tooltip.tooltip-success.right .tooltip-arrow {
    border-right-color: #629B58;
}
.tooltip-success+.tooltip.left .tooltip-arrow, .tooltip.tooltip-success.left .tooltip-arrow {
    border-left-color: #629B58;
}
.tooltip-success+.tooltip.bottom .tooltip-arrow, .tooltip.tooltip-success.bottom .tooltip-arrow {
    border-bottom-color: #629B58;
}
.tooltip-warning+.tooltip>.tooltip-inner, .tooltip.tooltip-warning>.tooltip-inner {
    background-color: #ED9421;
    text-shadow: 1px 1px 0 rgba(100, 90, 10, .3);
}
.tooltip-warning+.tooltip.top .tooltip-arrow, .tooltip.tooltip-warning.top .tooltip-arrow {
    border-top-color: #ED9421;
}
.tooltip-warning+.tooltip.right .tooltip-arrow, .tooltip.tooltip-warning.right .tooltip-arrow {
    border-right-color: #ED9421;
}
.tooltip-warning+.tooltip.left .tooltip-arrow, .tooltip.tooltip-warning.left .tooltip-arrow {
    border-left-color: #ED9421;
}
.tooltip-warning+.tooltip.bottom .tooltip-arrow, .tooltip.tooltip-warning.bottom .tooltip-arrow {
    border-bottom-color: #ED9421;
}
.tooltip-info+.tooltip>.tooltip-inner, .tooltip.tooltip-info>.tooltip-inner {
    background-color: #4B89AA;
    text-shadow: 1px 1px 0 rgba(40, 50, 100, .3);
}
.tooltip-info+.tooltip.top .tooltip-arrow, .tooltip.tooltip-info.top .tooltip-arrow {
    border-top-color: #4B89AA;
}
.tooltip-info+.tooltip.right .tooltip-arrow, .tooltip.tooltip-info.right .tooltip-arrow {
    border-right-color: #4B89AA;
}
.tooltip-info+.tooltip.left .tooltip-arrow, .tooltip.tooltip-info.left .tooltip-arrow {
    border-left-color: #4B89AA;
}
.tooltip-info+.tooltip.bottom .tooltip-arrow, .tooltip.tooltip-info.bottom .tooltip-arrow {
    border-bottom-color: #4B89AA;
}

.item-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.item-list>li {
    padding: 9px;
    background-color: #FFF;
    margin-top: -1px;
    position: relative;
}
.item-list>li.selected {
    color: #8090A0;
    background-color: #F4F9FC;
}
.item-list>li.selected .lbl, .item-list>li.selected label {
    text-decoration: line-through;
    color: #8090A0;
}
.item-list>li label {
    font-size: 14px;
}
.item-list>li .percentage {
    font-size: 11px;
    font-weight: 700;
    color: #777;
}
li[class*=item-] {
    border: 1px solid #DDD;
    border-left-width: 3px;
}
li.item-orange {
    border-left-color: #E8B110;
}
li.item-orange2 {
    border-left-color: #F79263;
}
li.item-red {
    border-left-color: #D53F40;
}
li.item-red2 {
    border-left-color: #D15B47;
}
li.item-green {
    border-left-color: #9ABC32;
}
li.item-green2 {
    border-left-color: #0490A6;
}
li.item-blue {
    border-left-color: #4F99C6;
}
li.item-blue2 {
    border-left-color: #3983C2;
}
li.item-blue3 {
    border-left-color: #1144EB;
}
li.item-pink {
    border-left-color: #CB6FD7;
}
li.item-purple {
    border-left-color: #6F3CC4;
}
li.item-black {
    border-left-color: #505050;
}
li.item-grey {
    border-left-color: #A0A0A0;
}
li.item-brown {
    border-left-color: brown;
}
li.item-default {
    border-left-color: #ABBAC3;
}

@media only screen and (max-width:480px) {
    .itemdiv.memberdiv {
        float: none;
        width: auto;
    }
}

.form-group.has-error .chosen-single {
    border-color: #f2a696!important;
}
.form-group.has-info .chosen-single {
    border-color: #72aec2!important;
}
.form-group.has-warning .chosen-single {
    border-color: #e3c94c!important;
}
.form-group.has-success .chosen-single {
    border-color: #9cc573!important;
}

.page-content>.row .col-lg-12, .page-content>.row .col-md-12, .page-content>.row .col-sm-12, .page-content>.row .col-xs-12 {
    float: left;
    max-width: 100%}
.col-xs-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none!important;
}
@media (min-width:768px) {
    .col-sm-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none!important;
}
}@media (min-width:992px) {
    .col-md-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none!important;
}
}@media (min-width:1200px) {
    .col-lg-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none!important;
}
}.jqstooltip, .legendColorBox div {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.legendLabel {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 22px;
    padding-left: 2px;
    font-size: 10px;
}
.navbar-fixed-top+.main-container {
    padding-top: 45px;
}
@media (max-width:479px) {
    .navbar-fixed-top+.main-container {
    padding-top: 90px;
}
.navbar-fixed-top.navbar-collapse+.main-container {
    padding-top: 45px;
}
}@media only screen and (max-width:360px) {
    .grid2, .grid3, .grid4 {
    float: none;
    display: block;
    width: 96%;
    border-left-width: 0;
    position: relative;
    margin-bottom: 11px;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 4px;
}
.grid2>[class*=pull-], .grid3>[class*=pull-], .grid4>[class*=pull-] {
    float: none!important;
    display: inline-block;
    position: absolute;
    right: 11px;
    top: 0;
    margin-top: 0;
}
.grid2:last-child, .grid3:last-child, .grid4:last-child {
    border-bottom-width: 0;
}
}@media only screen and (max-width:480px) {
    .hidden-480 {
    display: none!important;
}
}@media only screen and (max-width:320px) {
    .hidden-320 {
    display: none!important;
}
}.no-skin .navbar .navbar-toggle {
    background-color: #75B3D7;
}
.no-skin .navbar .navbar-toggle:focus {
    background-color: #75B3D7;
    border-color: transparent;
}
.no-skin .navbar .navbar-toggle:hover {
    background-color: #61a8d1;
    border-color: rgba(255, 255, 255, .1);
}
.no-skin .navbar .navbar-toggle.display, .no-skin .navbar .navbar-toggle[data-toggle=collapse]:not(.collapsed) {
    background-color: #4d9dcc;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .25);
    border-color: rgba(255, 255, 255, .35);
}
.no-skin .sidebar {
    background-color: #F2F2F2;
    border-style: solid;
    border-color: #79b0ce;
    border-width: 0 1px 0 0;
}
.no-skin .nav-list .open>a, .no-skin .nav-list .open>a:focus, .no-skin .nav-list .open>a:hover {
    background-color: #FAFAFA;
}
.no-skin .nav-list>li {
    /*border-color: #E5E5E5;*/
    border-color: #79b0ce;    
}
.nav-side {
    height: 51px;
}
.nav-icon {
    width: 20px;
    float: left;
}

.nav-text {
    height: 50px;
    float: left;
    width: 140px;
}
.no-skin .nav-list>li>a {
    background-color: #F8F8F8;    
    color: #585858;
}
.upper-nav-colour {
    background-color: transparent !important;
    color: #fff !important;
}
.upper-nav-colour:hover {
    background-color: #359fd9 !important;
    color: #fff !important;
}
.upper-nav-colour:active {
    background-color: #359fd9 !important;
    color: #fff !important;
}
.no-skin .nav-list>li>a:focus {
    background-color: #F8F8F8;
    color: #1963AA;
}
.no-skin .nav-list>li:hover>a {
    background-color: #FFF;
    color: #266cad;
}
.no-skin .nav-list>li.open>a {
    background-color: #FAFAFA;
    color: #1963AA;
}
.no-skin .nav-list>li.active>a {
    font-weight: 700;
    color: #2B7DBC;
}
.no-skin .nav-list>li.active>a, .no-skin .nav-list>li.active>a:focus, .no-skin .nav-list>li.active>a:hover {
    background-color: #FFF;
}
.no-skin .nav-list>li .submenu {
    background-color: #FFF;
    border-color: #E5E5E5;
}
.no-skin .nav-list>li .submenu>li>a {
    border-top-color: #E4E4E4;
    background-color: #FFF;
    color: #616161;
}
.no-skin .nav-list>li .submenu>li>a:hover {
    color: #4B88B7;
    background-color: #F1F5F9;
}
.no-skin .nav-list>li .submenu>li.active>a {
    color: #2B7DBC;
}
.no-skin .nav-list>li .submenu>li.active>a>.menu-icon {
    color: #C86139;
}
.no-skin .nav-list>li .submenu>li.active.open>a>.menu-icon {
    color: inherit;
}
@media only screen and (min-width:992px) {
    .no-skin .nav-list>li .submenu>li.active.hover>a.dropdown-toggle>.menu-icon {
    color: inherit;
}
}.no-skin .nav-list>li .submenu>li.active:not(.open)>a {
    background-color: #F5F7FA;
}
.no-skin .nav-list>li .submenu>li.active:not(.open)>a:hover {
    background-color: #F1F5F9;
}
.no-skin .nav-list>li>.submenu .open>a, .no-skin .nav-list>li>.submenu .open>a:focus, .no-skin .nav-list>li>.submenu .open>a:hover {
    border-color: #E4E4E4;
}
.no-skin .nav-list>li>.submenu li>.submenu>li a {
    color: #757575;
}
.no-skin .nav-list>li>.submenu li>.submenu>li a:hover {
    color: #4B88B7;
    background-color: #F1F5F9;
}
.no-skin .nav-list>li>.submenu li.open>a, .no-skin .nav-list>li>.submenu li>.submenu li.active>a, .no-skin .nav-list>li>.submenu li>.submenu li.open>a {
    color: #4B88B7;
}
.no-skin .nav-list>li>.submenu:before, .no-skin .nav-list>li>.submenu>li:before {
    border-color: #9dbdd6;
}
.no-skin .nav-list>li.active>.submenu:before, .no-skin .nav-list>li.active>.submenu>li:before {
    border-color: #8eb3d0;
}
.no-skin .sidebar-toggle {
    background-color: #F3F3F3;
    border-color: #E0E0E0;
}
.no-skin .sidebar-toggle>.ace-icon {
    border-color: #BBB;
    color: #AAA;
    background-color: #FFF;
}
.no-skin .sidebar-shortcuts {
    background-color: #FAFAFA;
}
.no-skin .sidebar-fixed .sidebar-shortcuts {
    border-color: #DDD;
}
.no-skin .sidebar-shortcuts-mini {
    background-color: #FFF;
}
.no-skin .nav-list li>.arrow:before {
    border-right-color: #B8B8B8;
    border-width: 10px 10px 10px 0;
    left: -10px;
}
.no-skin .nav-list li>.arrow:after {
    border-right-color: #FFF;
    border-width: 10px 10px 10px 0;
    left: -9px;
}
.no-skin .nav-list>li.pull_up>.arrow:after {
    border-right-color: #FFF!important;
}
.no-skin .nav-list li.active>a:after {
    border-right-color: #2B7DBC;
}
.no-skin .nav-list>li.active:after {
    display: block;
    content: "";
    position: absolute;
    right: -2px;
    top: -1px;
    bottom: 0;
    z-index: 1;
    border: 2px solid #2B7DBC;
    border-width: 0 2px 0 0;
}
.no-skin .sidebar-scroll .nav-list>li.active:after {
    right: 0;
}
.no-skin .sidebar.menu-min .nav-list>li>a>.menu-text {
    background-color: #F5F5F5;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    border-color: #CCC;
}
.no-skin .sidebar.menu-min .nav-list>li>a.dropdown-toggle>.menu-text {
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .1);
}
.no-skin .sidebar.menu-min .nav-list>li.active>.submenu {
    border-left-color: #83B6D1;
}
.no-skin .sidebar.menu-min .nav-list>li>.submenu {
    background-color: #FFF;
    border: 1px solid #CCC;
    border-top-color: #e6e6e6;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .1);
}
.no-skin .sidebar.menu-min .nav-list>li>.arrow:after {
    border-right-color: #F5F5F5;
    border-width: 8px 8px 8px 0;
    left: -8px;
}
.no-skin .sidebar.menu-min .nav-list>li>.arrow:before {
    border-width: 8px 8px 8px 0;
    left: -9px;
}
.no-skin .sidebar.menu-min .nav-list>li.active>.arrow:before {
    border-right-color: #5a9ec2;
}
.no-skin .sidebar.menu-min .nav-list>li.active>a>.menu-text {
    border-left-color: #83B6D1;
}
.no-skin .sidebar.menu-min .sidebar-shortcuts-large {
    background-color: #FFF;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    border-color: #CCC;
}
.no-skin .sidebar.menu-min .sidebar-toggle>.ace-icon {
    border-color: #b1b1b1;
}
@media (max-width:991px) {
    .no-skin .sidebar.responsive-min .nav-list>li>a>.menu-text {
    background-color: #F5F5F5;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    border-color: #CCC;
}
.no-skin .sidebar.responsive-min .nav-list>li>a.dropdown-toggle>.menu-text {
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .1);
}
.no-skin .sidebar.responsive-min .nav-list>li.active>.submenu {
    border-left-color: #83B6D1;
}
.no-skin .sidebar.responsive-min .nav-list>li>.submenu {
    background-color: #FFF;
    border: 1px solid #CCC;
    border-top-color: #e6e6e6;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .1);
}
.no-skin .sidebar.responsive-min .nav-list>li>.arrow:after {
    border-right-color: #F5F5F5;
    border-width: 8px 8px 8px 0;
    left: -8px;
}
.no-skin .sidebar.responsive-min .nav-list>li>.arrow:before {
    border-width: 8px 8px 8px 0;
    left: -9px;
}
.no-skin .sidebar.responsive-min .nav-list>li.active>.arrow:before {
    border-right-color: #5a9ec2;
}
.no-skin .sidebar.responsive-min .nav-list>li.active>a>.menu-text {
    border-left-color: #83B6D1;
}
.no-skin .sidebar.responsive-min .sidebar-shortcuts-large {
    background-color: #FFF;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    border-color: #CCC;
}
.no-skin .sidebar.responsive-min .sidebar-toggle>.ace-icon {
    border-color: #b1b1b1;
}
}@media only screen and (min-width:992px) {
    .no-skin .nav-list li.hover>.submenu {
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    border-color: #CCC;
}
.no-skin .nav-list li.hover>.submenu>li.active>a {
    background-color: #F5F5F5;
}
.no-skin .nav-list li.hover>.submenu>li:hover>a {
    background-color: #EEF3F7;
    color: #2E7DB4;
}
}@media only screen and (min-width:992px) and (max-width:991px) {
    .no-skin .sidebar.navbar-collapse .nav-list li li.hover.active.open>a, .no-skin .sidebar.navbar-collapse .nav-list li li.hover:hover>a {
    background-color: #FFF;
}
.no-skin .sidebar.navbar-collapse .nav-list li li.hover.open.active>a:hover, .no-skin .sidebar.navbar-collapse .nav-list li li.hover.open>a:hover, .no-skin .sidebar.navbar-collapse .nav-list li li.hover>a:hover {
    background-color: #F1F5F9;
}
.no-skin .sidebar.navbar-collapse .nav-list>li .submenu>li.active.hover>a.dropdown-toggle>.menu-icon {
    color: #C86139;
}
.no-skin .sidebar.navbar-collapse .nav-list>li .submenu>li.active.open.hover>a.dropdown-toggle>.menu-icon {
    color: inherit;
}
}@media only screen and (min-width:992px) {
    .no-skin .sidebar.navbar-collapse .nav-list>li.open.hover:not(:hover):not(:focus):not(.active)>a {
    color: #585858;
}
.no-skin .sidebar.navbar-collapse .nav-list>li.open.hover:not(:hover):not(:focus):not(.active)>a>.arrow {
    color: inherit;
}
.no-skin .sidebar.navbar-collapse .nav-list>li.open.hover:hover>a {
    background-color: #FFF;
}
.no-skin .sidebar.navbar-collapse .nav-list>li>.submenu li.open.hover:not(:hover):not(:focus):not(.active)>a {
    color: #616161;
}
.no-skin .sidebar.navbar-collapse .nav-list>li>.submenu li.open.hover:not(:hover):not(:focus):not(.active)>a>.arrow {
    color: inherit;
}
}@media only screen and (min-width:992px) and (max-width:991px) {
    .no-skin .sidebar.navbar-collapse .nav-list li.hover>.submenu {
    border-top-color: #E5E5E5;
    background-color: #FFF;
}
.no-skin .nav-list li.hover>.submenu>li.active:not(.open)>a {
    background-color: #F5F7FA;
}
.no-skin .nav-list li.hover>.submenu>li.active:not(.open)>a:hover {
    background-color: #F1F5F9;
}
}@media only screen and (min-width:992px) {
    .no-skin .sidebar.h-sidebar {
    /*background-color: #F8F8F8;*/
    background-color: #165788;
}
.no-skin .sidebar.h-sidebar:before {
    background-color: #E4E4E4;
    -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, .05) inset;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, .05) inset;
}
.no-skin .sidebar.h-sidebar .nav-list>li:hover, .no-skin .sidebar.h-sidebar .nav-list>li:hover+li {
    border-left-color: #CEDDE5;
}
.no-skin .sidebar.h-sidebar .nav-list>li:last-child:hover {
    border-right-color: #CEDDE5;
}
.no-skin .sidebar.h-sidebar .nav-list>li.active, .no-skin .sidebar.h-sidebar .nav-list>li.active+li, .no-skin .sidebar.h-sidebar .nav-list>li:hover+li.active {
    border-left-color: #79B0CE;
}
.no-skin .sidebar.h-sidebar .nav-list>li.active:last-child {
    border-right-color: #79B0CE;
}
.no-skin .sidebar.h-sidebar .nav-list>li.active:before {
    background-color: #2B7DBC;
}
.no-skin .sidebar.h-sidebar .nav-list>li.active>a:after {
    border-width: 0 0 2px;
    border-color: transparent transparent #FFF;
    left: 0;
    right: 0;
    top: auto;
    bottom: -2px;
}
.no-skin .sidebar.h-sidebar .sidebar-shortcuts-large {
    background-color: #FFF;
    border-color: #CCC;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .1);
}
.no-skin .sidebar.h-sidebar .sidebar-shortcuts-large:after {
    border-bottom-color: #FFF;
}
.no-skin .sidebar.h-sidebar .nav-list>li.hover>.submenu {
    border-color: #CCC;
}
.no-skin .sidebar.h-sidebar.menu-min .nav-list>li.hover>.submenu {
    border-top-color: #e6e6e6;
}
.no-skin .sidebar.h-sidebar .nav-list>li.hover>.arrow:after {
    border-color: transparent transparent #FFF;
}
.no-skin .sidebar.h-sidebar .nav-list>li.hover>.arrow:before {
    border-color: transparent transparent #B8B8B8;
}
.no-skin .sidebar.h-sidebar.menu-min .nav-list>li.hover>.arrow:after {
    border-bottom-color: #F5F5F5;
}
.no-skin .sidebar.h-sidebar.menu-min .nav-list>li.active>a>.menu-text {
    border-left-color: #CCC;
}
}@media only screen and (max-width:991px) {
    body {
    overflow-x: hidden;
}
.no-skin .sidebar.responsive .nav-list>li.active.open>a:after, .no-skin .sidebar.responsive-max .nav-list>li.active.open>a:after {
    display: block;
}
.no-skin .sidebar.responsive .nav-list li li.active>a:after, .no-skin .sidebar.responsive-max .nav-list li li.active>a:after {
    display: none;
}
.no-skin .sidebar.responsive .nav-list>li.active:after, .no-skin .sidebar.responsive-max .nav-list>li.active:after {
    height: 41px;
}
.no-skin .sidebar {
    border-width: 0 1px 1px 0;
    border-top-color: #d6d6d6;
}
.no-skin .menu-toggler+.sidebar.responsive {
    border-top-width: 1px;
}
.no-skin .sidebar.responsive-min {
    border-width: 0 1px 0 0;
}
.no-skin .sidebar.navbar-collapse {
    border-width: 0;
    border-bottom-width: 1px!important;
    border-bottom-color: #CCC;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .1)!important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1)!important;
}
.no-skin .sidebar.navbar-collapse.menu-min .nav-list>li>.submenu {
    background-color: #FFF;
}
}.no-skin .sidebar-scroll .sidebar-shortcuts {
    border-bottom-color: #ddd;
}
.no-skin .sidebar-scroll .sidebar-toggle {
    border-top-color: #ddd;
}
.no-skin .main-container .menu-toggler {
    background-color: #444;
}
.no-skin .main-container .menu-toggler:before {
    border-top-color: #87B87F;
    border-bottom-color: #6FB3E0;
}
.no-skin .main-container .menu-toggler:after {
    border-top-color: #FFA24D;
    border-bottom-color: #D15B47;
}
.no-skin .main-container .menu-toggler>.toggler-text {
    border-top-color: #444;
}
.no-skin .nav-list>li.disabled:before {
    display: none!important;
}
.no-skin .nav-list>li.disabled>a {
    background-color: #ebebeb!important;
    color: #656565!important;
}
.no-skin .nav-list li .submenu>li.disabled>a, .no-skin .nav-list li.disabled .submenu>li>a {
    background-color: #f2f2f2!important;
    color: #7a7a7a!important;
    cursor: not-allowed!important;
}
.nav-list li li.highlight.active>a:after, .nav-list li li.highlight.active>a:before, .nav-list li.highlight li.active>a:after, .nav-list li.highlight li.active>a:before, .no-skin .nav-list li .submenu>li.disabled>a>.menu-icon, .no-skin .nav-list li.disabled .submenu>li>a>.menu-icon {
    display: none;
}
.nav-list>li.highlight.active>a:after, .nav-list>li.highlight.active>a:before {
    display: block;
}
.no-skin .nav-list>li.highlight.active, .no-skin .nav-list>li.highlight:hover {
    border-color: #C8D8E2;
}
.no-skin .nav-list>li.highlight.active+li, .no-skin .nav-list>li.highlight:hover+li {
    border-top-color: #C8D8E2;
}
.no-skin .nav-list>li.highlight.active>a, .no-skin .nav-list>li.highlight:hover>a {
    background-color: #FFF;
}
.no-skin .nav-list>li.highlight.active:after {
    display: none;
}
.no-skin .nav-list>li.highlight.active:before {
    display: block;
    background-color: #4f90c2;
}
.no-skin .nav-list>li.highlight.active>a {
    background-color: #F2F6F9!important;
}
.no-skin .nav-list>li.highlight.active>a:after, .no-skin .nav-list>li.highlight.active>a:before {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    right: -9px;
    bottom: auto;
    z-index: 1;
    border-style: solid;
    border-width: 20px 0 21px 10px;
    border-color: transparent;
}
.no-skin .nav-list>li.highlight.active>a:before {
    border-left-color: #a0bccd;
    right: -10px;
}
.no-skin .nav-list>li.highlight.active>a:after {
    border-left-color: #F2F6F9;
}
.no-skin .nav-list li li.highlight.active>a {
    background-color: #F2F6F9;
}
.no-skin .nav-list li li.highlight.active.open>a {
    background-color: #FFF;
}
.no-skin .nav-list li li.highlight.active.open>a:hover {
    background-color: #F1F5F9;
}
@media (min-width:992px) {
    .no-skin .sidebar.h-sidebar .nav-list>li.highlight.active>a:after {
    display: block;
    content: "";
    position: absolute;
    top: auto;
    right: auto;
    bottom: -2px;
    left: 50%;
    margin-left: -7px;
    border-color: transparent transparent #FFF;
    border-width: 8px 7px;
}
.no-skin .sidebar.h-sidebar .nav-list>li.highlight.active>a:before {
    content: "";
    position: absolute;
    display: block;
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-left: -7px;
    border-width: 8px;
    border-color: transparent transparent #74add7;
}
.no-skin .sidebar.h-sidebar .nav-list>li.highlight.active.hover-show>a:after, .no-skin .sidebar.h-sidebar .nav-list>li.highlight.active.hover-show>a:before, .no-skin .sidebar.h-sidebar .nav-list>li.highlight.active:hover>a:after, .no-skin .sidebar.h-sidebar .nav-list>li.highlight.active:hover>a:before, .sidebar.compact .nav-list li.highlight.active>a:after, .sidebar.compact .nav-list li.highlight.active>a:before {
    display: none;
}
.sidebar.compact.menu-min .nav-list li.highlight.active>a:after, .sidebar.compact.menu-min .nav-list li.highlight.active>a:before {
    display: block;
}
.sidebar.h-sidebar .nav-list li.highlight.active>a:after, .sidebar.h-sidebar .nav-list li.highlight.active>a:before {
    display: block;
    border-width: 8px!important;
    border-left-color: transparent!important;
    border-right-color: transparent!important;
}
.sidebar.h-sidebar .nav-list>li.highlight.active:hover>a:after, .sidebar.h-sidebar .nav-list>li.highlight.active:hover>a:before {
    display: none!important;
}
}.sidebar-scroll .nav-list>li.active.highlight>a:after, .sidebar-scroll .nav-list>li.active.highlight>a:before {
    display: none!important;
}

@media print {
    .main-content .nav-search, .navbar, .sidebar {
    display: none!important;
}
.footer, .footer .footer-inner, .footer .footer-inner .footer-content {
    width: 100%}
.main-content {
    margin-left: 0!important;
    margin-right: 0!important;
}
.main-content .breadcrumbs {
    float: right;
    border-width: 0!important;
}
.main-content .breadcrumbs .breadcrumb>li+li:before {
    content: "/"}
.main-content .breadcrumbs .breadcrumb .home-icon {
    display: none;
}
.ace-settings-container, .btn-scroll-up, .label[class*=arrowed]:after, .label[class*=arrowed]:before {
    display: none!important;
}
.footer {
    height: auto;
    position: relative;
}
.btn, .btn.btn-app {
    background: 0 0!important;
    border-width: 1px!important;
    border-radius: 0!important;
    color: #555!important;
}
.label {
    border: 1px solid #666!important;
}
}

/*custom css*/
.control-label {
    padding-top: 2px !important;
}
.input-addon-custom {
    padding: 3px 6px !important;
}

.admin-location {
    padding-top: 10%;
    padding-left: 25%;
}

.headerDiv {
    height:20px; 
    width:97.5%;
    margin-left:10px; 
    background-color:#165788;
    border:1px solid #165788;
    border-radius: 4px;
    color: #fff;
    text-align: center;
}

.slide-content {
    background: #fff;
        /*rgba(231, 231, 231, 0.28);*/
    display: block;
    margin: 0.2em;
    /*min-height: 495px;*/
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: auto;
    height: 230px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-left: 1px;
    margin-right: -6px;
    margin-top: 1px;
    /* box-shadow: 3px 3px 15px 5px #888888; */
}

.slide-grid-div {
    border: 1px solid #ccc;
    padding: 6px;
    height: 230px;
    overflow: auto;
    border-radius: 4px;
}

.right-blue {
    color: #336199  !important;
    text-align:right;
}

.text-blue {
    color: #165788 !important;
}

.border-none {
    border: none !important;
}

.ra {
    text-align:right !important;
}

.popup-table {    
    border: 1px solid #ddd !important;
    text-align: right !important;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    padding: 1px !important;
}

.table-heading {
    color:#165788; 
    font-weight:bold;
}

/*Number icon Start*/
a.fa-globe {
  position: relative;
  font-size: 2em;
  color: grey;
  cursor: pointer;
}
span.fa-comment {
    position: absolute;
    font-size: 1.3em;    
    color: #fcba04;
    height: 22px;
    width: 24px;
    top: 4px;
    right:16px;    
}
span.num {
    position: absolute;
    font-size: 0.8em;
    top: 3px;
    padding-left:1px;    
    color: #000;
}

.numberCircle {
    border-radius: 50%;
    width: 15px; 
    height: 15px;
    font-size: 1em;
    border: 1px solid #fe6703;
    background-color: #fcba04;
    float: left;
    top: 6px;
    right: 24px;
    position: absolute;
}
.numberCircle span {
    text-align: center;    
    display: block;
    top:-3px;
    right:2px;
    position:absolute;
    color:#000;
    font-size: 0.7em;
}

.disableMenu {
    background-color: lightgray !important;
    color: #897c7c !important;
    pointer-events: none;
}

.highlightMenu {
    background-color: #359fd9 !important;
}

.highlightMenu:hover {
    color: #FFF !important;
}

.MainContentMargin{
    margin: 5px 0px 0px 5px;
    width:98.4% !important;
}

.Margin10{
    margin-top:10px;
    margin-bottom:10px;
}

.GridBGColor{
    background-color: #1b6aaa !important;
    color: white !important;
}

.grid-bg-primary{
    background-color: #1b6aaa !important;
    color: white !important;
}

.TextOverflowEllipsis
{ 
    text-overflow: ellipsis;
    /* padding:3px 0px; */
    vertical-align: middle;
}

/*------------------------------Roles and Permisiion-------------------------*/
.roles-div{
    width:500px !important;
    margin-top: 10px;
}
.perm-div{
    width:600px !important;
    margin-top: 10px;
}


/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #D9D9D9;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* ----------------------------------------------------------------------- */

.active-align{
    text-align: right;
    }
    .div-search{
        width:100%;
        margin-bottom: 10px;
        margin-left:10px
    }
    .add-left{
        text-align: right;
        margin-top:10px;
    }
    .margin20{
        margin-left: 20px
    }
    .Dialog-Add{
        text-align: center
    }
/* ----------------------------Table row selection------------------------------------------- */
.body .ui-table .ui-table-tbody > tr.ui-state-highlight{
    background-color: #cce8ec !important;
    color:black !important;
 }
 .ui-table .ui-table-tbody > tr.ui-state-highlight{
     background-color: #cce8ec !important;
     color:black !important; 
 }

 .ui-inputtext:disabled {
    opacity: 1 !important;
    color: #666666 !important;
}

.btn-primary:focus {
    outline: none !important;
    border-radius: 4px !important;
    border-color: #2e6da4 !important;
}
     
 .ui-inputtext:disabled {
    opacity: 1 !important;
    color: #666666 !important;
}

.btn-primary:focus {
    outline: none !important;
    border-radius: 4px !important;
    border-color: #2e6da4 !important;
}

/*Navigation Menu Active*/
.active {
    background-color: #165788 !important;
    color: white !important;
}

body  .ui-breadcrumb {
    border: 0px solid #c8c8c8 !important;
    background-color: transparent !important;
    font-size: 10px !important;
    padding: 0px !important;
}
body .ui-breadcrumb-chevron{
font-size: 10px !important;
}

/* Rich text editor styles starts */
.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}
/* Rich text editor styles ends */

.asset-header-div {
    padding: 15px 5px 18px 1px;
}
.asset-header{
    font-size: 14px;
    padding: 8px 10px 8px 0px;
}
.asset-number{
    background: #1fa300;
    color: #ffffff;
    padding: 8px 10px 8px 10px;
    border-radius: 3px !important;
}
.asset-wo-delete-icon {
    width: 30px;
    float: left;
    padding: 4px 0px 0px 10px;
    color: #d03a2f;
    cursor: pointer;
}
.asset-wo-delete-icon:hover {
    color: #ea4337;
}




